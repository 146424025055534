import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { WidgetGroupBusinessRoutingModule } from './group_business-routing.module';
import { WidgetGroupBusinessComponent } from './group_business.component';
import { WidgetGroupBusinessItemModule } from './item/group_business_item.module';

@NgModule({
  imports: [CommonModule, WidgetGroupBusinessRoutingModule, WidgetGroupBusinessItemModule, SwiperModule],
  exports: [WidgetGroupBusinessComponent],
  declarations: [WidgetGroupBusinessComponent],
})
export class WidgetGroupBusinessModule {}
