import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Module } from 'models/module';
import { ApiService } from 'api_service';
import { Globals } from 'base';

@Component({
  selector: 'app-module-reservation',
  templateUrl: './reservation.component.html',
})
export class ReservationComponent implements OnInit {
  module: Module;
  entries: any[];
  rightSide = false;

  constructor(
    private api: ApiService,
    private globals: Globals,
    private router: Router,
  ) {}

  ngOnInit() {
    this.globals
      .getModule('reservation')
      .then((mod) => {
        this.module = mod;
        this.rightSide = this.module.medias && this.module.medias.length > 0;
      })
      .catch(() => {});
    this.getEntries().subscribe(
      (success) => {
        this.entries = success['entries'];
        if (this.entries.length === 1) {
          this.globals.overrideBacklink = ['/g', this.globals.getCode()];
          this.router.navigate(['g', this.globals.getCode(), 'reservation', this.entries[0]['id']], { replaceUrl: true });
        }
      },
      () => {},
    );
  }

  private getEntries(): Observable<Object> {
    return this.api.get('reservation/entries');
  }
}
