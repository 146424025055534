import { Component, OnInit, Input, Output, EventEmitter, HostListener, ChangeDetectorRef, ViewChild, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { PmsCiCoService } from 'cico_service';

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  styleUrls: ['../input-styles.scss', './phone-input.component.scss'],
})
export class PhoneInputComponent implements OnInit, AfterViewInit {
  @ViewChild('countryCodeInput') countryCodeInput: ElementRef;

  @Input() countries: any[];
  @Input() phoneNumber: string;
  @Input() required: boolean;
  @Input() tooltip: string;
  @Input() description: string;
  @Input() validation: string;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() disabled: boolean;
  @Input() errorMsg: string;
  @Input() countryFromAdr = null;
  @Input() submitted: boolean;
  @Input() isNewComponent: boolean;

  @Output() phoneNumberChange: EventEmitter<string> = new EventEmitter<string>();

  inputId: string;
  searchString = '';
  selCountry: any;
  selectedCountryCode: string;
  phoneWithoutCountry: string;
  dropdownOpen = false;
  dropdownPosition: 'above' | 'below';
  autocomplete: string;
  keyboardSize = 0;
  list: any[] = [];

  isPhoneIsNotValid: boolean = false;

  constructor(
    private cdRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private cicoService: PmsCiCoService,
  ) {}

  ngOnInit(): void {
    this.list = this.countries;

    if (this.phoneNumber) {
      this.phoneWithoutCountry = this.phoneNumber;
      this.setCountryCode();
    }

    this.inputId = `${this.name}_${Math.random().toString(36).substring(2)}`;
    this.autocomplete = window.screen.width >= 640 && window.screen.width <= 1320 ? 'nope' : 'on';

    this.keyboardSize = window.screen.width <= 640 ? 450 : window.screen.width >= 1320 ? 400 : 380;
  }

  ngAfterViewInit() {
    if (!this.isNewComponent) {
      const input = this.countryCodeInput?.nativeElement;
      this.renderer?.listen(input, 'keydown', (event) => {
        this.handleKeydown(event);
      });
    }
  }

  handlePhoneInputBlur(event: any) {
    this.isPhoneIsNotValid = event?.detail?.validationMessages && event.detail.validationMessages.length > 0;
  }

  handleInputBlur() {
    this.cicoService.keyboardOpenSubj.next(false);
    this.setCountryCode();
    this.emitValueChange();
  }

  setCountryCode() {
    this.removeBlanks();
    if (this.phoneWithoutCountry?.startsWith('+')) {
      let countryCode = this.getCountryCodeFromLib(this.phoneWithoutCountry);
      if (!countryCode && this.phoneWithoutCountry.length <= 4) {
        countryCode = this.phoneWithoutCountry.replace('+', '');
      }
      this.selCountry = this.getCountryBy(countryCode, 'phone');
      this.removeCountryCode();
    } else if (this.countryFromAdr && !this.selCountry) {
      this.selCountry = this.getCountryBy(this.countryFromAdr, 'value');
    }
    this.selectedCountryCode = this.selCountry?.value;
  }

  getCountryBy(value: string, property: 'phone' | 'value'): any[] {
    return this.countries.find((country) => country[property] === value);
  }

  getCountryCodeFromLib(phone: string): string {
    try {
      const numberProto = PhoneNumberUtil.getInstance().parse(phone, '');
      return numberProto.getCountryCode()?.toString();
    } catch {
      return null;
    }
  }

  emitValueChange() {
    this.phoneNumberChange.emit((this.selCountry ? '+' + this.selCountry.phone : '') + (this.phoneWithoutCountry ? this.phoneWithoutCountry : ''));
  }

  emitPhoneChange(event: any) {
    this.phoneWithoutCountry = event?.detail ?? this.phoneWithoutCountry;
    this.phoneNumberChange.emit((this.selCountry ? '+' + this.selCountry.phone : '') + this.phoneWithoutCountry);
  }

  removeCountryCode() {
    if (this.phoneWithoutCountry?.startsWith('+' + this.selCountry?.phone) && this.phoneWithoutCountry.replace('+', '').length >= this.selCountry?.phone.length) {
      this.phoneWithoutCountry = this.phoneWithoutCountry.replace('+' + this.selCountry.phone, '');
    }
  }

  openDropdown() {
    if (!this.disabled) {
      this.calculateDropdownPosition();
      this.dropdownOpen = true;
      this.searchString = '';
      this.list = this.countries;
    }
    this.cicoService.keyboardOpenSubj.next(true);
  }

  closeDropdown() {
    this.dropdownOpen = false;
    this.searchString = '';
    this.cicoService.keyboardOpenSubj.next(false);
  }

  applyFilter() {
    if (this.searchString?.length > 0 && this.containsNumber(this.searchString)) {
      this.list = this.countries.filter((country) => country.phone === this.searchString.replace('+', ''));
    } else if (this.searchString?.length > 0 && this.searchString !== '+') {
      this.list = this.cicoService.orderCountriesInSearch(this.list, this.searchString);
    }
  }

  containsNumber(value: string): boolean {
    return /\d/.test(value);
  }

  changeSelCountry(country: any) {
    this.selCountry = country;
    this.selectedCountryCode = this.selCountry?.value;
    this.emitValueChange();
    this.cdRef.detectChanges();
  }

  calculateDropdownPosition() {
    this.dropdownPosition = this.getAvailableDropdownSpace() < this.keyboardSize ? 'above' : 'below';
  }

  getAvailableDropdownSpace(): number {
    const el: HTMLElement = document.getElementById('country-dropdown');
    const rect: DOMRect = el.getBoundingClientRect();
    return window.innerHeight - rect.bottom;
  }

  countryInputChange() {
    this.applyFilter();
  }

  handleKeydown(event: KeyboardEvent) {
    if (this.dropdownOpen) {
      if (['ArrowUp', 'ArrowDown', 'Space'].indexOf(event.code) > -1) {
        event.preventDefault();
      }
      switch (event.key) {
        case 'ArrowUp':
          this.selectPrevItem();
          break;
        case 'ArrowDown':
          this.selectNextItem();
          break;
      }
    }
  }

  selectPrevItem() {
    const currIndex: number = this.countries.indexOf(this.selCountry);
    const newIndex: number = currIndex - 1 >= 0 ? currIndex - 1 : currIndex;
    this.changeSelCountry(this.countries[newIndex]);
  }

  selectNextItem() {
    const currentIndex: number = this.countries.indexOf(this.selCountry);
    const newIndex: number = currentIndex + 1 <= this.countries.length - 1 ? currentIndex + 1 : currentIndex;
    this.changeSelCountry(this.countries[newIndex]);
  }

  changeKeyboardState(open: boolean) {
    this.cicoService.keyboardOpenSubj.next(open);
  }

  removeBlanks() {
    this.phoneWithoutCountry = this.phoneWithoutCountry?.replace(/^\s*0|\s+/g, '');
    this.emitValueChange();
  }
}
