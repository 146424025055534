import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Field } from 'models/field';
import { FieldValue } from 'models/field_value';
import { Module } from 'models/module';
import { Globals } from 'base';
import { PmsCiCoService } from 'cico_service';
import { GenericData } from 'models/pms/generic_data';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { BusinessService } from 'services/business.service';

@Component({
  selector: 'app-form-fields',
  templateUrl: './fields.component.html',
  styleUrls: ['./fields.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FormFieldsComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  init: boolean;
  data: GenericData;
  locale: string;
  datePickerValidationMessages: string;
  mappedFormFieldsValues: { [key: number]: FieldValue } = {};

  @Input() form: any;
  @Input() submited: boolean = false;
  @Input() module: Module;
  @Input() useDefault = false;
  @Input() redesign = false;

  fields: Field[];
  oneRowArrivalDeparture: boolean;

  constructor(
    public globals: Globals,
    private cicoService: PmsCiCoService,
    private businessService: BusinessService,
    private _cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.businessService.currentLocale.subscribe((locale) => {
        this.locale = locale;
      }),
    );

    this.cicoService.data.pipe(filter(Boolean), take(1)).subscribe((data: GenericData) => {
      this.data = data;
    });

    this.initFields();
    this.subscriptions.add(
      this.cicoService.fieldsUpdated.pipe(filter(Boolean)).subscribe(() => {
        this.initFields();
        this._cdr.detectChanges();
      }),
    );
  }

  initFields() {
    this.fields = this.module.usableFields(this.useDefault);
    this.fields.forEach((field) => {
      if (!this.modelFor(field.id)) {
        this.form.field_values.push(new FieldValue(field));
      }
      this.mappedFormFieldsValues[field.id] = this.modelFor(field.id);
    });
    this.oneRowArrivalDeparture = this.fields.some((field) => field.identifier === 'arrival') && this.fields.some((field) => field.identifier === 'departure');
    this.init = true;
  }

  /**
   * @param value the selected value of the polarType question yes/no
   * @param fieldId id of the field inside the form (custom field from backend)
   * value of this field should boolean in order to have a correct status in confirm component - checkCoAnswers()
   */

  polarType(value: string, fieldId: number): void {
    this.modelFor(fieldId).value = value === 'yes';
  }

  /**
   * @param selectedValue the selected payment method, value of the selected option will be assigned to the field
   * @param fieldId in order to get the payment field from the mappedFormFieldsValues to assign the new value
   */

  changeSelPaymentMethod(selectedValue: { name?: string; value?: any }, fieldId: number): void {
    this.mappedFormFieldsValues[fieldId].value = selectedValue.value;
  }

  setDatePickerValidationMessage(event: any, fieldName: string): void {
    this.datePickerValidationMessages = this.cicoService.setDatePickerValidationMessage(event, fieldName);
  }

  modelFor(id) {
    return this.form.field_values.find((field) => field.id === id);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
