<ng-container *ngIf="redesign; else old">
  <app-select #select *ngIf="options?.length" [name]="name" ngDefaultControl [items]="options" [errorMsg]="errorLabel" [(ngModel)]="model[key]" (ngModelChange)="valueChange($event)" [required]="required" [description]="label | translate" [submited]="submited" [tooltip]="tooltip" [showIcons]="showIcons" placeholder="{{ 'misc.select' | translate }}" [showIcons]="true" [triggerKeyboard]="triggerKeyboard"></app-select>
</ng-container>

<ng-template #old>
  <ng-container *ngIf="options?.length">
    <label *ngIf="label" [for]="inputId" translate>{{ label }}</label>
    <small translate *ngIf="text?.length">{{ text }}</small>
    <ng-select #select [items]="options" bindLabel="name" bindValue="value" [groupBy]="group" [name]="name" [id]="inputId" [required]="required" [(ngModel)]="model[key]" (change)="valueChange($event)" (close)="select.blur(); select.focus()" placeholder="{{ 'misc.select' | translate }}"> </ng-select>
    <label translate class="error">{{ errorLabel }}</label>
  </ng-container>
</ng-template>
