import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { WidgetAdvertModule } from 'widgets/advert/advert.module';
import { ModuleTitleModule } from 'shared/layout/module_title.module';
import { SharedSwiperModule } from 'shared/layout/swiper/swiper.module';
import { PmsCiCoBaseDirective } from '../base/cico_base.directive';
import { PmsLoginModule } from '../shared/login/login.module';
import { PmsDoorApiModule } from './api/api.module';
import { PmsDoorRoutingModule } from './door-routing.module';
import { PmsDoorComponent } from './door.component';
import { PmsDoorPinCodesModule } from './pin_codes/pin_codes.module';

@NgModule({
  imports: [CommonModule, PmsDoorRoutingModule, PmsDoorApiModule, SharedSwiperModule, ModuleTitleModule, PmsLoginModule, WidgetAdvertModule, PmsDoorPinCodesModule, ReactiveFormsModule, TranslateModule, FormsModule],
  exports: [PmsDoorComponent],
  declarations: [PmsDoorComponent],
  providers: [PmsCiCoBaseDirective],
})
export class PmsDoorModule {}
