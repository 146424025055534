import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { InternationalPhoneNumberModule } from 'ngx-international-phone-number';
import { LengthValidatorModule } from '../../../../validators/length.validator.module';
import { PhoneValidatorModule } from '../../../../validators/phone.validator.module';
import { FormPhoneFieldRoutingModule } from './phone_field-routing.module';
import { FormPhoneFieldComponent } from './phone_field.component';

@NgModule({
  imports: [CommonModule, FormPhoneFieldRoutingModule, FormsModule, InternationalPhoneNumberModule, TranslateModule, PhoneValidatorModule, LengthValidatorModule],
  exports: [FormPhoneFieldComponent],
  declarations: [FormPhoneFieldComponent],
})
export class FormPhoneFieldModule {}
