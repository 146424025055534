import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FilterPipeModule } from 'pipes/filter.pipe.module';
import { WidgetAdvertModule } from 'widgets/advert/advert.module';
import { ModuleTitleModule } from 'shared/layout/module_title.module';
import { SharedSwiperModule } from 'shared/layout/swiper/swiper.module';
import { NewspapersRoutingModule } from './newspapers-routing.module';
import { NewspapersComponent } from './newspapers.component';

@NgModule({
  imports: [CommonModule, NewspapersRoutingModule, FormsModule, TranslateModule, FilterPipeModule, SharedSwiperModule, WidgetAdvertModule, ModuleTitleModule],
  exports: [NewspapersComponent],
  declarations: [NewspapersComponent],
})
export class NewspapersModule {}
