import { Directive, ElementRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';
import { ValueAccessor } from './value-accessor';

@Directive({
  selector: 'straiv-datepicker',
  host: {
    '(valueChange)': 'handleChangeEvent($event.target.value)',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DatepickerValueAccessor,
      multi: true,
    },
  ],
})
export class DatepickerValueAccessor extends ValueAccessor {
  @Input() localeLng: string = 'default';

  constructor(protected el: ElementRef) {
    super(el);
  }

  // we transform any given date string to the format the straiv datepicker can work with.
  writeValue(value: string) {
    if (!value) {
      this.el.nativeElement.value = '';
      return;
    }
    this.el.nativeElement.value = this.formatValue(value);
  }

  private formatValue(value: string): string {
    // Parse the input value to a consistent date format (e.g., YYYY-MM-DD)
    const { momentMask } = this.getLocaleMask(this.localeLng);
    return moment(new Date(value)).format(momentMask);
  }

  private getLocaleMask(locale: string) {
    return this.LOCALE_MASKS.find((mask) => mask.locale === locale) || this.LOCALE_MASKS.find((mask) => mask.locale === 'default');
  }

  private LOCALE_MASKS = [
    { locale: 'de', momentMask: 'DD.MM.YYYY' },
    { locale: 'ar', momentMask: 'YYYY/MM/DD' },
    { locale: 'default', momentMask: 'DD/MM/YYYY' },
  ];
}
