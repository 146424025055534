import { CurrencyPipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Globals } from 'base';
import { PmsCiCoService } from 'cico_service';
import { Business } from 'models/business';
import { PmsPaymentService } from 'payment_service';
import { PmsBaseDirective } from 'pms_base/base.directive';
import { ConfirmName, UserActionType, PaymentProcess, PmsModType, ResState, Step, CusProductTypes } from 'pms_enums';
import { GenericData } from 'pms_models/generic_data';
import { PmsFolio } from 'pms_models/pms_folio';
import { filter, take } from 'rxjs/operators';
import { StepperService } from 'services/pms/stepper.service';
import { PmsPaymentComponent } from '../payment/payment/payment.component';
import { PmsService } from 'modules/pms/pms.service';
import { EventAggregatorService } from 'services/events/event-aggregator.service';
import { EventConstants } from 'global_enums';

@Component({
  selector: 'app-pms-folios',
  templateUrl: './folios.component.html',
  styleUrls: ['./folios.component.scss', './../start/start.component.scss'],
})
export class PmsFoliosComponent extends PmsBaseDirective implements OnInit, OnDestroy, AfterViewInit {
  public processType = PaymentProcess;

  valid: boolean;
  loaded: boolean;
  showHints: boolean;
  checks: any;
  ui_messages: any;
  reservation_id: any;
  state: string;
  amountSum: any;
  protel_prem: boolean;
  protel_prem_ci: boolean;

  collectAddress: boolean = false;
  suite8: boolean;
  opera: boolean;
  skip: boolean;
  skipType: string;
  skipable: boolean = false;
  skipAllowed: boolean = false;
  skipButton: boolean = false;
  reloadButton: boolean = false;
  msgToDisplay: string = '';
  msgToDisplayHeadLine: string = '';
  onlyAdress: boolean = false;
  blankFolios: boolean = false;
  listening: boolean = false;
  foliosErrorMsg: string = '';

  @Input() business: Business;
  @Input() payment: boolean;
  @Output() noFolios = new EventEmitter<any>();

  payableAmount: string;
  isDialogOpen: boolean = false;
  payButton: string;
  isExternalPaymentDialogOpen: boolean = false;

  constructor(
    public cicoService: PmsCiCoService,
    public globals: Globals,
    private paymentService: PmsPaymentService,
    protected stepperService: StepperService,
    private pmsService: PmsService,
    private currencyPipe: CurrencyPipe,
    protected readonly eventService?: EventAggregatorService,
  ) {
    super(cicoService, Step.invoice, undefined, stepperService);
  }

  ngOnInit() {
    if (this.cicoService.should_payment !== false) {
      this.cicoService.should_payment = true;
    }
    this.cicoService.infoScreen = false;
    this.cicoService.disableBack = false;
    this.cicoService.disableNextButton(true);
    this.cicoService.confirmNameSubj.next(ConfirmName.next);
    this.defaultLoadingText();
    this.globals.removeOverlayClass();
    this.skipType = undefined;

    super.ngOnInit();

    if (this.isReservationModule()) {
      this.checkAndSetAddresses();
    }

    this.subscriptions.add(
      this.eventService.getEvent(EventConstants.toCheckThePaidFolios).subscribe(() => {
        this.check();
      }),
    );

    this.subscriptions.add(
      this.eventService.getEvent(EventConstants.folioProcessInitiated).subscribe(() => {
        this.globals.removeOverlayClass();

        this.setAuthCheck();
        this.checkAndSetAddresses();

        const payOrAuth = this.payment || this.data.paymentProcess === PaymentProcess.pre_auth;

        if (payOrAuth && this.skipType && this.preventNext()) {
          this.setSkip(true);
        } else if (payOrAuth && (!this.loaded || this.preventNext())) {
          const checks = this.data.incident.checks;
          if ((!checks.paid_folios || !checks.auth_done) && this.preventNext()) {
            const message = this.ui_messages[this.data.paymentProcess + '_required'];
            if (message?.content && !this.cicoService.autoSkipUntilStep) {
              this.globals.alert('error', message?.content);
            }
            this.scrollToFolio();
          }
          this.cicoService.setAutoSkipUntilStep(undefined);
        }
      }),
    );

    this.subscriptions.add(
      this.cicoService.folioUpdate.pipe(filter(Boolean)).subscribe((info: any) => {
        this.setFolioSettings();
        this.cicoService.loadFolios(this.data, info.cus);
      }),
    );

    this.subscriptions.add(
      this.cicoService.overlayClose.subscribe(() => {
        this.cicoService.preventNext = this.preventNext();
      }),
    );

    this.subscriptions.add(
      this.paymentService.receivedPayment.subscribe((raw_folios: Array<PmsFolio> | PmsFolio) => {
        this.data.module.settings.travel_purpose_lock = true;
        const folio = raw_folios;
        if (folio instanceof PmsFolio) {
          const fol = this.data.incident.reservation.folios.find((obj) => obj.number === (<PmsFolio>folio).number);
          fol.check = 'pending';
          this.setButtons();
        } else {
          let folios = raw_folios;
          if (folios && !(folios[0] instanceof PmsFolio)) {
            folios = this.cicoService.mapFolios(folios, this.data.business);
          }
          folios = (<PmsFolio[]>folios)?.filter((fol) => fol.reservation.reservation_id === this.data.incident.reservation.reservation_id);
          if (folios.length) {
            this.data.setFolios(folios);
            this.setFolios();
          }
        }
      }),
    );
  }

  ngAfterViewInit() {
    setTimeout(() => {
      // If any payment is required, we stop skipping the invoice step.
      // If after successfull payment redirection, we stop skipping the invoice step.
      if (this.preventNext() || this.cicoService.autoSkipUntilStep === Step.invoice) {
        this.cicoService.setAutoSkipUntilStep(undefined);
      } else if (this.cicoService.autoSkipUntilStep === Step.confirm) {
        this.autoNextProcess(Step.invoice);
      }
    }, 400);
  }

  protected fetchData() {
    this.subscriptions.add(
      this.cicoService.data.pipe(filter(Boolean), take(1)).subscribe((data: GenericData) => {
        this.data = data;
        this.business = this.data.business;
        this.suite8 = this.business.usePms('suite8');
        this.opera = this.business.usePms('opera');
        this.protel_prem = this.business.protelOnPrem();
        this.ui_messages = this.cicoService.ui_messages();
        this.showHints = !this.isReservationModule();

        this.setAddressCollect();

        this.onlyAdress = this.data.blank && this.field_for('invoice_address')?.active;

        if (this.reservation_id !== data.incident.reservation.reservation_id) {
          this.data = data;
          this.data.incident.checks.required_payments = this.data.module?.settings?.required_payments;
          this.state = this.data.incident.reservation?.booking_data?.state_type;
          this.reservation_id = this.data.incident.reservation.reservation_id;
          this.loaded = this.data.blank || this.data.folioInfo?.loaded?.value || this.data.incident.reservation.folios.length > 0;
          this.cicoService.preventNext = !this.data.blank;
          this.waitForFolios();
        }
      }),
    );
  }

  waitForFolios() {
    if (this.loaded) {
      this.setFolios();
    } else {
      this.listenToFolioInfo();
    }
  }

  // Set an appropriate folio error message, manage loading process while waiting for folio, and subscribe to folio information updates based on customer status
  listenToFolioInfo(cus = false) {
    this.errorMsg(cus);
    if (!this.listening) {
      this.listening = true;
      if (cus) {
        this.longLoadingAfterCus();
      } else {
        this.longLoading();
      }
      this.subscriptions.add(
        this.data.folioInfo.loaded.pipe(filter(Boolean)).subscribe(() => {
          this.setFolios();
        }),
      );
    }
  }

  checkAndSetAddresses() {
    this.setAddressCollect();
    this.check();
  }

  loadFolios() {
    this.defaultLoadingText();
    this.cicoService.loadFolios(this.data);
  }

  setFolioSettings() {
    this.cicoService.disableNextButton(false);
    this.cicoService.confirmNameSubj.next(ConfirmName.next);
    this.blankFolios = !this.data.incident.reservation.folios.length || this.data.incident.reservation.folios.every((folio) => !folio.positions.length);
    this.skipAllowed = this.data.module.settings?.allow_folio_error || (!this.data.module.settings?.required_payments && !this.preAuthRequired());
    this.reloadButton = false;
    this.data.incident.checks.paid_folios = false;
  }

  isReservationModule(): boolean {
    return this.data?.isReservationModule();
  }

  payFromResModule(): boolean {
    return this.isReservationModule() && this.payment;
  }

  check() {
    this.globals.clearAlert();
    this.amountSum = this.calculateSum();

    this.data.incident.checks.paid_folios = this.foliosPaid();
    if (this.data.incident.checks.paid_folios && !this.skipable) {
      this.cicoService.confirmNameSubj.next(ConfirmName.next);
    } else if (this.payment && this.skipable) {
      this.cicoService.confirmNameSubj.next(ConfirmName.skip);
    }
  }

  fillAddresses() {
    this.data.incident.reservation.folios.filter((folio) => !folio.address_lock).forEach((folio) => (folio.address = this.data.incident.reservation.address));
  }

  setAddressCollect() {
    this.protel_prem_ci = this.protel_prem && this.isReservationModule() && this.state === ResState.checked_in;
    this.collectAddress = !this.protel_prem_ci && this.state !== ResState.checked_out;
  }

  preventNext(): boolean {
    this.check();
    let prevented: boolean;
    if (this.data.blank || this.data.paymentProcess === PaymentProcess.nothing || this.folioError() || !this.cicoService.should_payment) {
      prevented = false;
    } else if (this.checkOutPending()) {
      prevented = true;
    } else if (this.data.paymentProcess === PaymentProcess.payment && this.payRequired()) {
      prevented = true;
    } else if (this.data.paymentProcess === PaymentProcess.pre_auth && this.preAuthRequired()) {
      // Handle 'Next' button based on Auth rule: after CUS long loading, respect 'allow check-in with error' setting; otherwise, check authorization
      prevented = this.data.folioInfo.cusLongLoading.value ? !this.data.module.settings.allow_folio_error : !this.isAuthorized();
    } else {
      prevented = this.data.incident.reservation.folios?.some((folio) => folio.paymentRequired() && !folio.paidOrAuthorized());
    }
    this.cicoService.preventNext = prevented;
    return prevented;
  }

  checkOutPending(): boolean {
    return this.data.module.type === PmsModType.co && this.pendingFolios();
  }

  setButtons() {
    this.skipButton = false;
    this.reloadButton = false;
    this.cicoService.preventNext = this.preventNext();
  }

  preAuthUsable(): boolean {
    if (this.data.module.type !== PmsModType.ci || this.isAuthorized() || !this.preAuthRequired() || this.exisitingPayments() || this.forbiddenTerminal()) {
      return false;
    }

    if (this.data?.folioInfo?.error || !this.data.incident.reservation.folios.length) {
      return this.data?.incident?.reservation?.pre_auth?.auth_without_folio;
    } else if (this.amountSum > 0) {
      return true;
    } else if (this.amountSum === 0) {
      return this.data?.incident?.reservation?.pre_auth?.auth_after_balancing;
    } else {
      return false;
    }
  }

  forbiddenTerminal(): boolean {
    return this.data.incident.reservation.payment_providers?.terminal && this.data.business.usePayment('worldline');
  }

  isAuthorized(): boolean {
    const folios = this.data?.incident?.reservation?.folios;
    if (this.data?.preAuth?.fullyAuthorized) {
      return true;
    } else if (this.data?.folioInfo?.error) {
      return this.data.module.settings.allow_folio_error;
    } else if (folios.length) {
      return folios.every((folio) => folio.authorized());
    } else {
      return false;
    }
  }

  setProcess() {
    const pay_option = this.payOptional();
    const pay_required = this.payRequired();
    const auth_required = this.preAuthUsable();

    this.skipable = pay_option && !pay_required && this.paymentAllowed();

    if (!this.cicoService.should_payment || this.state === ResState.checked_out) {
      this.data.paymentProcess = PaymentProcess.nothing;
    } else if (auth_required || this.authorized()) {
      this.data.paymentProcess = PaymentProcess.pre_auth;
    } else if (this.payment) {
      this.data.paymentProcess = PaymentProcess.payment;
    } else {
      this.data.paymentProcess = PaymentProcess.nothing;
    }
  }

  payOptional(): boolean {
    return this.data.incident.reservation.folios.some((folio) => folio.paymentOptional());
  }

  payRequired(): boolean {
    return this.requiredFolios().length > 0;
  }

  paymentAllowed(): boolean {
    return this.payment && this.data.incident.reservation.folios.filter((folio) => folio.paymentAllowed() && !folio.paidOrAuthorized()).length > 0;
  }

  requiredFolios(): PmsFolio[] {
    return this.data.incident.reservation.folios.filter((folio) => folio.paymentRequired());
  }

  pendingFolios(): boolean {
    return this.data.incident.reservation.folios?.filter((folio) => folio.check === 'pending').length > 0;
  }

  authorized(): boolean {
    return this.data.incident.reservation.folios?.some((folio) => folio.authorized());
  }

  preAuthRequired(): boolean {
    return this.data?.incident?.reservation?.pre_auth?.required === 'required';
  }

  exisitingPayments(): boolean {
    return this.data?.incident?.reservation?.folios?.some((folio) => folio.existing_payments);
  }

  skipPreAuth() {
    this.isDialogOpen = true;
    this.skipType = this.data.module.settings?.required_payments && this.data.module.settings?.payment_fallback ? 'no_credit_card_with_rule' : 'no_credit_card_without_rule';
    this.setSkip(true);
  }

  setSkip(active) {
    this.skip = active;
    this.cicoService.setShowFooter(!active);
  }

  confirmSkip() {
    this.setSkip(false);
    this.setAuthCheck();
    if (this.skipType === 'no_credit_card_with_rule') {
      this.data.paymentProcess = this.data.paymentProcess === PaymentProcess.payment ? PaymentProcess.pre_auth : PaymentProcess.payment;
    } else {
      this.cicoService.preventNext = false;
      this.cicoService.navigate(UserActionType.next);
    }
  }

  openPreAuth(event) {
    const button = event.target;
    button.disabled = true;
    this.payButton = button;
    this.isExternalPaymentDialogOpen = true;
  }

  setting(): string {
    return this.data.module.settings?.invoice_address;
  }

  folioError(): boolean {
    return this.data.folioInfo.error;
  }

  private setFolios() {
    this.loaded = true;
    this.setFolioSettings();
    this.cicoService.disableNextButton(false);

    if (this.folioError()) {
      this.errorCase();
    } else if (!this.data.blank) {
      this.data.folioInfo.noFolios = false;

      if (!this.data.incident.reservation.folios.length || this.data.incident.reservation.folios.every((folio) => !folio.positions.length)) {
        this.onlyAdress = this.data.business.usePms(['guestline', 'sihot', 'infor']) && this.field_for('invoice_address')?.active && !this.isReservationModule();
        if (!this.data.incident.reservation.pre_auth?.auth_without_folio) {
          this.noFolios.emit(true);
        }
      }
      this.setPreAuth();

      if (!this.isReservationModule()) {
        this.amountSum = this.calculateSum();
        this.setButtons();
      }

      this.setProcess();
      this.check();
      if (this.cicoService.deviatingBillingAddress) {
        this.fillAddresses();
      }

      const autoSkipUntilStep = this.cicoService.autoSkipUntilStep;
      const autoSkip = autoSkipUntilStep && autoSkipUntilStep !== Step.invoice;

      if (autoSkip && !this.preventNext()) {
        this.skipIt();
      } else if (autoSkipUntilStep === Step.invoice) {
        this.cicoService.setAutoSkipUntilStep(undefined);
      }
    }
  }

  errorCase() {
    this.amountSum = -1;
    this.setPreAuth();
    this.setProcess();
    this.setButtons();
    if (!this.skipAllowed) {
      this.data.incident.checks.loaded_folios = false;
    }
    this.check();
  }

  foliosPaid(): boolean {
    if (this.data.module.type === PmsModType.co) {
      return this.cicoService.foliosPaid();
    } else {
      return (!this.data.folioInfo?.error || false) && (!this.payRequired() || (this.amountSum <= 0 && !this.folioError()) || this.isPaid() || this.isAuthorized());
    }
  }

  calculateSum(auth?: boolean): any {
    const { reservation } = this.data?.incident || {};

    // Check for folios existence
    if (!reservation?.folios?.length) {
      return reservation?.pre_auth?.auth_without_folio ? -1 : 0;
    }

    // Filter folios and calculate balances based on the `auth` flag
    const folioBalances = reservation.folios.filter((folio) => (auth ? !folio.paid(true) : !folio.paid() && folio.paymentRequired())).map((folio) => (auth ? folio.balance : folio.payable_balance));

    // Calculate total balances
    const totalBalances = folioBalances.reduce((sum, current) => sum + current, 0);

    // Only update payableAmount if there is something to transform
    this.payableAmount = this.currencyPipe.transform(totalBalances, this.business.currency);

    return totalBalances;
  }

  hasAuthorizations(): boolean {
    return this.data.incident.reservation.folios.some((folio) => folio.authorized());
  }

  isPaid(): boolean {
    return this.data?.incident?.reservation?.folios?.every((folio) => folio.paid());
  }

  private setPreAuth() {
    if (this.data.module.type === PmsModType.co) {
      return;
    }

    const res_auth = this.data?.incident?.reservation?.pre_auth;
    const no_folios_received = !this.data?.incident?.reservation?.folios?.length;
    const without_folio = no_folios_received && res_auth?.auth_without_folio;
    const viewable_folios = this.data?.incident?.reservation?.folios?.filter((folio) => folio.viewable).length > 0;
    const fetchPreAuth = res_auth?.required?.length || without_folio || this.data.paymentProcess === PaymentProcess.pre_auth || this.hasAuthorizations();

    if (fetchPreAuth) {
      this.paymentService.getAuthAmount(this.calculateSum(true), this.data.incident.reservation.reservation_id, without_folio, no_folios_received, viewable_folios).subscribe((success: any) => {
        this.data.preAuth = {
          ...success,
          balance_with_currency: this.currencyPipe.transform(success.amount || success.payed, this.business.currency),
          deposit_with_currency: this.currencyPipe.transform(success.deposit, this.business.currency),
        };
      });
    }
  }

  defaultLoadingText() {
    this.msgToDisplay = 'misc.loading';
    this.msgToDisplayHeadLine = '';
  }

  longLoading() {
    this.setFolioSettings();
    if (this.data.folioInfo.longLoading.value) {
      this.loadingMsgManagement();
    } else {
      this.subscriptions.add(
        this.data.folioInfo.longLoading.pipe(filter(Boolean)).subscribe(() => {
          this.loadingMsgManagement();
        }),
      );
    }
  }

  // While waiting up to 8 seconds for folios, configure folio settings and payment process, then handle or subscribe to long loading errors specific to CUS scenario
  longLoadingAfterCus() {
    this.setFolioSettings();
    this.setProcess();
    if (this.data.folioInfo.cusLongLoading.value) {
      this.processLongLoadingError();
    } else {
      this.subscriptions.add(
        this.data.folioInfo.cusLongLoading.pipe(filter(Boolean)).subscribe(() => {
          this.processLongLoadingError();
        }),
      );
    }
  }

  processLongLoadingError() {
    // Mark folios as unavailable and reset button states
    this.data.folioInfo.noFolios = true;
    this.skipButton = false;
    this.reloadButton = false;
    this.loaded = true;
    this.errorCase();

    // If there's a error while waiting for folios update, set the error message specific to CUS
    if (this.data.folioInfo.error) {
      this.errorMsg(true);
    }
  }

  loadingMsgManagement() {
    const transBase = 'service.payment.loading';
    if (this.data.module.type === PmsModType.co) {
      this.msgToDisplay = this.folioError() ? `${transBase}.checkout.error` : `${transBase}.checkout.info`;
      this.reloadButton = true;
    } else {
      if (this.folioError()) {
        this.skipButton = true;
        this.reloadButton = true;
        if (this.data.module.settings?.required_payments) {
          this.msgToDisplay = !this.skipAllowed && this.data.incident.reservation.can_check_in ? `${transBase}.checkin.skip_no_check_in` : '';
        } else {
          this.msgToDisplay = `${transBase}.checkin.skip_and_later`;
        }
      } else {
        this.skipButton = true;
        this.reloadButton = false;
        if (this.data.incident.reservation.can_check_in) {
          this.msgToDisplay = this.skipAllowed ? `${transBase}.checkin.skip_and_later` : `${transBase}.checkin.skip_no_check_in`;
        } else {
          this.msgToDisplay = `${transBase}.buttons.skip`;
        }
      }
      if (this.isReservationModule()) {
        this.msgToDisplay = ' ';
      }
    }

    this.msgToDisplayHeadLine = this.folioError() ? `${transBase}.common.error_headline` : `${transBase}.common.info_headline`;
  }

  skipIt() {
    this.enableButtons();
    this.setAuthCheck();
    this.cicoService.confirmNameSubj.next(ConfirmName.next);
    this.cicoService.navigate(UserActionType.next);
  }

  setAuthCheck() {
    delete this.data.incident.checks['auth_done'];
    if (this.data.paymentProcess === PaymentProcess.pre_auth) {
      this.data.incident.checks.auth_done = this.data?.preAuth?.fullyAuthorized || this.data.incident?.reservation?.folios?.every((folio) => folio.authorized());
    }
  }

  enableButtons() {
    this.cicoService.disableNext = false;
    this.cicoService.preventNext = false;
  }

  scrollToFolio() {
    const folio = this.requiredFolios()[0];
    if (folio) {
      this.cicoService.scrollToFolio(folio);
    }
  }

  // Sets the appropriate error message based on the conditions provided.
  // Param boolean cus indicating if a custom error message should be used.
  errorMsg(cus = false) {
    if (cus) {
      this.foliosErrorMsg = '_cus';
      return;
    }
    const usePms = this.data.business.usePms(['guestline', 'sihot', 'infor', 'suite8']);
    this.foliosErrorMsg = usePms ? '_exceptions' : this.data.blank ? '_no_pms' : '';
  }

  closeDialog() {
    this.isDialogOpen = false;
    this.cicoService.setShowFooter(true);
  }

  closeExternalPaymentDialog() {
    this.isExternalPaymentDialogOpen = false;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
