import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Globals } from 'base';
import { PmsCiCoService } from 'cico_service';
import { Business } from 'models/business';
import { GenericOverlay } from 'models/pms/generic_overlay';
import { UserOption } from 'models/user_option';
import { LostKeyStep, UserActionType, OverlayAction, OverlayType, PmsProcess } from 'pms_enums';
import { Subscription } from 'rxjs';
import { GuestService } from 'services/guest.service';

@Component({
  selector: 'app-pms-terminal-option',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss'],
})
export class PmsWizardTerminalOptionComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  public olContent: GenericOverlay;
  public showOverlay: boolean;
  door: boolean;
  lastStep: LostKeyStep;
  subscribed: boolean;
  reservation_id: string;

  heading: string;
  description: string;

  @Input() business: Business;
  @Input() reservation: any;
  @Input() options: UserOption[];
  @Input() actionNotAvailable = false;
  @Output() reset_terminal = new EventEmitter<any>();
  @Output() selOptionChange = new EventEmitter<string>();

  @ViewChild('serviceForm') serviceForm: NgForm;

  constructor(
    public globals: Globals,
    public cicoService: PmsCiCoService,
    public guestService: GuestService,
  ) {}

  ngOnInit() {
    this.reservation_id = this.reservation.reservation_id;
    this.cicoService.cardEncoded = this.reservation?.already_encoded;
    this.navSubscription();
    this.cicoService.addSubscriptions();
    this.cicoService.headerActionSubj.next(UserActionType.cancel);

    this.heading = this.actionNotAvailable ? 'service.process.not_available' : 'service.process.pick_action';
    this.description = this.actionNotAvailable ? 'service.process.not_available_details' : '';
  }

  select(type: string) {
    this.cicoService.setProcess(type === 'new_card' ? PmsProcess.door : PmsProcess[type]);
    this.selOptionChange.emit(type);

    if (!this.subscribed && !['check_in', 'check_out', 'reservation'].includes(type)) {
      this.subOverlay();
      this.cicoService.disableNextButton(true);
      this.cicoService.hideNextButton(true);
      this.subscribed = true;
      this.cicoService.headerActionSubj.next(UserActionType.back);
    }
    if (['check_in', 'check_out'].includes(type)) {
      this.subscriptions.unsubscribe();
    }
  }

  navSubscription() {
    this.subscriptions.add(
      this.cicoService.navigation.subscribe((type) => {
        switch (type) {
          case UserActionType.back:
            this.globals.clearAlert();
            if (this.cicoService.getProcess() === null) {
              this.resetTerminal();
            } else {
              this.cicoService.setProcess(PmsProcess.door);
              this.cicoService.headerActionSubj.next(UserActionType.cancel);
            }
            break;
          case UserActionType.next:
            if (this.cicoService.getProcess() === PmsProcess.door) {
              this.cicoService.doorStep = this.lastStep;
            }
            break;
          case UserActionType.cancel:
            if (['check_in', 'check_out'].includes(this.cicoService.getProcess()) && !this.cicoService.infoScreen && !this.guestService.loginRequired) {
              this.cicoService.openOverlay(OverlayType.cancel);
            } else if (['cards', 'new_card'].includes(this.cicoService.getProcess()) && !this.cicoService.cardEncoded) {
              this.cicoService.openOverlay(OverlayType.noCard);
            } else {
              this.resetTerminal();
            }
            break;
          default:
            break;
        }
      }),
    );
  }

  subOverlay() {
    this.subscriptions.add(
      this.cicoService.overlay.subscribe((content) => {
        this.olContent = content;
        this.showOverlay = true;
      }),
    );

    this.subscriptions.add(
      this.cicoService.overlayClose.subscribe((close: any) => {
        switch (close.action) {
          case OverlayAction.cancel:
            document.getElementById('container').classList.add('overlay');
            this.resetTerminal();
            break;
          case OverlayAction.reallySure:
          case OverlayAction.skip:
          case OverlayAction.close:
            document.getElementById('container').classList.add('overlay');
            this.showOverlay = false;
            break;
          default:
            break;
        }
      }),
    );
  }

  resetTerminal() {
    this.cicoService.toggleInactivity(false);
    this.cicoService.cardLost = null;
    this.reset_terminal.emit(true);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
