<div class="page-headline">
  <h1>{{ title }}</h1>
</div>

<em [innerHtml]="description" *ngIf="description != null"></em>

<div class="container">
  <div class="container-right" *ngIf="rightSide">
    <app-swiper [items]="medias"></app-swiper>

    <app-widget-advert></app-widget-advert>
  </div>
  <div class="container-left">
    <div class="top-btn-container clearfix" *ngIf="showMap">
      <a [routerLink]="['/g/', globals.getCode(), 'poi', 'map']" class="button top-btn"> <i class="fas fa-map-marked-alt"></i>{{ 'poi.map' | translate }} </a>
    </div>

    <div class="searchbox" *ngIf="module?.search && (categories || entries)">
      <input type="search" [(ngModel)]="searchText" placeholder="{{ 'misc.searchenter' | translate }}" />
      <div class="search-icon"><i class="fas fa-search"></i></div>
    </div>

    <div class="menu" *ngIf="suggestions">
      <a [routerLink]="['/g', globals.getCode(), 'poi_category', 'smart']">
        <div translate>smart_weather.suggestion</div>
        <div>
          <i class="fas fa-angle-right icon-next"></i>
        </div>
      </a>
    </div>
    <app-attachments [files]="files" *ngIf="files?.length"></app-attachments>
    <div class="menu" *ngIf="categories?.length">
      <a *ngFor="let item of categories | filter: searchText" [routerLink]="['/g', globals.getCode(), 'poi_category', item.category.id]">
        <div>{{ item.category.name }}</div>
        <div>
          <i class="fas fa-angle-right icon-next"></i>
        </div>
      </a>
    </div>
    <div class="menu entries" *ngIf="entries?.length">
      <div class="head" *ngIf="!searchText">
        <div *ngIf="distances"><i class="fas fa-route"></i></div>
        <div translate>misc.place_name</div>
        <div></div>
      </div>
      <a *ngFor="let item of entries | filter: searchText" [routerLink]="['/g', globals.getCode(), 'poi', item.entry.id]">
        <div *ngIf="distances">{{ item.entry.coordinates?.distance }}</div>
        <div><i class="ad-icon" *ngIf="item.entry.advert"></i>{{ item.entry.name }}</div>
        <div><i class="fas fa-angle-right icon-next"></i></div>
      </a>
    </div>
  </div>
</div>

<app-backlink *ngIf="category"></app-backlink>
