import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormFieldsModule } from 'shared/forms/fields/fields.module';
import { DatePickerModule } from 'shared/forms/datepicker/datepicker.module';
import { LabelModule } from 'shared/forms/label/label.module';
import { PmsTravelInfoRoutingModule } from './travel_info-routing.module';
import { PmsTravelInfoComponent } from './travel_info.component';
import { PmsBaseDirective } from 'pms_base/base.directive';
import { PmsOverlayModule } from 'modules/pms/shared/overlay/overlay.module';
import { TypographyModule } from 'common/typography/typography.module';
import { PmsInvoiceAddressFormModule } from 'modules/pms/shared/invoice_address/form/form.module';
import { LoaderModule } from 'common/loader/loader.module';
import { MainTitleModule } from 'modules/pms/shared/main-title/main-title.module';
import { PhotoPreviewModule } from 'modules/shared/forms/photo-preview/photo-preview.module';
import { ProductComponent } from './product/product.component';
import { CustomOrderPlannerComponent } from './custom-order-planner/custom-order-planner.component';
import { UpsellGalleryComponent } from './upsell-gallery/upsell-gallery.component';
import { UpsellItemComponent } from './upsell-gallery/upsell-item/upsell-item.component';

@NgModule({
  imports: [CommonModule, PmsTravelInfoRoutingModule, FormFieldsModule, PmsOverlayModule, FormsModule, LabelModule, DatePickerModule, TranslateModule, TypographyModule, PmsInvoiceAddressFormModule, LoaderModule, MainTitleModule, PhotoPreviewModule],
  exports: [PmsTravelInfoComponent],
  declarations: [PmsTravelInfoComponent, ProductComponent, CustomOrderPlannerComponent, UpsellGalleryComponent, UpsellItemComponent],
  providers: [PmsBaseDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PmsTravelInfoModule {}
