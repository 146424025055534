import { Injectable } from '@angular/core';
import { StorageService } from 'services/storage.service';
import { A2hs } from './a2hs';
import { Address } from './address';
import { Contact } from './contact';
import { Coordinates } from './coordinates';
import { Icon } from './icon';
import { Socialmedia } from './socialmedia';
import { Style } from './style';
import { TechStack } from './tech';

@Injectable({ providedIn: 'root' })
export class Business {
  a2hs: A2hs;
  address: Address;
  code: string;
  token: string;
  contact: Contact;
  coordinates: Coordinates;
  currency: string;
  languages: Array<string>;
  icon: Icon;
  id: number;
  image: string;
  logo: string;
  logo_upright: boolean;
  multilingual: boolean;
  name: string;
  mobile_sidebar: boolean;
  desktop_sidebar: boolean;
  language_sidebar: boolean;
  push: any;
  socialmedia: Socialmedia;
  style: Style;
  medias: any;
  map: string;
  maps_protected: boolean;
  vcf: string;
  tech: any;
  legal: any;
  ip_modules: any[];
  updated: Number; // vor storage
  timezone: string;

  constructor(private storageService: StorageService) {}

  setBusiness(business: any) {
    this.id = business.id;
    this.a2hs = new A2hs(business.a2hs);
    this.address = new Address(business.address);
    this.contact = new Contact(business.contact);
    this.coordinates = new Coordinates(business.coordinates);
    this.currency = business.currency.toUpperCase();
    this.languages = business.languages;
    this.icon = new Icon(business.icon);
    this.multilingual = business.multilingual;
    this.name = business.name;
    this.mobile_sidebar = business.style?.mobile_sidebar || business.mobile_sidebar;
    this.desktop_sidebar = business.style?.desktop_sidebar || business.desktop_sidebar;
    this.language_sidebar = business.style?.language_sidebar || business.language_sidebar;
    this.push = business.push;
    this.socialmedia = business.socialmedia;
    this.style = new Style(business.style);
    this.tech = new TechStack(business.tech);
    this.ip_modules = business.ip_modules || [];
    this.legal = business.legal;
    this.token = business.token;
    this.timezone = business.timezone;

    this.medias = business.medias;

    // mapping needed because the api send the code as cryptcode attribute, but we provide only
    // a code, and this is stored locally
    this.code = business.cryptcode || business.code;

    // mapping needed, we store media directly without parent
    // if we save the business as json in the storage, the media parent is gone
    this.image = business.media ? business.media['image'] : business.image;
    this.logo = business.media ? business.media['logo'] : business.logo;
    this.logo_upright = business.media ? business.media['logo_upright'] : business.logo_upright;
    this.map = business.media ? business.media['map'] : business.map;
    this.vcf = business.media ? business.media['vcf'] : business.vcf;

    this.maps_protected = business.maps_protected;

    return this;
  }

  setIpModules(data: any) {
    this.ip_modules = data.ip_modules;

    return this;
  }

  getAddressBlock(bold = false) {
    const name_tag = bold ? `<b>${this.name}</b>` : this.name;
    return name_tag + '<br>' + this.address.address + '<br>' + this.address.zipcode + ' ' + this.address.city;
  }

  listOfLocals(): Array<string> {
    const country = this.address.country?.toLowerCase() || 'de';
    switch (country) {
      case 'fi':
        return ['dk', 'fi', 'se', 'no'];
      default:
        return [country];
    }
  }

  usePms(system = undefined): boolean {
    if (!system) {
      return !!this.tech.pms_system?.type;
    } else if (typeof system === 'string') {
      return system === this.tech.pms_system?.type;
    } else {
      return system.includes(this.tech.pms_system?.type);
    }
  }

  usePayment(provider = undefined): boolean {
    if (!provider) {
      return !!this.tech.payment_provider?.type;
    } else {
      return provider === this.tech.payment_provider?.type;
    }
  }

  protelOnPrem(): boolean {
    return this.usePms('protel') && this.tech.pms_system?.onPremise;
  }

  // save the business
  save() {
    this.storageService.setItem('current_business', this.toLocalStorage());
  }

  private toLocalStorage() {
    const ls_business = {};
    Object.keys(this)
      .filter((k) => k !== 'storageService' && k !== 'cookieService')
      .forEach((k) => (ls_business[k] = this[k]));
    return ls_business;
  }
}
