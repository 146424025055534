<ng-container *ngIf="items.length">
  <swiper [config]="slideConfig" (init)="swiperInit()" (indexChange)="setIndex($event)" (click)="openItem(items[slideIndex]?.media, $event)" class="swiper-container">
    <div *ngFor="let media of items" class="swiper-slide" [class.center]="center">
      <ng-container *ngIf="media.media.type === 'image'">
        <img [src]="media.media.url" [alt]="media.media.alt" />
      </ng-container>
      <ng-container *ngIf="media.media.type === 'video'">
        <video [attr.data-id]="media.media.id" [muted]="!media.media.settings.audio" controls [loop]="media.media.settings.loop" playsinline>
          <source [src]="media.media.url.webm" type="video/webm" />
          <source [src]="media.media.url.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </ng-container>
    </div>
  </swiper>

  <div id="swiperLightbox" class="modal" *ngIf="lightbox">
    <span class="close cursor" (click)="closeModal()">&times;</span>
    <div class="modal-content">
      <div *ngFor="let item of medias | mediaFilter: 'image'" class="imgSlides">
        <img class="imgSlide" [src]="item.media.url" />
      </div>

      <a class="prev" (click)="plusSlides(-1)">&#10094;</a>
      <a class="next" (click)="plusSlides(1)">&#10095;</a>

      <div *ngFor="let item of medias | mediaFilter: 'image'; let i = index" class="column">
        <img class="demo" [src]="item.media.url" (click)="currentSlide(i)" [alt]="item.media.alt" />
      </div>
    </div>
  </div>
</ng-container>
