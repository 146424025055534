<ng-container *ngIf="social">
  <div class="page-headline">
    <h1 translate>group.social_wall.title</h1>
  </div>

  <em translate>{{ 'group.social_wall.description' | translate: business_group }}</em>

  <div class="tiles">
    <ng-container *ngFor="let link of social_keys">
      <a (click)="globals.openUrl(social[link]['url'])" [name]="social[link]['name']" class="tile" *ngIf="social[link]['url'] !== null">
        <i class="fa {{ social[link]['icon'] }}"></i>
        {{ social[link]['name'] }}
      </a>
    </ng-container>
  </div>
</ng-container>
