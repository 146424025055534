import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Globals } from 'base';
import { Business } from 'models/business';
import { Coordinates } from 'models/coordinates';
import { File } from 'models/file';
import { MapProtection } from 'models/map_protection';
import { Module } from 'models/module';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BusinessService } from 'services/business.service';
import { GuestService } from 'services/guest.service';
import { ElementNavigationService } from 'shared/layout/element_navigation/element_navigation.service';
import { EventModuleService } from '../event.service';

@Component({
  selector: 'app-event',
  templateUrl: './entry.component.html',
})
export class EventEntryComponent implements OnInit, OnDestroy {
  SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };

  subscriptions: Subscription = new Subscription();
  module: Module;
  business: Business;
  files: File[] = [];
  entry: any;
  media: any;
  contact: any;
  coordinates: Coordinates;
  address: any;
  appointment: any;
  trigger = true;
  content: SafeHtml;

  constructor(
    public globals: Globals,
    private businessService: BusinessService,
    private elementNav: ElementNavigationService,
    private route: ActivatedRoute,
    private eventService: EventModuleService,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    this.businessService.current_business.pipe(filter(Boolean)).subscribe((business: Business) => {
      this.business = business;
    });

    this.globals
      .getModule('event')
      .then((mod) => {
        this.module = mod;
        this.route.params.subscribe((params) => {
          const id = params['id'];

          this.subscriptions.add(
            this.eventService.getEntry(id).subscribe(
              (success: any) => {
                this.trigger = true;
                this.entry = success.entry;
                this.globals.page = this.entry.gid;
                this.address = this.entry.address;
                this.media = this.entry.media;
                this.contact = this.entry.contact;
                this.appointment = this.entry.appointment;
                this.files = this.entry['attachments'];
                this.content = this.sanitizer.bypassSecurityTrustHtml(this.entry.content);

                this.coordinates = new Coordinates(this.entry.coordinates);
              },
              () => {},
            ),
          );
        });
      })
      .catch(() => {});
  }

  swipe(event: any) {
    if (![event.target?.className, event.target?.parentElement?.className].filter((slide) => slide?.includes('swipe')).length) {
      if (!event.type) {
        event.type = this.SWIPE_ACTION.RIGHT;
      }
      if (event.type === this.SWIPE_ACTION.RIGHT) {
        this.elementNav.next(this.entry);
      }
      if (event.type === this.SWIPE_ACTION.LEFT) {
        this.elementNav.previous(this.entry);
      }
      this.trigger = false;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
