<app-module-title [module]="module"></app-module-title>
<app-pms-login *ngIf="loginRequired; else doorMod" [mod]="ciCoType.door"></app-pms-login>

<ng-template #doorMod>
  <ng-container *ngIf="business && module && !loginRequired">
    <div class="container">
      <div class="container-right" *ngIf="module?.medias?.length">
        <app-swiper [items]="module.medias"></app-swiper>
        <app-widget-advert></app-widget-advert>
      </div>

      <div class="container-left">
        <div class="searchbox" *ngIf="module?.search">
          <input type="search" [(ngModel)]="searchText" placeholder="{{ 'misc.searchenter' | translate }}" />
          <div class="search-icon"><i class="fas fa-search"></i></div>
        </div>

        <app-pms-door-pin-codes [business]="business" [module]="module" [searchText]="searchText" *ngIf="module.settings.modes?.includes('pin_codes')"></app-pms-door-pin-codes>
        <app-pms-door-api [business]="business" [module]="module" [searchText]="searchText" *ngIf="module.settings.modes?.includes('api')"></app-pms-door-api>
      </div>
    </div>
  </ng-container>
</ng-template>
