import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { WidgetSocialModule } from 'widgets/social/social.module';
import { AttachmentsModule } from 'shared/layout/attachments/attachments.module';
import { SharedSwiperModule } from '../shared/layout/swiper/swiper.module';
import { NewsItemComponent } from './modal/news_item.component';
import { NewsRoutingModule } from './news-routing.module';
import { NewsComponent } from './news.component';
import { MatCarouselModule } from 'ng-mat-carousel';

@NgModule({
  imports: [CommonModule, NewsRoutingModule, AttachmentsModule, WidgetSocialModule, TranslateModule, MatCarouselModule.forRoot(), SharedSwiperModule],
  exports: [NewsComponent],
  declarations: [NewsComponent, NewsItemComponent],
})
export class NewsModule {}
