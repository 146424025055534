import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { DateValidatorModule } from 'validators/date.validator.module';
import { DatePickerRoutingModule } from './datepicker-routing.module';
import { DatepickerComponent } from './datepicker.component';

@NgModule({
  imports: [CommonModule, DatePickerRoutingModule, MatDatepickerModule, FormsModule, TranslateModule, ReactiveFormsModule, MatInputModule, DateValidatorModule],
  exports: [DatepickerComponent],
  declarations: [DatepickerComponent],
})
export class DatePickerModule {}
