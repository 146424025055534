import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PmsCheckInModule } from '../../check_in/check_in.module';
import { PmsCheckOutModule } from '../../check_out/check_out.module';
import { PmsOverlayModule } from '../../shared/overlay/overlay.module';
import { PmsWizardTerminalDoorModule } from '../door/door.module';
import { PmsWizardTerminalOptionRoutingModule } from './option-routing.module';
import { PmsWizardTerminalOptionComponent } from './option.component';

@NgModule({
  imports: [CommonModule, PmsWizardTerminalOptionRoutingModule, PmsCheckInModule, PmsCheckOutModule, TranslateModule, PmsWizardTerminalDoorModule, PmsOverlayModule, FormsModule],
  exports: [PmsWizardTerminalOptionComponent],
  declarations: [PmsWizardTerminalOptionComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PmsWizardTerminalOptionModule {}
