import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Business } from 'models/business';
import { BusinessService } from 'services/business.service';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
})
export class LocationComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  business: Business;
  showHint = false;

  @Input() description: string;

  constructor(
    private businessService: BusinessService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.description = this.description || 'gps.desc';
    this.subscriptions.add(
      this.businessService.current_business.pipe(filter(Boolean)).subscribe((business: Business) => {
        this.business = business;
      }),
    );
    this.route.fragment.subscribe((fragment: string) => {
      if (fragment === 'denied') {
        this.showHint = true;
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
