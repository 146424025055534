import { Step } from 'pms_enums';

export enum StepperState {
  active = 'active',
  disabled = 'disabled',
  done = 'done',
}

export enum StepperActions {
  none = 'none',
  next = 'next',
  previous = 'previous',
}

export type SubStep = GuestSubSteps | TravelInfoSubSteps | InvoiceSubSteps | ConfirmSubSteps | 'unknown';
export interface Stepper {
  key: Step;
  icon: string;
  totalSteps: number;
  subSteps: SubStep[];
  currentStep: number;
  state: string;
  action: string;
}

export enum GuestSubSteps {
  primaryGuest = 'primary_guest',
  privateAddress = 'private_address',
  identificationGuest = 'identification_guest',
  otherGuest = 'other_guest',
}

export enum TravelInfoSubSteps {
  reservation = 'reservation',
  cusBreakfast = 'cus_breakfast',
  customFields = 'custom_fields',
}

export enum InvoiceSubSteps {
  invoice = 'invoice',
}

export enum ConfirmSubSteps {
  confirm = 'confirm',
}

export const stepConfigurations: { [key: string]: { icon: string; action: string } } = {
  guests: { icon: 'fas fa-people-group', action: StepperActions.none },
  reservation: { icon: 'fas fa-suitcase-rolling', action: StepperActions.none },
  invoice: { icon: 'fas fa-file-invoice', action: StepperActions.none },
  confirm: { icon: 'fas fa-check', action: StepperActions.none },
};
