import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { EmailValidatorModule } from 'validators/email.validator.module';
import { DatePickerModule } from 'shared/forms/datepicker/datepicker.module';
import { FormFieldsModule } from 'shared/forms/fields/fields.module';
import { PmsInvoiceAddressFormModule } from '../../shared/invoice_address/form/form.module';
import { PmsCoGeneralRoutingModule } from './general-routing.module';
import { PmsCoGeneralComponent } from './general.component';
import { PmsBaseDirective } from 'pms_base/base.directive';
import { TypographyModule } from 'common/typography/typography.module';

@NgModule({
  imports: [CommonModule, PmsCoGeneralRoutingModule, PmsInvoiceAddressFormModule, FormsModule, DatePickerModule, FormFieldsModule, EmailValidatorModule, TranslateModule, TypographyModule],
  exports: [PmsCoGeneralComponent],
  declarations: [PmsCoGeneralComponent],
  providers: [PmsBaseDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PmsCoGeneralModule {}
