<div class="popup-text" *ngIf="timetable">
  <p>{{ 'service_time.available' | translate }}</p>
  <table class="timetable">
    <tbody>
      <tr *ngFor="let day of days" [class.main-bg]="timetable[day].today">
        <td class="tableday" [innerHtml]="timetable[day].name"></td>
        <td>
          <span *ngIf="!timetable[day].available" translate>service_time.no_service</span>
          <ng-container *ngIf="timetable[day].available">
            <span *ngIf="timetable[day].available" class="time">
              {{ timetable[day].from }} - {{ timetable[day].till }}
              <ng-container *ngIf="hasAfternoon(timetable[day])">
                / <span>{{ timetable[day].afternoon_from }}</span> - <span>{{ timetable[day].afternoon_till }}</span>
              </ng-container>
            </span>
          </ng-container>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="button-grid">
  <a translate class="button light close" (click)="close()">misc.close</a>
</div>
