import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Globals } from 'base';
import { Subscription } from 'rxjs';
import { A2hsService } from 'services/a2hs.service';

@Component({
  selector: 'app-a2hs-bar',
  templateUrl: './a2hs.component.html',
})
export class A2hsComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();

  @ViewChild('container', { static: true }) container: ElementRef;

  constructor(
    private translate: TranslateService,
    private sanitizer: DomSanitizer,
    public a2hsService: A2hsService,
    public globals: Globals,
  ) {}

  ngOnInit() {
    this.a2hsService.a2hsEl = this.container.nativeElement;
    this.subscriptions.add(
      this.translate.get('a2hs.browser.' + this.a2hsService.text).subscribe((content) => {
        this.a2hsService.content = content;
      }),
    );

    this.subscriptions.add(
      this.a2hsService.isBeforeInstallNonWhitelist.subscribe(() => {
        this.subscriptions.add(
          this.translate.get('a2hs.text.' + this.a2hsService.browser).subscribe((firstBtnContent) => {
            this.a2hsService.btnContent = this.sanitizer.bypassSecurityTrustHtml(firstBtnContent);
          }),
        );
      }),
    );
  }

  hide() {
    this.a2hsService.remindLater();
  }

  install() {
    this.a2hsService.install();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
