<app-pms-login class="step-container" (reservation_data)="setReservation(ciCoType.ci)" [mod]="ciCoType.ci" *ngIf="loginRequired; else process"></app-pms-login>
<ng-template #process>
  <ng-template [ngTemplateOutlet]="loading" *ngIf="cicoService.autoSkipUntilStep || !(loaded && data?.incident?.reservation && data?.module?.type === ciCoType?.ci)"></ng-template>
  <ng-container *ngIf="loaded && data?.incident?.reservation && data?.module?.type === ciCoType?.ci">
    <div class="grid-container" [class.hidden]="showOverlay || cicoService.autoSkipUntilStep">
      <div class="content">
        <ng-container *ngIf="!this.data.done">
          <app-steps-indicator></app-steps-indicator>

          <straiv-section-header heading="{{ this.cicoService.sectionDetails.sectionHeader }}" description="{{ this.cicoService.sectionDetails.sectionDescription }}" info="{{ this.cicoService.sectionDetails.sectionInfo }}"></straiv-section-header>
          <div class="required-text instructions" *ngIf="!cicoService.showContinue" translate>misc.required_fields</div>

          <app-pms-ci-general class="animation-out step-container" #guests *ngIf="cicoService.activeStep?.key === 'guests'" [locale]="locale"></app-pms-ci-general>

          <app-pms-ci-travel-info class="animation-out step-container" #reservation [submited]="formSubmited" *ngIf="this.cicoService.activeStep?.key === 'reservation'" [locale]="locale"></app-pms-ci-travel-info>

          <app-pms-folios class="animation-out step-container" #invoice [payment]="payment" *ngIf="this.cicoService.activeStep?.key === 'invoice'"></app-pms-folios>
        </ng-container>

        <app-pms-confirm class="animation-out step-container" #confirm [submitted]="formSubmited" *ngIf="this.cicoService.activeStep?.key === 'confirm'"></app-pms-confirm>
      </div>
    </div>
    <app-pms-footer *ngIf="cicoService.showFooter && !cicoService.autoSkipUntilStep" [idnow]="idnow" [currentIndex]="this.cicoService.activeStep?.currentStep" [confirmName]="confirmName"></app-pms-footer>
  </ng-container>
</ng-template>

<app-pms-cico-overlay [business]="globals.business" [content]="olContent" *ngIf="globals.business && showOverlay"></app-pms-cico-overlay>

<ng-template #loading>
  <app-loader *ngIf="!loaded || cicoService.autoSkipUntilStep"></app-loader>
  <app-info-screen [type]="cicoService.loadingError.type" [text]="cicoService.loadingError.message" *ngIf="loaded && cicoService.loadingError"></app-info-screen>
</ng-template>
