import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from 'environments/environment';
import firebase from 'firebase/app';
import 'firebase/messaging';
import 'hammerjs';
import { Workbox } from 'workbox-window';
import { AppModule } from './app/app.module';
import { defineCustomElements } from '@straiv/components/loader';

defineCustomElements(window);

if (environment.production) {
  enableProdMode();
}

document.getElementById('preconnect').setAttribute('href', environment.api_url);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => {
    if ('serviceWorker' in navigator && environment.production) {
      if (window.location.href.includes('/g/')) {
        const scope = window.location.origin + window.location.pathname.split('/').slice(0, 3).join('/');
        const code = window.location.pathname.split('/').slice(2, 3).join('');
        const wb = new Workbox('/c2oWorker.min.js', { scope: scope });

        wb.register()
          .then((reg) => {
            if (firebase.apps.length === 0) {
              firebase.initializeApp(environment.firebase);
            }
            if (firebase.messaging.isSupported()) {
              // TODO Add correct vapid key
              // firebase.messaging().getToken({ vapidKey: environment.firebaseServerKey, serviceWorkerRegistration: reg });
              firebase.messaging().useServiceWorker(reg);
            }

            reg.update();

            wb.messageSW({ type: 'GET_VERSION' })
              .then((version: string) => {
                window.localStorage?.setItem('swVersion', version);
              })
              .catch((err) => console.log(err));

            wb.messageSW({ type: 'SET_CODE', code: code }).then().catch();
          })
          .catch((err) => console.log(err));
      }
    }
  })
  .catch(() => {});
