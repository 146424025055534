import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { Globals } from 'base';
import { Field } from 'models/field';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { interval, Subscription } from 'rxjs';
import { Business } from 'models/business';
import { GenericService } from 'models/generic_service';
import { filter, take, takeWhile } from 'rxjs/operators';
import { BusinessService } from '../../../../_services/business.service';
import { ServiceService } from '../../../../_services/service.service';
import { LanguageService } from '../../../../common/language/language.service';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class DatepickerComponent implements OnInit {
  display: string;
  hint: boolean;
  type: any;
  helper: Date | string;
  start_with_day: boolean;
  validation: boolean;
  input_mask: any;
  input_mask2: any;
  signs = ['.', '/', '-'];
  service: GenericService;
  mobile: boolean;
  subscriptions: Subscription = new Subscription();
  business: Business;

  @Input() model: any;
  @Input() key: string;
  @Input() name: string;
  @Input() description: string;
  @Input() errorLabel: string;
  @Input() tooltip?: string;
  @Input() minDate: Date | string;
  @Input() maxDate: Date | string;
  @Input() startView: 'month' | 'year' | 'multi-year';
  @Input() disabled: boolean = false;
  @Input() required: boolean;
  @Input() submited: boolean;
  @Input() redesign: boolean;
  @Input() value: Date | string;
  @Input() field: Field;
  @Input() inputType = 'date';
  @Input() placeholder: string;
  @Input() filterDays: any;
  @Input() unavailableDays = [];
  @Output() changed = new EventEmitter<any>();
  formattedDate: string = '';

  constructor(
    private _adapter: DateAdapter<any>,
    public globals: Globals,
    private toastService: ToastrService,
    private languageService: LanguageService,
    private serviceService: ServiceService,
    private businessService: BusinessService,
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.businessService.current_business.pipe(filter(Boolean)).subscribe((business: Business) => {
        this.business = business;
        this.createForm();
        this.globals.locale() === 'en' ? this._adapter.setLocale('en-GB') : this._adapter.setLocale(this.globals.locale());
        this.name =
          this.name ||
          Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, '')
            .substr(0, 5);
        this.mobile = this.globals.isMobile();

        this.defineDisplay();
        this.set_values();
        if (this.display === 'picker' && this.name) {
          setTimeout(() => {
            this.buildPicker();
          }, 100);
        }
      }),
    );
  }

  ngOnChanges() {
    if (this.business) {
      this.set_values();
    }
  }

  ngDoCheck() {
    if (this.model[this.key] === 'YYYY-MM-DD') {
      setTimeout(() => {
        this.helper = null;
        this.model[this.key] = null;
      });
    }
  }

  set_values() {
    if (this.value) {
      this.model[this.key] = this.value;
    }
    if (this.model[this.key]) {
      this.buildHelper(true, this.model[this.key], this.display !== 'picker' && this.disabled);
    }
    if (this.field) {
      this.setMinMaxDate();
    }
  }

  createForm() {
    this.service = this.serviceService.newService();
  }

  defineDisplay() {
    if (this.inputType === 'text' || this.disabled) {
      this.display = 'picker';
    } else {
      this.display = this.mobile ? 'mobile' : 'desktop';
    }
  }

  buildPicker() {
    this.buildPlaceholder();
    this.start_with_day = this.placeholder.startsWith('DD');
    this.build_inputMask();
    setTimeout(() => {
      interval(10)
        .pipe(
          takeWhile(() => document.getElementById(this.name) !== null),
          take(1),
        )
        .subscribe(() => {
          Inputmask('datetime', { jitMasking: true, inputFormat: this.input_mask, insertMode: false }).mask(document.getElementById(this.name));
        });

      if (this.globals.locale() !== 'ar') {
        Inputmask('datetime', { jitMasking: true, inputFormat: this.input_mask2 }).mask(document.querySelectorAll('input#datepicker'));
      }
      Inputmask('datetime', { jitMasking: true, inputFormat: this.input_mask2 }).mask(document.querySelectorAll('input#mobile_datepicker'));
    }, 100);
  }

  build_inputMask() {
    this.input_mask = this.placeholder.replace('DD', 'dd').replace('TT', 'dd').replace('MM', 'mm').replace('YYYY', 'yyyy').replace('JJJJ', 'yyyy');
    this.input_mask = this.input_mask.toString().replace(/\./g, '.').replace(/-/g, '-').replace(/\//g, '/');
    this.input_mask2 = this.input_mask.toString().replace(/ /g, '');
  }

  buildHelper(create = true, val = this.value, insertWhitespace = true) {
    if (create) {
      const date = new Date(val);
      const language = this.globals.locale();
      this.helper = date.toLocaleDateString(this.languageService.mapping[language].date, { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: this.business.timezone });
      if (this.languageService.mapping[language].date === 'cs' && this.display === 'picker' && !this.disabled) {
        this.helper = this.removeWhitespace(this.helper);
      }
    } else if (val) {
      const value = val.toString();
      this.helper = this.signs.some((sign) => value.includes(sign)) ? value : this.insertSign(value, this.start_with_day ? 2 : 4);
      let temp = this.helper.toString();
      if (temp.length === 17 && <any>temp[temp.length - 1] <= 3) {
        temp = temp.slice(0, -1) + '0' + temp[temp.length - 1];
        this.helper = temp;
      }
    }
    this.helper = this.insertWhitespace(this.helper, insertWhitespace);
  }

  buildPlaceholder() {
    switch (this.globals.locale()) {
      case 'en': {
        this.placeholder = 'DD/MM/YYYY';
        break;
      }
      case 'ar': {
        this.placeholder = 'YYYY/MM/DD';
        break;
      }
      default: {
        this.placeholder = moment(new Date()).localeData().longDateFormat('L');
        break;
      }
    }
    this.placeholder = this.insertWhitespace(this.placeholder, true);
  }

  insertWhitespace(string, insert = false) {
    return insert ? (<any>string)?.toString()?.replace(/\//g, '  /  ')?.replace(/-/g, '  -  ')?.replace(/\./g, '  .  ') : string;
  }

  removeWhitespace(string) {
    return string?.toString()?.replace(/ /g, '').replace(/ /g, '');
  }

  insertSign(value, length) {
    this.signs.forEach((sign) => {
      if (this.placeholder.includes(sign)) {
        return value.substr(0, length) + sign + value.substr(length, 2) + sign + value.substr(length + 2);
      }
    });
    return this.helper;
  }

  removeTimestamp(event) {
    return new Date(event).toISOString().split('T')[0];
  }

  removeTimestampDatepicker() {
    this.model[this.key] = this.model[this.key].split('T')[0];
  }

  remove_timestamp() {
    if (this.model[this.key]) {
      this.model[this.key] = new Date(this.model[this.key]).toISOString().split('T')[0];
    }
    if (!this.disabled) {
      this.validate(this.unavailableDays);
    }
  }

  blur() {
    if (!this.disabled) {
      this.changed.emit(true);
    }
    this.buildHelper(false, this.helper, this.display !== 'picker');
    this.model[this.key] = this.formatString(this.helper);
    if (!this.model[this.key] || !this.helper) {
      this.helper = null;
      this.model[this.key] = null;
    }
  }

  formatString(datestring) {
    let date = datestring?.toString()?.replace(/  /g, '')?.replace(/\//g, '-')?.replace(/\./g, '-');
    date = date?.length && date[date?.length - 1] === '-' ? date.slice(0, -1) : date;
    if (date?.length === 9 && date[date?.length - 1] <= 3) {
      date = date?.slice(0, -1) + '0' + date[date?.length - 1];
    }
    date = this.start_with_day ? this.changeDirection(date) : date;
    if (date?.length) {
      return date.length <= 8 ? this.fill_missing_digits(date) : date;
    }
  }

  fill_missing_digits(date) {
    let date_parts = date?.split('-');
    let year = date_parts[0];
    if (year.length === 2) {
      year = year <= new Date().getFullYear() % 100 ? '20' + year : '19' + year;
      return year + '-' + date_parts[1] + '-' + date_parts[2];
    } else {
      this.model[this.key] = null;
      this.helper = null;
      return null;
    }
  }

  changeDirection(date) {
    const split = date?.split('-');
    return split ? split[2] + '-' + split[1] + '-' + split[0] : date;
  }

  setMinMaxDate() {
    switch (this.field.validation) {
      case 'future':
        this.minDate = new Date(this.globals.today());
        this.maxDate = new Date('2200-12-31');
        break;
      case 'past':
        this.minDate = new Date('1870-01-01');
        this.maxDate = new Date(this.globals.today());
        break;
      default:
        this.minDate = new Date('1870-01-01');
        this.maxDate = new Date(this.globals.today(365 * 20));
    }
  }

  validate(unavailableDays) {
    this.changed.emit(true);
    const day = new Date(this.model[this.key]).getDay();
    if (unavailableDays.includes(day)) {
      this.toastService.error(this.globals.translate('misc.not_open'));
      this.model[this.key] = '';
      this.hint = true;
    }
  }

  isInvalid(picker): boolean {
    return picker.classList.contains('ng-invalid');
  }

  change(event) {
    const picker = event.target;
    const value = picker.value;
    this.helper = value;
    this.blur();
    this.hint = this.isInvalid(picker) && value.includes('.') && this.notGerman();
  }

  remover(event) {
    event !== '' ? (this.helper = this.removeWhitespace(event)) : (this.helper = this.placeholder);
  }

  test(event) {
    const picker = event.target;
    const value = picker.value.split('T')[0];
    this.hint = this.isInvalid(picker) && value.includes('.') && this.notGerman();
  }

  notGerman(): boolean {
    return this.globals.locale() !== 'de';
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
