import { Directive, OnDestroy, OnInit } from '@angular/core';
import { Globals } from 'base';
import { Business } from 'models/business';
import { Module } from 'models/module';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BusinessService } from 'services/business.service';

@Directive()
export class ServiceAllDirective implements OnInit, OnDestroy {
  mod: string;

  subscriptions: Subscription = new Subscription();
  module: Module;
  business: Business;

  categories: any;
  entries: string[];

  searchText: string;
  medias: [];
  rightSide: boolean;

  constructor(
    public globals: Globals,
    protected businessService: BusinessService,
  ) {}

  ngOnInit(service: any = null): any {
    return new Promise<void>((resolve, reject) => {
      this.businessService.current_business.pipe(filter(Boolean)).subscribe((business: Business) => {
        this.business = business;
        this.globals
          .getModule(this.mod)
          .then((mod) => {
            this.module = mod;
            this.medias = this.module.medias;
            this.rightSide = this.medias && this.medias.length > 0;

            this.subscriptions.add(
              service.getAll().subscribe(
                (success: any) => {
                  this.categories = success.categories;
                  this.entries = success.entries;
                  resolve();
                },
                () => {},
              ),
            );
          })
          .catch(() => {
            reject();
          });
      });
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
