<div class="background" *ngIf="show">
  <div class="popup">
    <h2 class="title main-text" translate>service.check_in.terminal.session_timeout</h2>
    <p class="text" translate>service.check_in.terminal.inactivity</p>
    <strong class="countdown">{{ cicoService.intervalValue }}</strong>
    <div class="center-button">
      <button class="btn-filled" (click)="closeOverlay()" translate>service.check_in.terminal.keep_session</button>
    </div>
  </div>
</div>
