import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgStringPipesModule } from 'ngx-pipes';
import { FilterPipeModule } from 'pipes/filter.pipe.module';
import { EmailValidatorModule } from 'validators/email.validator.module';
import { WidgetAdvertModule } from 'widgets/advert/advert.module';
import { FormFieldsModule } from 'shared/forms/fields/fields.module';
import { ImageModule } from 'shared/forms/image/image.module';
import { FormPhoneFieldModule } from 'shared/forms/phone/phone_field.module';
import { FormPlaceModule } from 'shared/forms/place/place.module';
import { QuantityModule } from 'shared/forms/quantity/quantity.module';
import { FormSelectModule } from 'shared/forms/select/select.module';
import { SendCancelModule } from 'shared/forms/send_cancel/send_cancel.module';
import { ElementNavigationModule } from 'shared/layout/element_navigation/element_navigation.module';
import { ModuleTitleModule } from 'shared/layout/module_title.module';
import { SharedSwiperModule } from 'shared/layout/swiper/swiper.module';
import { ComplaintRoutingModule } from './complaint-routing.module';
import { ComplaintComponent } from './complaint.component';
import { ComplaintDirectComponent } from './direct/direct.component';
import { ComplaintListAllComponent } from './list/all/all.component';
import { ComplaintListParentModule } from './list/parent/parent.module';

@NgModule({
  imports: [CommonModule, ComplaintRoutingModule, FormsModule, WidgetAdvertModule, TranslateModule, ModuleTitleModule, SharedSwiperModule, NgStringPipesModule, FilterPipeModule, QuantityModule, ImageModule, FormFieldsModule, FormPlaceModule, FormPhoneFieldModule, SendCancelModule, ElementNavigationModule, EmailValidatorModule, FormSelectModule, ComplaintListParentModule],
  exports: [ComplaintComponent],
  declarations: [ComplaintComponent, ComplaintListAllComponent, ComplaintDirectComponent],
})
export class ComplaintModule {}
