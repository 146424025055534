<app-pms-terminal-timer></app-pms-terminal-timer>

<ng-container *ngIf="reservation || cicoService.process === PmsProcess.booking; else start">
  <ng-container *ngIf="reservation">
    <app-pms-terminal-option *ngIf="showAvailableActions" (reset_terminal)="reset()" [reservation]="reservation" [business]="business" [options]="options" [actionNotAvailable]="actionNotAvailable" (selOptionChange)="handleAction($event)"></app-pms-terminal-option>
    <app-pms-terminal-door *ngIf="cicoService.process === PmsProcess.door" [reservation]="reservation" [type]="doorType"></app-pms-terminal-door>
    <app-pms-kiosk-reservation *ngIf="cicoService.process === PmsProcess.reservation" [reservation]="reservation"></app-pms-kiosk-reservation>
    <app-check-in *ngIf="cicoService.process === PmsProcess.check_in" [res]="reservation"></app-check-in>
    <app-check-out *ngIf="cicoService.process === PmsProcess.check_out" [res]="reservation"></app-check-out>
  </ng-container>
  <app-pms-booking *ngIf="cicoService.process === PmsProcess.booking"></app-pms-booking>
</ng-container>

<ng-template #start>
  <div id="welcome" *ngIf="business && module && place" [style.background-image]="'url(' + imageUrl + ')'">
    <div class="content" [class.widget-active]="multipleActions || cicoService.process">
      <div class="welcome-section welcome-page-msg" *ngIf="place.welcome">
        <p class="welcome-text welcome-page-msg">
          <span translate>misc.welcome</span><br />
          <span>{{ business.name }}</span>
        </p>
      </div>
      <div class="content-widget" *ngIf="terminal && (cicoService.process || multipleActions)">
        <app-pms-wizard-actions *ngIf="!cicoService.process && multipleActions" [actions]="kiosk_actions" (actionClick)="handleAction($event)"></app-pms-wizard-actions>
        <app-pms-login *ngIf="cicoService.process && !reservation && cicoService.process !== PmsProcess.booking" (reservation_data)="setReservation($event)" (cancelLogin)="reset()" [wizardModule]="module" [mod]="modType" [deviceSize]="deviceSize"></app-pms-login>
      </div>
      <straiv-button *ngIf="!cicoService.process && !multipleActions && kiosk_actions" name="start-now-button" [leftLabel]="kiosk_actions[0].name" variant="flat" width="full-width" size="xxl" value="button" (buttonClick)="handleAction(kiosk_actions[0].connection)"></straiv-button>

      <span *ngIf="showDeviceName" class="terminal-info">{{ place.name }}</span>
    </div>
    <app-debug></app-debug>
  </div>
</ng-template>

<app-conflict *ngIf="wsService.showConflict"></app-conflict>
