import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgPipesModule } from 'ngx-pipes';
import { FilterPipeModule } from 'pipes/filter.pipe.module';
import { WidgetAdvertModule } from 'widgets/advert/advert.module';
import { BacklinkModule } from 'shared/layout/backlink/backlink.module';
import { SharedSwiperModule } from 'shared/layout/swiper/swiper.module';
import { ServiceParentDirective } from 'shared/service/parent.directive';
import { WishListParentRoutingModule } from './parent-routing.module';
import { WishListParentComponent } from './parent.component';

@NgModule({
  imports: [CommonModule, WishListParentRoutingModule, SharedSwiperModule, WidgetAdvertModule, TranslateModule, FilterPipeModule, NgPipesModule, BacklinkModule, FormsModule],
  exports: [WishListParentComponent],
  declarations: [WishListParentComponent],
  providers: [ServiceParentDirective],
})
export class WishListParentModule {}
