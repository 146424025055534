<form #serviceForm="ngForm" class="redesign" *ngIf="type === DoorType.cards && cicoService.doorStep === lostKeyStep.lostOrDuplicate && this.encoderData.already_encoded">
  <fieldset>
    <div class="encode">
      <h2 class="reason" translate>{{ 'pms_door.terminal.reason.title' | translate }}</h2>
      <div class="actions-container">
        <straiv-actioncard *ngFor="let action of mainActions" [icon]="action.icon" [text]="action.text | translate" (click)="lostChanged(action.type)"></straiv-actioncard>
      </div>
    </div>
  </fieldset>
</form>

<app-pms-encoder id="card_encoder" [encoderData]="encoderData" *ngIf="type === DoorType.cards && cicoService.doorStep === lostKeyStep.encoder"></app-pms-encoder>
<app-pms-ci-pin-codes [id]="encoderData.id" *ngIf="type === DoorType.pin_codes" [terminal]="true" [hideFinishButton]="true"></app-pms-ci-pin-codes>

<app-pms-cico-overlay [business]="globals.business" [content]="olContent" *ngIf="globals.business && showOverlay"></app-pms-cico-overlay>
