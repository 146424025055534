import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Globals } from 'base';
import { PmsCiCoService } from 'cico_service';
import { LanguageService } from 'common/language/language.service';
import { PmsPaymentService } from 'payment_service';
import { OverlayAction, PaymentProvider, PmsModType } from 'pms_enums';
import { GenericData } from 'pms_models/generic_data';
import { PmsService } from 'pms_service';
import { interval, Observable, of, Subscription } from 'rxjs';
import { delay, filter, take, takeWhile } from 'rxjs/operators';

declare let PayEngineWidget: any;
declare let AdyenCheckout: any;
declare let Datatrans: any;

@Component({
  selector: 'app-pms-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PmsPaymentComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  payment: any;
  dropin: any;
  order: any;
  check: string;
  loaded: boolean;
  timeout = 2000;
  pms_token: string;
  ui_messages: any;
  observer: any;

  @Input() data: GenericData;
  @Input() folio: any;
  @Input() preAuth: any;
  @Input() payButton: any;

  constructor(
    public globals: Globals,
    private pmsService: PmsService,
    private languageService: LanguageService,
    private cicoService: PmsCiCoService,
    private paymentService: PmsPaymentService,
  ) {}

  ngOnInit(): void {
    this.cicoService.setFolioAddressStep('payment');
    this.frameDetection();
    this.payment = this.data.incident.reservation.payment_providers;
    this.ui_messages = this.cicoService.ui_messages();
    this.check = this.folio?.check;

    switch (this.payment.provider) {
      case PaymentProvider.adyen:
        this.listenToClose();
        this.adyenLoader();
        break;
      case PaymentProvider.concardis:
        this.cicoService.toggleInactivity(false);
        this.concardisLoader();
        break;
      case PaymentProvider.datatrans:
        this.cicoService.toggleInactivity(false);
        this.datatransLoader();
        break;
      case PaymentProvider.worldline:
        this.listenToClose();
        this.worldlineLoader();
        break;
      case PaymentProvider.ibelsa_pay:
        this.listenToClose();
        this.ibelsaPayLoader();
        break;
    }
  }

  // Adyen

  private adyenLoader() {
    this.paymentService.makePayment(PmsPaymentComponent.dataInformation(null, this)).subscribe(
      (result: any) => {
        const self = this;
        this.loaded = true;
        const locale = this.languageService.mapping[this.globals.locale()]['adyen'];
        const configuration = {
          paymentMethodsConfiguration: this.getPaypalInformation(),
          environment: this.payment.test_env ? 'test' : 'live',
          locale: locale,
          shopperReference: result.shopperReference,
          clientKey: this.payment.key,
          session: {
            id: result.id,
            sessionData: result.sessionData,
          },
          conponent: this,
          paymentMethodsResponse: this.payment.methods,
          onSubmit: (state) => {
            this.handleOnSubmit(state, self);
          },
          onAdditionalDetails: (state) => {
            this.handleOnAdditionalDetails(state, self);
          },
          onClickCancel: () => {
            this.dropin.setStatus('ready');
          },
          onError: (err) => {
            console.log(err);
          },
        };

        this.subscriptions.add(
          interval(250)
            .pipe(
              takeWhile(() => 'AdyenCheckout' in window),
              take(1),
            )
            .subscribe(() => {
              AdyenCheckout(configuration).then((checkout) => {
                this.dropin = checkout.create('dropin').mount('#paymentContainer');
              });
            }),
        );
      },
      (error) => {
        this.paymentError(error);
      },
    );
  }

  private handleOnSubmit(state, self) {
    self.adyenButtonDisable();
    self.paymentService.makePayment(PmsPaymentComponent.dataInformation(state, self)).subscribe(
      (response: any) => {
        self.data.incident.reservation.payment_token = response.pms_token;
        if (response?.action) {
          self.saveInOnlineCache(self).subscribe(
            () => {
              self.dropin.handleAction(response.action);
            },
            (_e) => {
              self.adyenError(self);
            },
          );
        } else {
          self.checkTransaction(self);
        }
      },
      (error) => {
        self.adyenError(self, error);
      },
    );
  }

  private handleOnAdditionalDetails(state, self) {
    self.adyenButtonDisable(false);
    self.paymentService.paymentDetails(PmsPaymentComponent.dataInformation(state, self)).subscribe(
      (response: any) => {
        response?.action ? self.dropin.handleAction(response.action) : self.checkTransaction(self);
      },
      () => {
        self.adyenError(self);
      },
    );
  }

  private adyenError(self, error = null) {
    self.dropin.setStatus('error');
    self.enablePayButton();
    self.adyenButtonDisable(false);
    self.reloadFolios(error);
  }

  private adyenButtonDisable(disabled = true) {
    document.querySelectorAll('button.adyen-checkout__button--pay').forEach((button: HTMLButtonElement) => {
      button.disabled = disabled;
    });
  }

  // Concardis

  private concardisLoader() {
    this.timeout = 0;
    if (this.folio) {
      this.setFolioCheck('pending');
    }
    const callback = (error, result) => {
      if (result?.code === 0) {
        this.concardisReset();
      }

      this.loaded = false;
      let data = PmsPaymentComponent.dataInformation(null, this);
      if (error) {
        data = { ...{ error: true, body: error, result: result }, ...data };
      }

      this.paymentService.transactionCreated(this.type(), data).subscribe(
        () => {
          this.checkTransaction(this);
        },
        () => {
          this.concardisReset();
        },
      );
    };

    let observable;
    if (this.type() === 'payment') {
      observable = this.paymentService.makePayment(PmsPaymentComponent.dataInformation(null, this));
    } else {
      observable = this.paymentService.makePreAuth(PmsPaymentComponent.dataInformation(null, this));
    }

    observable.pipe(filter((data: any) => data.order)).subscribe(
      (result: any) => {
        if (result.order.orderId?.length) {
          this.order = result.order;
          this.data.incident.reservation.payment_token = result.pms_token;
          this.saveInOnlineCache(this).subscribe(
            () => {
              this.subscriptions.add(
                interval(250)
                  .pipe(
                    takeWhile(() => 'PayEngineWidget' in window),
                    take(1),
                  )
                  .subscribe(() => {
                    PayEngineWidget.initAsModal(this.payment.merchant, this.order.orderId, result.data, callback);
                    this.cicoService.closeOverlay(OverlayAction.close);
                    this.loaded = true;
                  }),
              );
            },
            () => {
              this.paymentError();
            },
          );
        } else {
          this.paymentError();
        }
      },
      (error) => {
        this.paymentError(error);
      },
    );
  }

  private concardisReset() {
    this.enablePayButton();
    this.setFolioCheck(this.check);
    return this.cicoService.closeOverlay(OverlayAction.close);
  }

  // Worldline

  private worldlineLoader() {
    let observable;
    if (this.type() === 'payment') {
      observable = this.paymentService.makePayment(PmsPaymentComponent.dataInformation(null, this));
    } else {
      observable = this.paymentService.makePreAuth(PmsPaymentComponent.dataInformation(null, this));
    }

    observable.subscribe(
      (result: any) => {
        if (result?.Behavior !== 'ABORT') {
          this.saveInOnlineCache(this).subscribe(
            () => {
              this.loaded = true;
              this.cicoService.logUnload = false;
              location.href = result?.RedirectUrl;
            },
            () => {
              this.paymentError();
            },
          );
        } else {
          this.paymentError();
        }
      },
      (error) => {
        this.paymentError(error);
      },
    );
  }

  // IbelsaPay

  private ibelsaPayLoader() {
    this.paymentService.makePayment(PmsPaymentComponent.dataInformation(null, this)).subscribe(
      (result: any) => {
        if (result.success) {
          this.saveInOnlineCache(this).subscribe(
            () => {
              this.loaded = true;
              this.cicoService.logUnload = false;
              location.href = result.data.data.url;
            },
            () => {
              this.paymentError();
            },
          );
        } else {
          this.paymentError();
        }
      },
      (error) => {
        this.paymentError(error);
      },
    );
  }

  // Datatrans

  private datatransLoader() {
    let observable;
    if (this.type() === 'payment') {
      observable = this.paymentService.makePayment(PmsPaymentComponent.dataInformation(null, this));
    } else {
      observable = this.paymentService.makePreAuth(PmsPaymentComponent.dataInformation(null, this));
    }

    observable.subscribe(
      (result: any) => {
        if (result.transactionId?.length) {
          this.data.incident.reservation.payment_token = result.pms_token;
          this.saveInOnlineCache(this).subscribe(
            () => {
              this.subscriptions.add(
                interval(250)
                  .pipe(
                    takeWhile(() => 'Datatrans' in window),
                    take(1),
                  )
                  .subscribe(() => {
                    Datatrans.startPayment({
                      transactionId: result.transactionId,
                    });
                    this.enablePayButton();
                    this.cicoService.closeOverlay(OverlayAction.close);
                    this.loaded = true;
                  }),
              );
            },
            () => {
              this.paymentError();
            },
          );
        } else {
          this.paymentError();
        }
      },
      (error) => {
        this.paymentError(error);
      },
    );
  }

  // All

  private checkTransaction(self) {
    setTimeout(() => {
      if (self.folio) {
        this.paymentService.paymentSubj.next(self.folio);
      }
      if ([PmsModType.ci, PmsModType.reservation].includes(self.data.module.type) && this.type() === 'payment') {
        this.paymentService.checkPayment(self.data.incident.reservation.payment_token).subscribe((response: any) => {
          this.setFolioCheck(response.state);
          this.setScaValid(response.sca_valid);
          this.folio.existing_payments = response.existing_payments;
          if (['pending', 'paid'].includes(response.state)) {
            this.folio.balance = 0;
          }
          this.checkPayment([this.folio], self);
        });
      } else {
        this.pmsService.getFolios(self.data.incident.reservation.uuid, self.data.incident.reservation.payment_token, this.data.module.type).subscribe(
          (success: any) => {
            const folios = success.folios;
            this.paymentService.paymentSubj.next(folios);

            if (this.folio) {
              this.checkPayment(folios, self);
            }
          },
          () => {
            self.setFolioCheck(this.check);
          },
        );
      }
      self.enablePayButton();
      self.adyenButtonDisable(false);
    }, 1500);
  }

  private checkPayment(folios, self) {
    const folio = folios?.find((_folio) => _folio?.number === self.folio?.number);
    if (folio?.check === 'unpaid') {
      self.dropin?.setStatus('error');
      this.subscriptions.add(
        of(true)
          .pipe(delay(this.timeout))
          .subscribe(() => {
            self.dropin?.setStatus('ready');
          }),
      );
    } else {
      self.dropin?.setStatus('success');
      this.cicoService.closeOverlay(OverlayAction.close);
      this.cicoService.toggleInactivity(true);
    }
  }

  private enablePayButton() {
    this.cicoService.toggleInactivity(true);
    this.cicoService.disableNextButton(false);
    if (this.payButton) {
      this.payButton.disabled = false;
    }
  }

  private paymentError(error = null) {
    this.cicoService.closeOverlay(OverlayAction.close);
    this.loaded = false;
    if (error?.status == 400) {
      this.globals.alert('warning', this.globals.translate('service.payment.too_fast'));
    } else {
      this.globals.alert('error', this.ui_messages?.payment_error?.content);
    }
    this.enablePayButton();
    this.reloadFolios(error);
  }

  private reloadFolios(error) {
    if (error?.status === 417) {
      this.cicoService.folioUpdateSubj.next({ cus: false });
    }
  }

  private saveInOnlineCache(self): Observable<Object> {
    const cacheData = { locale: this.globals.guest.locale, ...self.data };
    delete cacheData.business?.storageService;
    delete cacheData.folioInfo?.loaded;
    delete cacheData.folioInfo?.longLoading;
    return self.pmsService.setOnlineCache(cacheData);
  }

  private getPaypalInformation() {
    if (this.payment.methods.paymentMethods.map((method) => method.type).includes('paypal')) {
      return {
        paypal: {
          configuration: {
            merchantId: this.payment.methods.paypal_id,
            intent: 'capture',
          },
          environment: this.payment.test_env ? 'test' : 'live',
          countryCode: this.data.incident.reservation.primary_guest?.address?.country,
          amount: {
            currency: this.data.business.currency,
            value: this.folio.payable_balance,
          },
          style: {
            color: 'blue',
            tagline: false,
          },
          onClickCancel: (_data, _dropin) => {
            this.dropin.setStatus('ready');
          },
        },
      };
    }
    return {};
  }

  private listenToClose() {
    this.cicoService.toggleInactivity(false);
    this.subscriptions.add(
      this.cicoService.overlayClose.subscribe(() => {
        this.enablePayButton();
      }),
    );
  }

  private static dataInformation(state, self) {
    return {
      data: state?.data,
      locale: self.globals.guest.locale,
      code: self.data.code,
      folios: self.data.incident.reservation.folios,
      pre_auth: self.preAuth,
      invoice_address: self.data.incident.reservation.address,
      guest: self.data.incident.reservation.primary_guest,
      resId: self.data.incident.reservation.reservation_id,
      folioId: self.folio?.number,
      origin: self.data.module.type,
      type: self.type(),
      pms_token: self.data.incident.reservation.payment_token,
    };
  }

  private setFolioCheck(state) {
    if (this.folio) {
      this.folio.check = state;
    }
  }

  private setScaValid(valid) {
    if (this.folio) {
      this.folio.sca_valid = valid;
    }
  }

  private type(): string {
    return this.folio ? 'payment' : 'pre_auth';
  }

  private frameDetection() {
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation: any) => {
        const frames = Array.from(mutation.target.getElementsByTagName('iframe')).filter((tag: any) => tag.src.startsWith(location.origin) && tag.src.replace(location.origin, '').length >= 15);
        if (frames.length) {
          (<any>frames[0]).parentNode.removeChild(frames[0]);
          this.checkTransaction(this);
        }
      });
    });
    this.observer.observe(document.querySelector('app-pms-payment'), {
      childList: true,
      subtree: true,
    });
  }

  ngOnDestroy(): void {
    this.cicoService.setFolioAddressStep('folio');
    this.observer.disconnect();
    this.subscriptions.unsubscribe();
  }
}
