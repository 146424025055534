import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SafePipeModule } from 'pipes/safe.pipe.module';
import { WidgetAdvertModule } from 'widgets/advert/advert.module';
import { BacklinkModule } from '../layout/backlink/backlink.module';
import { SharedSwiperModule } from '../layout/swiper/swiper.module';
import { ExternalLinkModule } from './external/external.module';
import { LinkRoutingModule } from './link-routing.module';
import { LinkComponent } from './link.component';

@NgModule({
  imports: [CommonModule, LinkRoutingModule, BacklinkModule, SafePipeModule, SharedSwiperModule, WidgetAdvertModule, TranslateModule, ExternalLinkModule],
  exports: [LinkComponent],
  declarations: [LinkComponent],
})
export class LinkModule {}
