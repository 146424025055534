import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

@Directive({
  selector: '[datevalidator][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: DateValidator,
      multi: true,
    },
  ],
})
export class DateValidator implements Validator, OnChanges {
  validator: ValidatorFn;
  value: any;

  private control: FormControl;
  @Input('datevalidator') options: any;

  constructor() {
    this.validator = this.dateValidator();
  }

  ngOnChanges(_changes: SimpleChanges) {
    this.control?.updateValueAndValidity();
  }

  validate(c: FormControl) {
    return this.validator(c);
  }

  dateValidator(): ValidatorFn {
    return (c: FormControl) => {
      this.control = c;
      this.value = this.control.value;
      const isValid = this.check();
      if ((!this.options.required && !this.value) || isValid) {
        return null;
      } else {
        return {
          datevalidator: { text: this.text() },
        };
      }
    };
  }

  check() {
    let valid: boolean;
    const mask = this.options.mask;
    const minDate = this.options.min ? this.options.min : null;
    const maxDate = this.options.max ? this.options.max : null;
    const selectedDate = moment(this.value, mask, true);

    if (selectedDate) {
      const isValidDate = selectedDate.isValid();
      const isYearValid = selectedDate.year() >= 1900;
      const isMinDateValid = !minDate || selectedDate.isSameOrAfter(minDate);
      const isMaxDateValid = !maxDate || selectedDate.isSameOrBefore(maxDate);

      valid = isValidDate && isYearValid && isMinDateValid && isMaxDateValid;
    }
    return valid;
  }

  text(): string {
    if (!this.value?.length && this.options.required) {
      return 'validation.required';
    } else if (['future', 'past'].includes(this.options.validation)) {
      return `validation.date_${this.options.validation === 'past' ? 'future' : 'past'}`;
    } else if (['adult', 'kids'].includes(this.options.validation)) {
      return `validation.${this.options.validation}_date_of_birth`;
    } else {
      return 'misc.invalid_date';
    }
  }
}
