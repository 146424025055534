import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { Globals } from 'base';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput') fileInput!: ElementRef;

  @Input() name: string;
  @Input() pdf = false;
  @Input() trigger$: Subject<any>;
  @Input() required: boolean;

  @Output() success = new EventEmitter<any>();

  MAX_SIZE = 15000000;
  VALID_TYPES = ['jpeg', 'jpg', 'png'];

  subscriptions: Subscription = new Subscription();
  inputId: string;
  image: any;
  loading: boolean;
  accept: string;

  constructor(
    private toastService: ToastrService,
    private globals: Globals,
  ) {}

  ngOnInit() {
    this.inputId = `${this.name}_${Math.random().toString(36).substring(2)}`;

    this.subscriptions.add(
      this.trigger$.subscribe(() => {
        this.triggerFileUpload();
      }),
    );

    if (this.pdf) {
      this.VALID_TYPES.push('pdf');
    }

    if (this.captureSupported()) {
      this.accept = this.VALID_TYPES.map((type) => '.' + type).join(',') + ',capture=camera';
    }
  }

  captureSupported(): boolean {
    const el = document.createElement('input');
    return el.capture !== undefined;
  }

  triggerFileUpload() {
    this.fileInput.nativeElement.click();
  }

  onFileChange(event) {
    this.loading = true;
    const imageInput = event.target;
    const reader = new FileReader();
    if (imageInput.files?.length) {
      const file = imageInput.files[0];
      if (this.checkFile(file)) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64 = String(reader.result);
          if (base64.startsWith('data:image') || 'data:application/pdf') {
            this.success.emit(base64);
            this.loading = false;
          }
        };
      } else {
        this.loading = false;
      }
    } else {
      this.loading = false;
    }
  }

  checkFile(file: any): boolean {
    let error = '';
    if (file.size > this.MAX_SIZE) {
      error = this.globals.translate('validation.file_size');
    }
    if (!this.VALID_TYPES.filter((filetype) => file.type.includes(filetype)).length) {
      error = this.globals.translate('validation.file_type');
    }
    const type = file.type.split('/')[0];
    const subtype = file.type.split('/')[1];
    const mime_type = type === 'image' || (this.pdf && subtype === 'pdf');
    if (!mime_type) {
      error = this.globals.translate('validation.mime_type');
    }
    if (error.length) {
      this.toastService.error(error);
      this.loading = false;
      return false;
    } else {
      return true;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
