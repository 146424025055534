<div class="clearfix slider group-slide" *ngIf="widget">
  <swiper [config]="slideConfig" (indexChange)="setIndex($event)" (click)="action(widget[slideIndex], $event)">
    <div *ngFor="let entry of widget; let i = index" class="swiper-slide">
      <div class="slider-content">
        <span class="name">{{ entry.city }}</span>
        <a class="inner" *ngIf="entry?.medias?.length" [ngStyle]="{ 'background-image': 'url(' + imageUrl(entry) + ')' }"></a>
        <div class="main-bg name">{{ entry.name }}</div>
      </div>
    </div>
  </swiper>
</div>
