<a [routerLink]="['/g', globals.getCode(), 'weather']" class="clearfix" *ngIf="weather">
  <div class="weatherblock" *ngFor="let weatherReport of weather | slice: 0 : 2; let i = index">
    <p>{{ weatherReport.date_short }}</p>
    <span class="weathericon"><img [src]="weatherReport.media.image" [alt]="weatherReport.description" /></span>
    <h2 *ngIf="showFahrenheit; else celsius">{{ weatherReport.temp_fahrenheit }} °F</h2>
    <ng-template #celsius
      ><h2>{{ weatherReport.temp }} °C</h2></ng-template
    >
  </div>
</a>
