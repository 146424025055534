import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetSocialModule } from 'widgets/social/social.module';
import { NewsletterFormModule } from '../newsletter/form/form.module';
import { ModuleTitleModule } from 'shared/layout/module_title.module';
import { ConfirmRoutingModule } from './confirm-routing.module';
import { ConfirmComponent } from './confirm.component';

@NgModule({
  imports: [CommonModule, ConfirmRoutingModule, NewsletterFormModule, WidgetSocialModule, ModuleTitleModule],
  exports: [ConfirmComponent],
  declarations: [ConfirmComponent],
})
export class ConfirmModule {}
