import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { InfoModule } from '../../info/info.module';
import { PmsInvoiceAddressFormModule } from '../../invoice_address/form/form.module';
import { PmsCreditCardModule } from '../../payment/credit_card/credit_card.module';
import { PmsPaymentModule } from '../../payment/payment/payment.module';
import { PmsTerminalModule } from '../../payment/terminal/terminal.module';
import { PmsSimpleFooterModule } from '../../simple-footer/simple-footer.module';
import { PmsFolioRoutingModule } from './folio-routing.module';
import { PmsFolioComponent } from './folio.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, PmsFolioRoutingModule, TranslateModule, PmsCreditCardModule, PmsPaymentModule, PmsTerminalModule, InfoModule, PmsInvoiceAddressFormModule, PmsSimpleFooterModule, FormsModule],
  exports: [PmsFolioComponent],
  declarations: [PmsFolioComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PmsFolioModule {}
