<app-module-title [module]="module"></app-module-title>

<div class="container">
  <div class="container-right" *ngIf="rightSide">
    <div class="article-price" *ngIf="orderable && has_price">
      <h3 *ngIf="price > 0">{{ total_price | currency: business.currency }}</h3>
      <p class="faded" *ngIf="price > 0">{{ 'entries.unit_price' | translate }}: {{ price | currency: business.currency }}</p>
      <h3 *ngIf="price <= 0">{{ 'misc.free' | translate }}</h3>
      <p class="faded" *ngIf="price <= 0">{{ 'entries.unit_price' | translate }}</p>
    </div>
    <app-swiper [items]="entryMedias?.length > 0 ? entryMedias : medias" *ngIf="entryMedias?.length > 0 || medias?.length > 0"></app-swiper>

    <app-widget-advert></app-widget-advert>
  </div>

  <div class="container-left">
    <form (ngSubmit)="onSubmit()" #serviceForm="ngForm">
      <fieldset [disabled]="disabled">
        <div class="alert" [innerHtml]="error_text" *ngIf="error_text"></div>

        <div class="form-box" *ngIf="select?.length">
          <app-form-select [model]="service" [name]="'selection'" [label]="'request.selection'" [errorLabel]="'validation.type'" [options]="select" [group]="'category'" [required]="true" (optionValue)="optionChanged($event)"></app-form-select>
        </div>

        <p class="m-t-16- m-b-16" [innerHtml]="description" *ngIf="description"></p>

        <ng-container *ngIf="orderable">
          <app-form-quantity [value]="1" [removable]="false" [disabled]="disabled" (quantity)="set_quantity($event)" *ngIf="quantitable"></app-form-quantity>

          <div class="form-box">
            <label translate>request.comment</label>
            <textarea #body="ngModel" id="body" name="body" [(ngModel)]="service.body" placeholder="{{ 'request.comment' | translate }}" [required]="required"></textarea>
            <label translate class="error">validation.comment_text</label>
          </div>

          <app-form-fields [form]="service" [module]="module" *ngIf="module?.fields?.length"></app-form-fields>

          <ng-container *ngIf="module">
            <app-form-place [service]="service" [room_validation]="module.room_validation" *ngIf="globals.needPlace()"></app-form-place>
          </ng-container>

          <div class="form-box">
            <label translate>misc.confirm_mail</label>
            <input type="email" name="email" #email="ngModel" [(ngModel)]="service.email" placeholder="{{ 'misc.mail' | translate }}" emailvalidator required />
            <label translate class="error">validation.email</label>
          </div>

          <div class="form-box" *ngIf="module?.field('phone')">
            <label [innerText]="module.field('phone').name"></label>
            <small [innerText]="module.field('phone').description" *ngIf="module.field('phone').description"></small>
            <app-form-phone-field [model]="service" [key]="'phone'" [field]="module.field('phone')"></app-form-phone-field>
          </div>

          <app-form-send-cancel [confirm]="confirm_name" [status]="globals.send_cancel_disabled"></app-form-send-cancel>
        </ng-container>
      </fieldset>
    </form>
  </div>
</div>
