<div class="popup-text">
  <div class="half">
    <img [src]="business.media.image" [alt]="business.name" *ngIf="business.media.image" />
  </div>
  <div class="half">
    <h4 translate>misc.address</h4>
    {{ business.name }}<br />
    {{ business.address.address }}<br />
    {{ business.address.zipcode }} {{ business.address.city }}<br />
    {{ business.address.county }}

    <div class="contact">
      <h4 translate>poi.contact_details</h4>
      <ul class="icon-list">
        <li *ngIf="business.contact.email">
          <i class="far fa-envelope"></i>
          <a href="mailto:{{ business.contact.email }}">{{ business.contact.email }}</a>
        </li>
        <li *ngIf="business.contact.phone">
          <i class="fas fa-phone"></i>
          <a target="_blank" href="tel:{{ business.contact.phone }}">{{ business.contact.phone }}</a>
        </li>
        <li *ngIf="business.contact.url">
          <i class="fas fa-home"></i>
          <a class="ext-link" translate (click)="openUrl(business.contact.url)">misc.open_website</a>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="button-grid">
  <a translate class="button main-bg ext-link" (click)="globals.openUrl(business.contact.booking_url)" *ngIf="business.contact.booking_url">widget.book_direct</a>
  <a translate class="button light close" (click)="close()">misc.close</a>
</div>
