import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { EmailValidatorModule } from 'validators/email.validator.module';
import { SendCancelModule } from 'shared/forms/send_cancel/send_cancel.module';
import { NewsletterFormRoutingModule } from './form-routing.module';
import { NewsletterFormComponent } from './form.component';

@NgModule({
  imports: [CommonModule, NewsletterFormRoutingModule, FormsModule, TranslateModule, EmailValidatorModule, SendCancelModule],
  exports: [NewsletterFormComponent],
  declarations: [NewsletterFormComponent],
})
export class NewsletterFormModule {}
