<div class="popup-text">
  <div class="half">
    <app-swiper [items]="entry.medias" [lightbox]="false" *ngIf="entry?.medias?.length"></app-swiper>
  </div>
  <div class="half">
    <h4 [innerHtml]="entry.event_category.name" *ngIf="entry.event_category"></h4>
    <b translate>misc.from</b>: {{ entry.appointment.date_from }} <span *ngIf="entry.appointment.time_from">- {{ entry.appointment.time_from }}</span
    ><br />
    <b translate>misc.till</b>: {{ entry.appointment.date_till }} <span *ngIf="entry.appointment.time_till">- {{ entry.appointment.time_till }}</span
    ><br />

    <h4 translate>misc.address</h4>
    <ng-container *ngIf="entry.address.company?.length"> <span [innerHtml]="entry.address.company"></span><br /> </ng-container>
    {{ entry.address.address }}<br />
    {{ entry.address.zipcode }} {{ entry.address.city }}<br />
    {{ entry.address.county }}
  </div>
</div>
<div class="button-grid">
  <a translate class="button main-bg" [routerLink]="['/g', globals.getCode(), 'event', entry.id]" (click)="close()">misc.more</a>
  <a translate class="button light close" (click)="close()">misc.close</a>
</div>
