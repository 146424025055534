import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderModule } from 'common/loader/loader.module';
import { SafePipeModule } from 'pipes/safe.pipe.module';
import { ImageRoutingModule } from './image-routing.module';
import { ImageComponent } from './image.component';

@NgModule({
  imports: [CommonModule, ImageRoutingModule, TranslateModule, FormsModule, LoaderModule, SafePipeModule],
  exports: [ImageComponent],
  declarations: [ImageComponent],
})
export class ImageModule {}
