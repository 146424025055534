<ng-container *ngIf="data?.incident?.reservation?.primary_guest && formFields.primaryGuest">
  <form #form="ngForm" class="redesign">
    <div class="content" *ngIf="cicoService.activeStep.key === 'guests'">
      <ng-container *ngFor="let guest of guests; let i = index">
        <div class="child-heading" *ngIf="guest.group == 'children_fellows'">
          <span>{{ 'service.check_in.fellows.children_fellows' | translate: { number: i + 1 } }}</span>
        </div>
        <app-pms-guest [guest]="guest" [kind]="guest.group" [index]="i" [field]="fields[i]" [locale]="locale" [travelPurposeField]="travelPurposeField"> </app-pms-guest>
      </ng-container>
    </div>
  </form>
</ng-container>
