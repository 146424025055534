import { r3JitTypeSourceSpan } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
export class StorageService {
  ls: any;
  classList = document.querySelector('html')['className'];
  supportLS = this.classList.indexOf('can_localstorage') !== -1;
  supportCookie = this.classList.indexOf('can_cookies') !== -1;

  constructor(private cookieService: CookieService) {
    if (this.supportLS && 'SecureLS' in window) {
      try {
        this.ls = new (<any>window).SecureLS({ encodingType: 'aes', isCompression: true, encryptionSecret: 'order2code' });
      } catch (e) {}
    }
  }

  hasItem(key: string, code: string): Promise<any> {
    return new Promise<any>((resolve, _reject) => {
      this.getItem(key, code)
        .then((_data: any) => {
          resolve(true);
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  async setItem(key: string, value: any) {
    try {
      value.updated = Date.now();
    } catch (_e) {}
    if (this.supportLS && this.ls) {
      const keys = [];
      if (value?.code) {
        setTimeout(() => {
          try {
            if (this.ls.get(key)) {
              const ls_keys = JSON.parse(this.ls.get(key));
              if (Array.isArray(ls_keys)) {
                const index = ls_keys.findIndex((item) => item?.code === value.code);
                if (index !== -1) {
                  ls_keys[index] = value;
                } else {
                  ls_keys.push(value);
                }
                this.ls.set(key, JSON.stringify(ls_keys));
              } else {
                this.ls.remove(key);
                this.setItem(key, value);
              }
            } else {
              keys.push(value);
              this.ls.set(key, JSON.stringify(keys));
            }
          } catch (e) {
            if (e.name === 'QuotaExceededError' || e.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
              this.setCookie(key, value);
            } else {
              this.clear();
            }
          }
        }, 0);
      }
    } else {
      this.setCookie(key, value);
    }
  }

  getItem(key: string, code: string): Promise<any> {
    if (this.supportLS && this.ls) {
      return new Promise<any>((resolve, reject) => {
        try {
          if (this.ls.get(key)) {
            const ls_keys = JSON.parse(this.ls.get(key));
            if (Array.isArray(ls_keys) && ls_keys.length > 0) {
              for (const _key of ls_keys) {
                if (_key && _key.code === code) {
                  resolve(_key);
                }
              }
              reject();
            } else {
              reject();
            }
          } else {
            this.getCookie(key, code)
              .then((item) => {
                resolve(item);
              })
              .catch(() => {
                reject();
              });
          }
        } catch (e) {
          this.clearByKey(key, code);
          reject();
        }
      });
    } else {
      return this.getCookie(key, code);
    }
  }

  async keepOnlyNewest(keyPrefix: string, maxItems: number, code: string) {
    const keys = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key?.startsWith(keyPrefix)) {
        setTimeout(() => {
          this.getItem(key, code).then(
            (item) => {
              keys.push({ key: key, updated_at: item.updated });
              if (keys.length > maxItems) {
                keys.sort((a, b) => b.updated_at - a.updated_at);
                const keysToDelete = keys.splice(maxItems);
                keysToDelete.forEach((removal) => {
                  this.ls.remove(removal.key);
                });
              }
            },
            (_err) => {
              this.ls.remove(key);
            },
          );
        });
      }
    }
  }

  getTokens(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      try {
        if (this.supportLS && this.ls) {
          if (this.ls.get('current_guest')) {
            const guests = JSON.parse(this.ls.get('current_guest'));
            resolve(this.sortedGuests(guests).map((guest) => guest.token));
          } else {
            resolve([]);
          }
        } else if (this.supportCookie) {
          const tokens = [];
          const keys = Object.keys(this.cookieService.getAll()).filter((k) => {
            return k.indexOf('current_guest') > -1;
          });

          if (keys && keys.length > 0) {
            keys.forEach((key) => {
              try {
                tokens.push(JSON.parse(atob(this.cookieService.get(key))).token);
              } catch (_e) {
                tokens.push(JSON.parse(this.cookieService.get(key)).token);
              }
            });
            resolve(tokens);
          } else {
            resolve([]);
          }
        } else {
          resolve([]);
        }
      } catch (e) {
        resolve([]);
      }
    });
  }

  clearByCode(code: string): void {
    if (this.supportLS) {
      this.removeItem('current_guest', code);
      this.removeItem('current_business', code);
    } else {
      this.removeCookie('current_guest', code);
      this.removeCookie('current_business', code);
    }
  }

  clearByKey(key: string, code: string): void {
    if (this.supportLS) {
      this.removeItem(key, code);
    } else {
      this.removeCookie(key, code);
    }
  }

  /**
   * @param key localStorage key
   * @param code **Business code --> required because of cookie fallback**
   */
  removeKey(key: string, code: string): void {
    if (code) {
      this.supportLS ? this.ls.remove(key) : this.removeCookie(key, code);
    }
  }

  private removeItem(key: string, code: string): void {
    try {
      if (this.ls.get(key)) {
        const ls_keys = JSON.parse(this.ls.get(key));

        if (Array.isArray(ls_keys)) {
          ls_keys.forEach((val, i) => {
            if (ls_keys[i] && ls_keys[i].code === code) {
              ls_keys.splice(i, 1);
            }
          });
          this.ls.set(key, JSON.stringify(ls_keys));
        } else {
          this.ls.remove(key);
        }
      }
    } catch (_e) {}
  }

  private clear(): void {
    if (this.ls) {
      this.ls.remove('current_guest');
      this.ls.remove('current_business');
    }
  }

  private setCookie(key: string, value: object) {
    if (this.supportCookie) {
      const name = key + '_' + (<any>value).code;
      const date = new Date();
      date.setTime(date.getTime() + 3600 * 1000);

      if (value !== null && (<any>value).code) {
        try {
          const bValue = btoa(JSON.stringify(value));
          if (bValue.length <= 3900) {
            document.cookie = name + '=' + bValue + ';expires=' + date + ';path=/';
          } else {
            document.cookie = name + '=' + JSON.stringify(value) + ';expires=' + date + ';path=/';
          }
        } catch (e) {
          document.cookie = name + '=' + JSON.stringify(value) + ';expires=' + date + ';path=/';
        }
      }
    }
  }

  private getCookie(key: string, code: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.supportCookie) {
        const name = key + '_' + code;
        if (this.cookieService.check(name)) {
          try {
            resolve(JSON.parse(atob(this.cookieService.get(name))));
          } catch (e) {
            resolve(JSON.parse(this.cookieService.get(name)));
          }
        } else {
          reject();
        }
      }
      reject();
    });
  }

  private removeCookie(key: string, code: string): void {
    if (this.supportCookie) {
      const name = key + '_' + code;
      if (this.cookieService.check(name)) {
        this.cookieService.delete(name);
      }
    }
  }

  private sortedGuests(array): any {
    return array.sort((a, b) => a.updated - b.updated);
  }
}
