import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { EmailValidatorModule } from 'validators/email.validator.module';
import { WidgetAdvertModule } from 'widgets/advert/advert.module';
import { DatesModule } from 'shared/forms/dates/dates.module';
import { FormFieldsModule } from 'shared/forms/fields/fields.module';
import { FormPhoneFieldModule } from 'shared/forms/phone/phone_field.module';
import { FormPlaceModule } from 'shared/forms/place/place.module';
import { FormSelectModule } from 'shared/forms/select/select.module';
import { SendCancelModule } from 'shared/forms/send_cancel/send_cancel.module';
import { ModuleTitleModule } from 'shared/layout/module_title.module';
import { SharedSwiperModule } from 'shared/layout/swiper/swiper.module';
import { HousekeepingRoutingModule } from './housekeeping-routing.module';
import { HousekeepingComponent } from './housekeeping.component';

@NgModule({
  imports: [CommonModule, HousekeepingRoutingModule, FormsModule, WidgetAdvertModule, SharedSwiperModule, ModuleTitleModule, DatesModule, FormSelectModule, FormFieldsModule, FormPlaceModule, FormPhoneFieldModule, TranslateModule, EmailValidatorModule, SendCancelModule],
  exports: [HousekeepingComponent],
  declarations: [HousekeepingComponent],
})
export class HousekeepingModule {}
