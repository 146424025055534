import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SharedSwiperModule } from 'modules/shared/layout/swiper/swiper.module';
import { WidgetAdvertModule } from 'widgets/advert/advert.module';
import { LocationRoutingModule } from './location-routing.module';
import { LocationComponent } from './location.component';

@NgModule({
  imports: [CommonModule, LocationRoutingModule, SharedSwiperModule, WidgetAdvertModule, TranslateModule],
  exports: [LocationComponent],
  declarations: [LocationComponent],
})
export class LocationModule {}
