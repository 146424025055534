import { Directive, HostListener, Input, KeyValueDiffers, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Globals } from 'base';
import { PmsCiCoService } from 'cico_service';
import { ModalService } from 'common/modal/modal.service';
import { DomModal } from 'models/dom_modal';
import { Field } from 'models/field';
import { FieldValue } from 'models/field_value';
import { Guest } from 'models/guest';
import { PmsPaymentService } from 'payment_service';
import { ConfirmName, FormFieldKeys, UserActionType, OverlayAction, OverlayType, PmsModType, PmsProcess, Step } from 'pms_enums';
import { GenericData } from 'pms_models/generic_data';
import { GenericOverlay } from 'pms_models/generic_overlay';
import { interval, Subscription } from 'rxjs';
import { filter, skipWhile, take } from 'rxjs/operators';
import { GuestService } from 'services/guest.service';
import { OfflineService } from 'services/offline.service';
import { StorageService } from 'services/storage.service';
import { WebsocketService } from 'services/websocket/websocket.service';
import { StartComponent } from '../shared/start/start.component';
import { PmsVersionComponent } from '../shared/version/version.component';
import { PmsBaseDirective } from './base.directive';
import { GuestSubSteps, StepperActions, TravelInfoSubSteps } from 'models/pms/stepper';
import { EventAggregatorService } from 'services/events/event-aggregator.service';
import { EventConstants } from 'global_enums';
import { StepperService } from 'services/pms/stepper.service';
import { PmsGuest } from 'models/pms/pms_guest';

@Directive()
export class PmsCiCoBaseDirective implements OnInit, OnDestroy {
  @ViewChild('guests', { static: false }) guests: PmsBaseDirective;
  @ViewChild('reservation', { static: false }) reservation: PmsBaseDirective;
  @ViewChild('invoice', { static: false }) folios: PmsBaseDirective;
  @ViewChild('confirm', { static: false }) confirm: PmsBaseDirective;

  protected subscriptions: Subscription = new Subscription();
  public suppressGuard: boolean;
  public showOverlay: boolean;
  public olContent: GenericOverlay;

  public current: number;
  public data: GenericData;
  public confirmName: string;
  public ciCoType = PmsModType;

  public loginRequired: boolean;
  public loaded: boolean;

  payment: boolean;
  paymentError: boolean;

  uuid: string;

  @Input() res: any;

  step: string;
  formSubmited: boolean;
  idnow: boolean;

  private static hasModalBoxes() {
    return document.getElementsByClassName('modal-box').length || document.querySelector('.component-overlay:not(.with_back)') || document.querySelector('.generic-overlay:not(.with_back)');
  }

  private static isPayingFolios() {
    return document.getElementsByTagName('app-pms-payment')?.length || document.getElementsByTagName('app-pms-terminal')?.length;
  }

  private static hasScanner() {
    return !document.getElementsByTagName('app-pms-terminal-door').length && document.getElementsByTagName('app-scanner').length;
  }

  private static isStart() {
    return document.getElementById('welcome');
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander(event: BeforeUnloadEvent) {
    if (this.cicoService.logUnload) {
      this.cicoService.localSaveProcess(this.data);
      this.cicoService.closeLog('page closed');
      event.returnValue = true;
    }
  }

  constructor(
    public cicoService: PmsCiCoService,
    protected guestService: GuestService,
    protected differs: KeyValueDiffers,
    protected storageService: StorageService,
    public globals: Globals,
    protected modalService?: ModalService,
    protected paymentService?: PmsPaymentService,
    protected route?: ActivatedRoute,
    protected offlineService?: OfflineService,
    protected wsService?: WebsocketService,
    protected readonly eventService?: EventAggregatorService,
    protected stepperService?: StepperService,
  ) {
    this.subscriptions.add(
      this.cicoService.data.pipe(filter(Boolean), take(1)).subscribe((data: GenericData) => {
        this.data = data;
        this.uuid = data.incident.reservation.uuid;

        if (!this.cicoService.autoSkipUntilStep) {
          if (data.module.type === PmsModType.ci) {
            if (data.incident.reservation.skipable) {
              this.showSkipCiDialog();
            } else {
              this.initScreen();
            }
          } else if (data.module.type === PmsModType.co) {
            this.initScreen();
            // Generate the header based on the current step and type of process (Checkin, Checkout).
            this.cicoService.generateSectionHeader(this.idnow, PmsModType.co);
          }
        }

        if (data.module.type === PmsModType.ci) {
          if (data.blank) {
            this.cicoService.nationalityChoseWithBackBtn = true;
          } else {
            const adults = data.module.field('fellows')?.subField(FormFieldKeys.adultFellows);
            const children = data.module.field('fellows')?.subField(FormFieldKeys.childrenFellows);
            // Calculate the total count of guests based on the reservation data
            let totalGuestSubSteps = 0;
            if (adults) {
              totalGuestSubSteps += data.incident.reservation.related_guests.length;
            }
            // Add 1 if there are children guests, otherwise add 0
            if (children && data.incident.reservation.children_guests.length > 0) {
              totalGuestSubSteps += 1;
            }
            // Update the total steps for guest processing in the cicoService
            this.cicoService.updateTotalSteps(Step.guests, totalGuestSubSteps + 1);

            const customFields = data.module.usableFields(false);
            // Update the total steps for reservation
            if (customFields.length > 0) {
              this.cicoService.addSubstep(Step.reservation, TravelInfoSubSteps.customFields);
            }
          }

          this.cicoService.setPassportVisa();
          this.checkIdnow();

          // Generate the header based on the current step and type of process (Checkin, Checkout).
          this.cicoService.generateSectionHeader(this.idnow, PmsModType.ci);
        }
      }),
    );
  }

  ngOnInit(module: PmsModType = null): void {
    this.formSubmited = false;
    this.clearData();
    this.subSuppressGuard();
    this.cicoService.addSubscriptions();
    this.toggleHeaderFooter();
    this.globals.taskSubj.next(true);
    this.cicoService.encoderData = undefined;
    this.cicoService.sendSubj.next(false);

    /**
     * Handles navigation changes for a multi-step process in a subscription service.
     * This method deals specifically with form interactions based on the current active step.
     */
    this.subscriptions.add(
      this.eventService?.getEvent(EventConstants.navigationChange).subscribe(() => {
        this.handleStepperNavigation();
      }),
    );

    this.subscriptions.add(
      this.cicoService.navigation.subscribe((type) => {
        this.handleUserCloseTheForm(type);
      }),
    );

    this.subscriptions.add(
      this.cicoService.loaded.subscribe((loaded: boolean) => {
        this.loaded = loaded;
      }),
    );

    this.subscriptions.add(
      this.guestService.currentGuest.pipe(filter(Boolean)).subscribe((guest: Guest) => {
        this.uuid = this.uuid || this.res?.uuid || guest.place.reservation_uuid;
        this.guestService.loginRequiredSubj.next(guest.place.cico_login);
      }),
    );

    this.subscriptions.add(
      this.guestService.loginRequired.subscribe(
        (loginRequired: boolean) => {
          this.loginRequired = loginRequired;
        },
        () => {},
      ),
    );

    if (module) {
      this.globals.setModule(module);
      this.globals.versionCheck(module).subscribe(
        () => {
          this.start(module);
        },
        (error) => {
          this.cicoService.suppressGuardSubj.next(true);
          const body = new DomModal();
          body.title = this.globals.translate('misc.notice');
          body.params = { status: error.status };
          body.update = true;
          body.redirect = false;
          body.closeable = false;
          body.component = PmsVersionComponent;
          interval(15)
            .pipe(
              skipWhile(() => !this.modalService),
              take(1),
            )
            .subscribe(() => {
              this.modalService.open(body, true, true);
            });
        },
      );
    }
  }

  /** Handles the navigation change event. */
  private handleStepperNavigation(): void {
    if (!this.cicoService.activeStep) {
      return;
    }
    // reset formSubmitted for the next step
    this.formSubmited = false;
    this.cicoService.toggleInactivity(true);
    this.globals.clearAlert();

    switch (this.cicoService.activeStep.action) {
      case StepperActions.next:
        this.handleNextAction();
        break;
      case StepperActions.previous:
        this.handlePreviousAction();
        window.scrollTo(0, 0);
        break;
      default:
        break;
    }

    // Generate the header based on the current step.
    this.cicoService.generateSectionHeader(false, this.data.module.type);

    // Record the user's actions.
    if (!this.cicoService.autoSkipUntilStep) {
      this.cicoService.setRecording();
    }
  }

  /** Handles the 'Next' action in the stepper. */
  private handleNextAction(): void {
    if (this.cicoService.preventNext || this.cicoService.disableNext) {
      if (this.cicoService.activeStep.key === Step.invoice) {
        this.eventService.getEvent(EventConstants.folioProcessInitiated).publish(undefined);
        return;
      }
      return;
    }

    if (this.cicoService.activeStep.key === Step.invoice) {
      this.completeStepperProcess();
      this.eventService.getEvent(EventConstants.toCheckThePaidFolios).publish(undefined);
      return;
    }

    const form = this.getActiveForm();
    if (!form) {
      return;
    }

    this.formValidation(form);
    // check form's validation in the last step of CI/CO in order to submit the form.
    this.confirmStepValid(form);
  }

  /** Get the current form for validation process. */
  private formValidation(form: any): void {
    this.globals.markAllControlsTouched(form);
    this.processFormValidity(form);
  }

  /** Returns the active form based on the current step. */
  private getActiveForm(): any {
    return this[this.cicoService.activeStep.key]?.form;
  }

  /** Processes the validity of the form based on the current step. */
  private processFormValidity(form: any): void {
    if (form.valid || form.disabled) {
      this.cicoService.localSaveProcess(this.data);

      // Sub-step handling for primary guest.
      const isSubsetps = this.isSubStepPrimaryGuestNext(this.data);
      this.updateBackButtonVisibility();

      if (!isSubsetps) {
        // If the form is valid and not the last step (confirm), continue the stepper process.
        if (this.cicoService.activeStep.key !== Step.confirm) {
          this.completeStepperProcess();
        }

        if (this.cicoService.activeStep.key === Step.guests) {
          this.processToAdjacentPage();
        }
      } else if (this.cicoService.autoSkipUntilStep) {
        // Skip to the next step if the autoSkipUntilStep is set. This is for the primary guest sub-steps.
        this.stepperService.nextStep();
      }
      window.scrollTo(0, 0);
    } else {
      this.handleFormErrors();
    }
  }

  /** Completes or continues the stepper process when it successfull*/
  private completeStepperProcess(): void {
    // Continue or complete the stepper. This method updates the user steps array.
    this.stepperService.continueOrCompleteStepper();
  }

  /** Handles the 'Previous' action in the stepper. */
  private handlePreviousAction(): void {
    this.removeCusSelected();
    const isSubsetps = this.isSubStepPrimaryGuestPrevious(this.data);
    this.updateBackButtonVisibility();

    if (isSubsetps) {
      const isRelevantSubStep = this.cicoService.subStepForGuest === GuestSubSteps.identificationGuest || this.cicoService.subStepForGuest === GuestSubSteps.privateAddress;
      if (isRelevantSubStep) {
        // Process navigation to the adjacent page if the current sub-step is relevant.
        this.processToAdjacentPage();
      }
      return;
    }

    this.cicoService.preventNext = false;
    this.cicoService.setAutoSkipUntilStep(undefined);

    if (this.cicoService.activeStep.key == Step.guests) {
      this.processToAdjacentPage();
    }
  }

  /** Removes the customer selected CUS when the user clicks back. */
  private removeCusSelected() {
    if (this.cicoService.getCurrentStepName() === TravelInfoSubSteps.reservation && !this.cicoService.cusRoomupgradeToBook) {
      this.cicoService.confirmNameSubj.next(ConfirmName.next);
    }
    if (this.cicoService.getCurrentStepName() === TravelInfoSubSteps.cusBreakfast && !this.cicoService.cusBreakfastToBook) {
      this.cicoService.confirmNameSubj.next(ConfirmName.next);
    }
  }

  /** Updates the visibility of the back button based on the current step. */
  private updateBackButtonVisibility(): void {
    if (!this.data.incident.reservation.idnow()) {
      this.cicoService.hideBackButton(this.cicoService.subStepForGuest === GuestSubSteps.primaryGuest);
    }
  }

  /**
   * Verify if the form in the last step of CICO is valid or not.
   * Trigger blur events in order to display error msgs for invalid controls.
   */
  private confirmStepValid(form: any): void {
    if (this.cicoService.activeStep.key !== Step.confirm) {
      return;
    }
    // if the final step of CICO (confirm) has a valid form => allow to submit.
    if (!this.formSubmited) {
      this.eventService.getEvent(EventConstants.checkInOutAction).publish(undefined);
      return;
    }
    // if the form in final step of CICO has an invalid form => restrict submitting, trigger error msgs
    this.globals.triggerBlurEvents(form);
  }

  /** Scrolls to the first error in the form and resets the step handling upon validation failure. */
  private handleFormErrors(): void {
    this.scrollToError();
    this.cicoService.activeStep.action = StepperActions.none;
    this.formSubmited = true; // TODO: This is for validation only in reservation page. Need to be change in future.
    this.eventService.getEvent(EventConstants.afterSubmitForm).publish(undefined);
    this.cicoService.setAutoSkipUntilStep(undefined);
  }

  /** Handles processing of a valid form submission, including moving to the next page and scrolling to the top. */
  private processToAdjacentPage(): void {
    this.eventService.getEvent(EventConstants.moveToNextOrPreviousPage).publish(undefined);
  }

  private handleUserCloseTheForm(userActionType: UserActionType) {
    switch (userActionType) {
      case UserActionType.back:
        this.handleUserClickOnBack();
        break;
      case UserActionType.cancel:
        this.handlUserCancel();
        break;
    }
  }

  private handleUserClickOnBack() {
    if (this.cicoService.activeStep?.currentStep > 0) {
      if (!this.cicoService.disableBack) {
        this.stepperService.previousStep();
      }
      this.cicoService.preventNext = false;
      this.cicoService.hideNextButton(false);
      this.cicoService.disableNextButton(false);
    } else {
      if (this.cicoService.cardEncoded) {
        this.endProcess();
      }
    }
  }

  private handlUserCancel() {
    if (this.cicoService.activeStep?.currentStep > 0 && !this.cicoService.infoScreen && [PmsProcess.check_in, PmsProcess.check_out].includes(this.cicoService.getProcess())) {
      this.cicoService.openOverlay(OverlayType.cancel);
    } else {
      if ((!this.cicoService.encoderData?.can_encode || this.cicoService.cardEncoded) && (this.cicoService.confirmed || this.cicoService.infoScreen)) {
        this.endProcess();
      } else {
        const noCard = this.cicoService.encoderData?.can_encode && !this.cicoService.cardEncoded && this.globals.place.wizard;
        const overlay = noCard ? OverlayType.noCard : OverlayType.cancel;
        if (![PmsProcess.reservation].includes(this.cicoService.getProcess())) {
          this.cicoService.openOverlay(overlay);
        }
      }
    }
  }

  /**
   * Determines if the primary guest can proceed to the next sub-step based on their current information.
   *
   * @param {GenericData} data - The data containing information about the guest.
   * @returns {boolean} True if the sub-step should advance, false otherwise.
   */
  private isSubStepPrimaryGuestNext(data: GenericData): boolean {
    // Exit early if not in the guests step.
    if (this.cicoService.activeStep.key !== Step.guests) return false;

    // Check if the primary guest has identification documents and a private address.
    const isIdentification = this.checkIdentificationDocuments(data.incident.reservation.primary_guest);
    // Check if the primary guest has a private address.
    const isPrivateAddress = this.checkPrivateAddress(data.module.fields);

    switch (this.cicoService.subStepForGuest) {
      case GuestSubSteps.primaryGuest:
        if (isPrivateAddress) {
          this.cicoService.subStepForGuest = GuestSubSteps.privateAddress;
          return true;
        }
        if (isIdentification) {
          this.cicoService.subStepForGuest = GuestSubSteps.identificationGuest;
          return true;
        }
        break;
      case GuestSubSteps.privateAddress:
        if (isIdentification) {
          this.cicoService.subStepForGuest = GuestSubSteps.identificationGuest;
          return true;
        }
        break;
    }
    // For any other state, move to the next logical step without specific checks.
    this.cicoService.subStepForGuest = GuestSubSteps.otherGuest;
    // If none of the conditions are met, do not advance the sub-step.
    return false;
  }

  /**
   * Determines if the primary guest has a private address, identification documents, and if the user clicked back.
   * It updates the sub-step state based on the current state and the data provided.
   *
   * @param {GenericData} data - The data containing information about the guest and their documents.
   * @returns {boolean} - True if the sub-step should move to the previous step, false otherwise.
   */
  private isSubStepPrimaryGuestPrevious(data: GenericData): boolean {
    // Early return if not in the guests step or not at the first sub-step.
    if (this.cicoService.activeStep.key !== Step.guests || this.cicoService.activeStep.currentStep !== 1) {
      return false;
    }

    // Check if the primary guest has identification documents and a private address.
    const isIdentification = this.checkIdentificationDocuments(data.incident.reservation.primary_guest);
    // Check if the primary guest has a private address.
    const isPrivateAddress = this.checkPrivateAddress(data.module.fields);

    switch (this.cicoService.subStepForGuest) {
      case GuestSubSteps.otherGuest:
        // Check for identification documents first, then for private address.
        if (isIdentification) {
          this.cicoService.subStepForGuest = GuestSubSteps.identificationGuest;
          return true;
        } else if (isPrivateAddress) {
          this.cicoService.subStepForGuest = GuestSubSteps.privateAddress;
          return true;
        }
        // If neither condition is met, move to the primary guest state.
        // No need to return true because other guest and primary guest are the same.So reinitialize is required
        this.cicoService.subStepForGuest = GuestSubSteps.primaryGuest;
        break;

      case GuestSubSteps.identificationGuest:
        // Move to private address if present, otherwise to primary guest.
        this.cicoService.subStepForGuest = isPrivateAddress ? GuestSubSteps.privateAddress : GuestSubSteps.primaryGuest;
        return true;

      case GuestSubSteps.privateAddress:
        // Move from private address to primary guest.
        this.cicoService.subStepForGuest = GuestSubSteps.primaryGuest;
        return true;
    }

    // Default return false if no conditions above result in a state change that requires moving to a previous step.
    return false;
  }

  /** This method is used to determine if the primary guest has identification documents */
  private checkIdentificationDocuments(primaryGuest: PmsGuest): boolean {
    return primaryGuest.passport_image || primaryGuest.passport_data || primaryGuest.visa_data;
  }

  /** This method is used to determine if the primary guest has private address */
  private checkPrivateAddress(fields: Field[]): boolean {
    const hasTravelPurpose = fields.some((field) => field.identifier === 'travel_purpose');
    const hasCarLicense = fields.some((field) => field.identifier === 'car_license');
    const hasPrimaryGuestAddress = fields.some((field) => field.identifier === 'primary_guest' && field.fields.some((subField) => subField.identifier === 'private_address'));

    return hasTravelPurpose || hasCarLicense || hasPrimaryGuestAddress;
  }

  start(module: PmsModType): void {
    window.scrollTo(0, 0);
    this.globals.taskSubj.next(true);
    if (this.uuid || !this.globals.business?.usePms()) {
      this.loadProcess(module);
    } else if (this.loginRequired) {
      this.subscriptions.add(
        this.guestService.loginRequired
          .pipe(
            filter((loginRequired) => loginRequired === false),
            take(1),
          )
          .subscribe(
            () => {
              this.subscriptions.add(
                this.guestService.currentGuest.pipe(filter(Boolean), take(1)).subscribe((guest: Guest) => {
                  this.uuid = guest.place.reservation_uuid;
                  this.loadProcess(module);
                }),
              );
            },
            () => {},
          ),
      );
    }
  }

  showSkipCiDialog() {
    this.cicoService.loadedSubj.next(true);
    this.cicoService.openOverlay(OverlayType.skipCi, 'service.check_in.overlay.skipCiTitle', 'service.check_in.overlay.skipCiDescription', false);
  }

  initScreen() {
    if (this.data.module.settings.show_description && !this.data.incident.reservation.skipable) {
      this.cicoService.openComponentOverlay(StartComponent, { data: this.data }, 'start', true);
    }
  }

  loadProcess(module) {
    if (module) {
      // only pms_check_in // pms_check_out ?
      this.cicoService.suppressGuardSubj.next(true);
      this.route?.queryParams?.pipe(take(1)).subscribe((query: any) => {
        let uuid = this.uuid;
        let action = '';
        if (query.params) {
          const params = JSON.parse(atob(query.params));
          if (params['uuid'].length) {
            this.paymentError = params['state'] === false;
            uuid = params['uuid'];
          }
          action = params['action'];
        }
        if (action === 'payment') {
          // This is for when payment redirection, we need to jump to the invoice step
          this.cicoService.setAutoSkipUntilStep(Step.invoice);
        }
        this.subStepps(action);
        this.cicoService.fetchData(uuid, module);
      });
    }
    this.subOverlay();
    this.subConfirmName();
    this.checkTaskModule();
    if (this.globals.mobileKiosk()) {
      this.kioskAlive();
    }
  }

  deleteLocalState(data: GenericData) {
    this.storageService.removeKey(data.incident.getStorageKey(), data.business.code);
  }

  subStepps(action) {
    this.subscriptions.add(
      this.cicoService.data.pipe(filter(Boolean), take(1)).subscribe((data: GenericData) => {
        this.data = data;
        this.loaded = true;

        this.setFields();
        this.loadPayment();

        if (this.paymentError) {
          this.globals.alert('error', this.cicoService.ui_messages()?.payment_error?.content);
        }

        if (this.globals.kiosk()) {
          this.wsService?.sendMessage('message', { topic: 'reservation', reservation: this.data.incident.reservation.reservation_id });
        }
      }),
    );
  }

  loadPayment() {
    if (!this.payment) {
      this.setPayment();

      if (this.data.incident.reservation.payment_providers) {
        this.paymentService.loadAssets(this.data.incident.reservation.payment_providers);
      }
    }
  }

  setPayment() {
    this.payment = (this.cicoService.should_payment && this.data.module?.settings?.payment) || false;
    this.data.payment = this.payment;
  }

  setFields() {
    const fields = (<any>this.allFields(this.data?.module?.fields)).flat(3).filter((field) => field.group !== 'default');
    if (fields.length !== this.data.incident.field_values.length) {
      fields.forEach((field) => {
        if (!this.data.incident?.field_values?.find((ifield) => ifield.id === field.id)) {
          const filledField = this.data.incident.reservation.field_values.find((ifield) => ifield.id === field.id);
          this.data.incident.field_values.push(new FieldValue(filledField || field));
        }
      });
    }
  }

  allFields(fields): Field[] {
    const list = [];
    list.push(fields.filter((field) => field.fields.length === 0));
    if (fields.find((field) => field.fields?.length)) {
      list.push(
        <any>this.allFields(
          fields
            .filter((field) => field.fields?.length)
            .map((field) => field.fields)
            .flat(),
        ),
      );
    }
    return list;
  }

  subOverlay() {
    this.subscriptions.add(
      this.cicoService.overlay.subscribe((content) => {
        this.olContent = content;
        this.showOverlay = true;
      }),
    );

    this.subscriptions.add(
      this.cicoService.overlayClose.subscribe((close: any) => {
        if (![OverlayAction.start].includes(close.action)) {
          this.cicoService.setShowFooter(true);
        }
        switch (close.action) {
          case OverlayAction.cancel:
            if (!this.data?.done) {
              this.cicoService.localSaveProcess(this.data);
              this.cicoService.closeLog('closed');
            }
            if (this.globals.kiosk() || !close.guard) {
              this.endProcess();
            }
            // Reset the user steps to their initial state.
            this.cicoService.resetUserSteps();
            break;
          case OverlayAction.skipCi:
            this.showOverlay = false;
            this.skipCi();
            break;
          case OverlayAction.closeSkipCi:
            this.showOverlay = false;
            setTimeout(() => {
              this.initScreen();
              if (this.idnow) {
                this.eventService.getEvent(EventConstants.toShowIdNowWhenUserClickBack).publish(undefined);
              }
            });
            break;
          case OverlayAction.start:
          case OverlayAction.close:
          case OverlayAction.addToFolios:
          case OverlayAction.reallySure:
          case OverlayAction.skip:
            this.showOverlay = false;
            break;
          case OverlayAction.reload:
            this.cicoService.logUnload = false;
            this.cicoService.localSaveProcess(this.data);
            this.cicoService.closeLog('process reload');
            this.globals.reload(true);
            break;
          default:
            break;
        }
      }),
    );
  }

  subConfirmName() {
    this.subscriptions.add(
      this.cicoService.confirmName.subscribe((confirmName: ConfirmName) => {
        this.confirmName = 'misc.' + confirmName;
      }),
    );
  }

  subSuppressGuard() {
    this.subscriptions.add(
      this.cicoService.suppressGuard.subscribe((suppressGuard) => {
        this.suppressGuard = suppressGuard;
      }),
    );
  }

  checkTaskModule() {
    this.subscriptions.add(
      this.cicoService?.data?.pipe(filter(Boolean), take(1)).subscribe((data: GenericData) => {
        if ([PmsModType.ci, PmsModType.co].includes(<PmsModType>data?.module?.type)) {
          this.subscriptions.add(
            this.globals.taskObservable.subscribe((taskModule) => {
              if (!taskModule) {
                this.globals.taskSubj.next(true);
              }
            }),
          );
        }
      }),
    );
  }

  endProcess() {
    if (this.globals.kiosk()) {
      this.cicoService.dataSubj.next(null);
      this.cicoService.idleSubj.next(true);
    } else {
      this.globals.navigate('home');
    }
  }

  kioskAlive() {
    this.subscriptions.add(
      this.wsService.online?.pipe(filter(Boolean)).subscribe(() => {
        this.subscriptions.add(
          this.cicoService.data.pipe(filter(Boolean)).subscribe(() => {
            this.wsService.statusSubj.next('in_use');
          }),
        );
      }),
    );
  }

  toggleHeaderFooter() {
    const observer = new MutationObserver((mutations) => {
      const container = document.getElementById('container');
      mutations.forEach((mutation: any) => {
        if (container && mutation.target) {
          if ([...mutation.addedNodes].length) {
            if (PmsCiCoBaseDirective.hasModalBoxes() || PmsCiCoBaseDirective.hasScanner() || PmsCiCoBaseDirective.isStart()) {
              container.classList.add('overlay');
            }
          } else if ([...mutation.removedNodes].length) {
            if (!PmsCiCoBaseDirective.hasModalBoxes() && !PmsCiCoBaseDirective.hasScanner() && !PmsCiCoBaseDirective.isStart() && !PmsCiCoBaseDirective.isPayingFolios()) {
              container.classList.remove('overlay');
            }
          }
        }
      });
    });
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  }

  skipCi() {
    this.cicoService.skipCi = true;
    this.data.authChosen = true;
    this.cicoService.setAutoSkipUntilStep(Step.confirm);
    this.cicoService.setShowFooter(true);
    setTimeout(() => {
      this.stepperService.nextStep();
    }, 250);
  }

  setReservation(modType: PmsModType) {
    this.ngOnInit(modType);
  }

  clearData() {
    this.data = null;
    this.cicoService.dataSubj.next(null);
    this.cicoService.removeSubscriptions();
  }

  checkIdnow() {
    this.idnow = this.data.incident.reservation.idnow();
  }

  private scrollToError() {
    const selectors = ['.error-message'];

    const input = Array.from(document.querySelectorAll(selectors.join(', ')))[0];
    input?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
  }

  ngOnDestroy(): void {
    if (!this.globals.kiosk()) {
      document.getElementById('container')?.classList?.remove('wizard', 'task', 'overlay');
    }
    this.subscriptions.unsubscribe();
    this.cicoService.removeSubscriptions();
    setTimeout(() => {
      this.cicoService.disableButtons(false);
      this.cicoService.hideNextButton(false);
    });
    this.cicoService.should_payment = true;
    this.cicoService.dataSubj.next(null);
  }
}
