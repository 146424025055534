import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PmsEncoderRoutingModule } from './encoder-routing.module';
import { PmsEncoderComponent } from './encoder.component';
import { SuccessScreenModule } from '../success-screen/success-screen.module';
import { MainTitleModule } from '../main-title/main-title.module';

@NgModule({
  imports: [CommonModule, PmsEncoderRoutingModule, TranslateModule, FormsModule, SuccessScreenModule, MainTitleModule],
  exports: [PmsEncoderComponent],
  declarations: [PmsEncoderComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PmsEncoderModule {}
