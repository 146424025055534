import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ApiService } from 'api_service';
import { Globals } from 'base';
import { PmsCiCoService } from 'cico_service';
import { Business } from 'models/business';
import { FolioType, PaymentProcess, PmsModType } from 'pms_enums';
import { GenericData } from 'pms_models/generic_data';
import { PmsFolio, PmsFolioPosition } from 'pms_models/pms_folio';
import { of, Subscription, timer } from 'rxjs';
import { delay, scan, take } from 'rxjs/operators';

@Component({
  selector: 'app-pms-folio',
  templateUrl: './folio.component.html',
  styleUrls: ['./folio.component.scss'],
})
export class PmsFolioComponent implements OnInit, OnDestroy {
  MIN_ITEMS: number = 2;
  business: Business;
  positions: Array<PmsFolioPosition>;
  subscriptions: Subscription = new Subscription();

  terms: SafeHtml;
  ui_messages: any;
  total: number;
  checkOut: boolean;
  collectAddress: boolean;
  pendingDynamicMsg: string;
  showAll: boolean = false;
  reservationModule: boolean = false;
  receivedData: boolean = false;
  showDetails: boolean = false;

  @ViewChild('folioForm') folioForm: NgForm;

  @Input() data: GenericData;
  @Input() folio: PmsFolio;
  @Input() payment: boolean;
  @Input() staticAddress: boolean;
  @Input() showHints: boolean;
  @Input() number: number;
  @Input() show_invoice_address: boolean;

  isDialogOpen: boolean = false;
  payButton: any;
  paymentStatus: any;
  folioType: FolioType;

  constructor(
    public cicoService: PmsCiCoService,
    private globals: Globals,
    private api: ApiService,
    private _cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    this.business = this.data.business;
    this.positions = this.folio.positions.filter((pos) => pos.viewable);
    this.total = this.data.incident.reservation.folios.length;

    this.ui_messages = this.cicoService.ui_messages();
    this.checkOut = this.data.module.type === PmsModType.co;
    this.reservationModule = this.data.isReservationModule();
    this.collectAddress = !this.staticAddress && !(this.reservationModule && this.payment && !this.folio.paymentAllowed());
    this.terms = this.sanitizer.bypassSecurityTrustHtml(this.globals.translate('service.payment.terms'));

    if (this.folio.check === 'pending') {
      this.msgManagement();
    }
  }

  msgManagement() {
    this.pendingDynamicMsg = this.ui_messages.pay_in_progress?.content;
    timer(0, 1000)
      .pipe(
        scan((acc) => acc + 1, 0),
        take(30),
      )
      .subscribe((sec) => {
        if (sec === 20) {
          this.pendingDynamicMsg = this.ui_messages.pay_delayed_still_in_progress?.content;
        } else if (sec === 50) {
          this.pendingDynamicMsg = this.ui_messages.pay_still_in_progress?.content;
        }
      });
  }

  showPayButton(): boolean {
    if (this.payment && this.data.paymentProcess === PaymentProcess.payment && this.folio.check !== 'pms_failed' && this.folio.payment !== 'no_payment') {
      if (this.data.module.type === PmsModType.co) {
        return this.folio.unpaid() || this.folio.authorized();
      } else {
        return !this.folio.paidOrAuthorized() && this.folio.paymentAllowed();
      }
    }
    return false;
  }

  validStaticAddress(): boolean {
    return !this.collectAddress || this.folioForm?.valid;
  }

  openPayment(event) {
    this.globals.clearAlert();

    const button = event.target;
    button.disabled = true;

    if (this.data.module.type !== PmsModType.co) {
      this.loadPaymentComponent(button);
      return;
    }

    this.api.silentGet('pms/card').subscribe((success: any) => {
      if (success?.payment || (success?.pre_auth?.token?.length && success?.pre_auth?.available_amount)) {
        this.payButton = button;
        this.paymentStatus = success.payment || success.pre_auth;
        this.folioType = FolioType.creditCard;
      } else {
        this.loadPaymentComponent(button);
      }
    });
  }

  private loadPaymentComponent(button) {
    this.payButton = button;
    const terminal = this.data.incident.reservation.payment_providers.terminal;
    this.folioType = terminal ? FolioType.terminal : FolioType.payment;
    this.isDialogOpen = true;
  }

  download(event) {
    event.target.classList.add('disabled');
    this.subscriptions.add(
      of(true)
        .pipe(delay(5000))
        .subscribe(() => {
          event.target.classList.remove('disabled');
        }),
    );
  }

  receiveDataFromChild(data: boolean) {
    this.cicoService.addressChange = true;
    this.receivedData = data;
  }

  toggleDetails() {
    this.showDetails = !this.showDetails;
  }

  closeDialog() {
    this.isDialogOpen = false;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
