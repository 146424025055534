import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WeatherModule } from 'modules/weather/weather.module';
import { WidgetAddressModule } from 'widgets/address/address.module';
import { WidgetGroupModule } from 'widgets/group/group.module';
import { WidgetGroupBusinessModule } from 'widgets/group_business/group_business.module';
import { WidgetGroupImageModule } from 'widgets/group_image/group_image.module';
import { WidgetLegalModule } from 'widgets/legal/legal.module';
import { WidgetLogoModule } from 'widgets/logo/logo.module';
import { WidgetPushModule } from 'widgets/push/push.module';
import { WidgetQrCodeModule } from 'widgets/qr_code/qr_code.module';
import { WidgetSocialModule } from 'widgets/social/social.module';
import { WidgetWeatherModule } from 'widgets/weather/weather.module';
import { WidgetA2hsModule } from 'widgets/a2hs/a2hs_widget.module';
import { MenuModule } from '../menu/menu.module';
import { NavbarRoutingModule } from './navbar-routing.module';
import { NavbarComponent } from './navbar.component';

@NgModule({
  imports: [CommonModule, NavbarRoutingModule, WidgetA2hsModule, WidgetLogoModule, WidgetAddressModule, WidgetGroupModule, WidgetGroupBusinessModule, WidgetGroupImageModule, WidgetLegalModule, WidgetPushModule, WidgetQrCodeModule, WidgetSocialModule, WidgetWeatherModule, MenuModule, WeatherModule],
  exports: [NavbarComponent],
  declarations: [NavbarComponent],
})
export class NavbarModule {}
