import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();

    const groups = ['entry', 'category'];

    return items.filter((item) => {
      let found = false;
      if (has_children(item)) {
        groups.forEach(function (value) {
          if (item[value] && !found) {
            found = search(item[value], searchText);
          }
        });
      } else {
        found = search(item, searchText);
      }
      return found;
    });
  }
}

function search(item, searchText) {
  let found = false;
  if (typeof item === 'string') {
    found = item.toLowerCase().indexOf(searchText) > -1;
  } else {
    const filters = ['name', 'title', 'description', 'content', 'entries_list', 'magazine', 'comfort_code', 'start_code'];
    filters.forEach(function (value) {
      if (item[value] && !found) {
        found = item[value].toString().toLowerCase().indexOf(searchText) > -1;
      }
    });
  }
  return found;
}

function has_children(item) {
  let found = false;
  const groups = ['entry', 'category'];
  Object.keys(item).forEach(function (value) {
    if (groups.indexOf(value) > -1) {
      found = true;
    }
  });
  return found;
}
