<div class="reservation-card">
  <div class="card-header">
    <h2 class="guest">{{ reservation.guest }}</h2>
    <span class="state {{ reservation.state_type }}">{{ reservation.state }}</span>
  </div>
  <span class="unit">{{ reservation.unit }}</span>
  <div class="arrival"><i class="fa-solid fa-arrow-right-to-bracket"></i> {{ reservation.arrival }}</div>
  <div class="departure"><i class="fa-solid fa-arrow-right-from-bracket"></i> {{ reservation.departure }}</div>
  <strong class="reservation-id">{{ reservation.reservation_id }}</strong>
</div>
