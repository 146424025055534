import { ChangeDetectorRef, Component, ComponentRef, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Globals } from 'base';
import { Business } from 'models/business';
import { interval, Subscription } from 'rxjs';
import { filter, take, takeWhile } from 'rxjs/operators';
import { BusinessService } from 'services/business.service';
import { ModalService } from './modal.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
})
export class ModalComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  public open: boolean;
  show_social: boolean;
  business: Business;

  @ViewChild('dynamicContent', { read: ViewContainerRef })
  dynamicContent: ViewContainerRef;
  componentRef: ComponentRef<any>;

  constructor(
    public modalService: ModalService,
    public globals: Globals,
    private businessService: BusinessService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.modalService.getModal().subscribe((isOpen) => {
        this.subscriptions.add(
          this.businessService.current_business.pipe(filter(Boolean)).subscribe((business: Business) => {
            this.business = business;

            this.componentRef?.destroy();
            this.open = isOpen as boolean;
            if (isOpen) {
              if (this.modalService.component?.selector === 'app-cookie-info') {
                this.show_social = false;
              } else {
                this.show_social = this.business.style.social_in_modal;
              }

              if (this.modalService.component) {
                interval(15)
                  .pipe(
                    takeWhile(() => !!this.dynamicContent),
                    take(1),
                  )
                  .subscribe(() => {
                    this.changeDetector.detectChanges();
                    this.componentRef = this.dynamicContent.createComponent(this.modalService.component);
                    if (this.modalService.params) {
                      Object.keys(this.modalService.params).forEach((key) => {
                        this.componentRef.instance[key] = this.modalService.params[key];
                      });
                    }
                    this.moveButtons();
                    this.changeDetector.detectChanges();
                  });
              }
            } else {
              this.clear();
            }
          }),
        );
      }),
    );
  }

  close() {
    if (this.modalService.closeable) {
      this.clear();
      this.modalService.close();
      if (this.dynamicContent) {
        this.dynamicContent.clear();
      }
    }
  }

  clear() {
    this.modalService.component = undefined;
    this.dynamicContent?.clear();
    this.componentRef?.destroy();
  }

  moveButtons() {
    const element = document.getElementById('modal');
    const buttons = element.getElementsByClassName('button-grid')[0];
    if (buttons && !buttons.classList.contains('sticky')) {
      const inner = element.getElementsByClassName('inner')[0];
      inner.classList.add('button-attached');
      inner.after(buttons);
    }
  }

  ngOnDestroy() {
    this.changeDetector.detach();
    this.subscriptions.unsubscribe();
  }
}
