<div class="input-container" [ngClass]="{ valid: isValid(), invalid: isInvalid() || (submited && isInvalid()), disabled: disabled }">
  <app-description-label [for]="inputId" [description]="description" [required]="required && !disabled" [tooltip]="tooltip"></app-description-label>

  <div tabindex="0" [id]="'select-wrapper' + inputId" class="input-wrapper" [class.open]="dropdownOpen" (mousedown)="dropdownOpen ? closeDropdown() : openDropdown()" (clickOutside)="dropdownOpen ? closeDropdown() : null" (keydown.enter)="dropdownOpen ? closeDropdown() : openDropdown()" (keydown.space)="dropdownOpen ? null : openDropdown()" (keydown.escape)="closeDropdown()">
    <img *ngIf="showIcons && displayelement" class="country-icon" [src]="displayelement?.flag" alt="" />
    <input class="redesign" [placeholder]="displayelement ? displayelement?.name : placeholder" [id]="inputId" [name]="name" [(ngModel)]="searchVal" (keyup)="handleInputKeyup($event)" [class.icon-hidden]="!displayelement" autocomplete="nope" [disabled]="disabled" [readonly]="triggerKeyboard" [attr.data-cy]="name" />
    <i class="fas fa-chevron-down dropdown-icon main-text"></i>
    <div class="select-list thin-scroll" [ngClass]="{ visible: dropdownOpen, 'display-top': dropdownPosition === 'above' }">
      <div *ngFor="let item of list; let i = index" [id]="'item-' + i" class="item-container" [class.selected]="item === displayelement" (mousedown)="changeSelItem(item)" (keydown.escape)="closeDropdown()" tabindex="0">
        <img *ngIf="showIcons" [src]="item?.flag" alt="" /><span>{{ item?.name }}</span>
      </div>
      <div *ngIf="list?.length === 0" class="not-found" translate>misc.no_match</div>
    </div>
  </div>

  <label class="error-label">
    <i class="fas fa-circle-xmark"></i> <span translate>{{ errorMsg }}</span>
  </label>
</div>
