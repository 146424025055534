import { Injectable } from '@angular/core';
import { ApiService } from 'api_service';
import { LanguageService } from 'common/language/language.service';
import { Guest } from 'models/guest';
import { Observable } from 'rxjs';
import { GuestService } from 'services/guest.service';

declare var InstallTrigger: any;

@Injectable({ providedIn: 'root' })
export class StraivService {
  constructor(
    private api: ApiService,
    private languageService: LanguageService,
    private guestService: GuestService,
    private _guest: Guest,
  ) {}

  getToken(cryptcode: string, device: Object, token: string, tokens: [], reservation?: string, origin?: string): Observable<Object> {
    if (cryptcode) {
      return this.api.rootPost('auth', {
        cryptcode: cryptcode,
        platform: this.determinePlatform(device['os'], device['deviceType']),
        browser: device['browser'].toLowerCase() === 'safari' ? this.checkSafari() : device['browser'],
        version: device['browser_version'],
        guest: token,
        tokens: tokens,
        reservation: reservation,
        o: origin,
      });
    } else {
      return Observable.create((observer) => {
        observer.next(false);
        observer.complete();
      });
    }
  }

  determinePlatform(os: string, deviceType: string) {
    const normalizedOS = os.toLowerCase();
    const normalizedDeviceType = deviceType.toLowerCase();

    if (normalizedOS === 'mac') {
      return normalizedDeviceType === 'tablet' ? 'iPad' : 'Mac';
    } else if (normalizedOS === 'ios') {
      return 'iPhone';
    } else {
      return normalizedOS;
    }
  }

  checkSafari() {
    if (this.isFirefox()) {
      return 'firefox';
    } else if (this.isOpera()) {
      return 'opera';
    } else {
      return 'safari';
    }
  }

  isFirefox(): boolean {
    try {
      return (typeof InstallTrigger !== undefined && typeof InstallTrigger !== 'undefined') || navigator.userAgent.indexOf('Firefox') > -1 || navigator.userAgent.indexOf('firefox') > -1;
    } catch (_) {
      return false;
    }
  }

  isOpera(): boolean {
    try {
      return (!!(<any>window).opr && !!(<any>window).opr.addons) || !!(<any>window).opera || navigator.userAgent.indexOf(' OP') >= 0;
    } catch (_) {
      return false;
    }
  }

  createGuest(data: any): Guest {
    const guest = this._guest;
    guest.setGuest(data.guest);
    this.guestService.setGuest(guest);
    this.api.codeSubj.next(guest.code);
    this.languageService.setLanguage(guest.locale);
    guest.save();

    return guest;
  }

  getLandingpPage(code: string) {
    return this.api.get('landingpage/' + code);
  }
}
