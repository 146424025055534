import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { WidgetLogoModule } from 'widgets/logo/logo.module';
import { LinkModule } from '../../shared/link/link.module';
import { PmsCiCoBaseDirective } from '../base/cico_base.directive';
import { PmsCheckInModule } from '../check_in/check_in.module';
import { PmsCheckOutModule } from '../check_out/check_out.module';
import { PmsEncoderModule } from '../shared/encoder/encoder.module';
import { PmsFoliosModule } from '../shared/folios/folios.module';
import { PmsFooterModule } from '../shared/footer/footer.module';
import { PmsLoginModule } from '../shared/login/login.module';
import { PmsWizardActionsModule } from './actions/actions.module';
import { PmsBookingModule } from './booking/booking.module';
import { ConflictModule } from './conflict/conflict.module';
import { DebugModule } from './debug/debug.module';
import { PmsWizardTerminalDoorModule } from './door/door.module';
import { PmsKioskRoutingModule } from './kiosk-routing.module';
import { PmsKioskComponent } from './kiosk.component';
import { PmsWizardTerminalOptionModule } from './option/option.module';
import { PmsKioskReservationModule } from './reservation/reservation.module';
import { PmsKioskTimerModule } from './timer/timer.module';

@NgModule({
  imports: [CommonModule, WidgetLogoModule, PmsCheckOutModule, PmsCheckInModule, PmsLoginModule, PmsWizardTerminalOptionModule, PmsKioskRoutingModule, PmsKioskTimerModule, TranslateModule, ConflictModule, PmsWizardActionsModule, DebugModule, PmsFoliosModule, LinkModule, PmsFooterModule, PmsBookingModule, PmsKioskReservationModule, PmsEncoderModule, PmsWizardTerminalDoorModule],
  exports: [PmsKioskComponent],
  declarations: [PmsKioskComponent],
  providers: [PmsCiCoBaseDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PmsKioskModule {}
