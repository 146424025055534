<div class="animation-out" *ngIf="fields">
  <div #passportForm class="form-grid">
    <div class="passport-row" *ngIf="guest.passport_data || (fields.passport_image && guest.passport_image)">
      <straiv-select *ngIf="guest.valid_documents" ngDefaultControl name="doc_type" [labelText]="'service.check_in.documents.title' | translate" [items]="docTypes | json" [value]="guest.doc_type" [placeholder]="'validation.selection' | translate" [required]="requiredDocType" [errorMessage]="'validation.doc_type' | translate" (selectedEvent)="onSelectedDocType($event)"> </straiv-select>
    </div>

    <ng-container *ngIf="guest.passport_data && guest.valid_documents">
      <straiv-input *ngIf="fields.passport_authority" ngDefaultControl name="passport_authority" [required]="fields.passport_authority.required" [labelText]="(guest.doc_type ? ('service.check_in.documents.' + guest.doc_type | translate) + ' ' : '') + fields.passport_authority.name" [placeholder]="(guest.doc_type ? ('service.check_in.documents.' + guest.doc_type | translate) + ' ' : '') + fields.passport_authority.name" [(ngModel)]="guest.passport_authority" [errorMessage]="'validation.required' | translate" (valueChange)="setRequiredDocTyp()"> </straiv-input>

      <straiv-input *ngIf="fields.passport_id" ngDefaultControl min="6" max="254" name="passport_id" [required]="fields.passport_id.required" [labelText]="(guest.doc_type ? ('service.check_in.documents.' + guest.doc_type | translate) + ' ' : '') + fields.passport_id.name" [placeholder]="(guest.doc_type ? ('service.check_in.documents.' + guest.doc_type | translate) + ' ' : '') + fields.passport_id.name" [(ngModel)]="guest.passport_id" [errorMessage]="passportErrorMsg ? passportErrorMsg : guest.passport_id ? '' : ('validation.required' | translate)" [duplicatedValue]="passportErrorMsg ? true : false" (inputBlur)="onBlurPassportNumber()" (valueChange)="handleDocumentChange($event, 'passport_id'); setRequiredDocTyp()"> </straiv-input>

      <straiv-datepicker *ngIf="fields.passport_date" ngDefaultControl name="passport_date" [required]="fields.passport_date.required" [locale]="locale" [localeLng]="locale" [labelText]="fields.passport_date.name + (guest.doc_type ? ' (' + ('service.check_in.documents.' + guest.doc_type | translate) + ')' : '')" [errorMessage]="datePickerValidationMessages | translate" [validation]="fields.passport_date.validation" [(ngModel)]="guest.passport_date" (dateBlur)="setDatePickerValidationMessage($event, 'passport_date'); setRequiredDocTyp()"> </straiv-datepicker>

      <straiv-datepicker *ngIf="fields.passport_expire" ngDefaultControl name="passport_expire" [required]="fields.passport_expire.required" [locale]="locale" [localeLng]="locale" [labelText]="fields.passport_expire.name + (guest.doc_type ? ' (' + ('service.check_in.documents.' + guest.doc_type | translate) + ')' : '')" [errorMessage]="datePickerValidationMessages | translate" [validation]="fields.passport_expire.validation" [(ngModel)]="guest.passport_expire" (dateBlur)="setDatePickerValidationMessage($event, 'passport_expire'); setRequiredDocTyp()"> </straiv-datepicker>
    </ng-container>
  </div>
  <div class="camera-section" *ngIf="fields.passport_image">
    <straiv-label [header]="'service.check_in.documents.picture_header' | translate" [description]="'service.check_in.documents.picture_description' | translate"></straiv-label>
    <straiv-button *ngIf="guest.valid_documents && !guest.passport" name="passport-camera-button" left-icon="fas fa-camera" [leftLabel]="'service.check_in.documents.add_photo' | translate" (buttonClick)="addPassportDoc()"> </straiv-button>

    <app-file-upload *ngIf="guest.valid_documents && !guest.passport" name="file_upload" [trigger$]="fileUploadTrigger$" [pdf]="true" (success)="onUploadSuccess($event)" [required]="false"></app-file-upload>

    <div class="photos-container" *ngIf="guest.passport">
      <div class="photo-container">
        <div *ngIf="!photoIsPdf" class="photo-wrapper" (contextmenu)="preventContextMenu($event)" (click)="photoClick(guest.passport)">
          <img [src]="passportImg" alt="" />
        </div>
        <img *ngIf="photoIsPdf" class="pdf-icon" src="assets/images/pdf.svg" alt="PDF" />
        <button type="button" class="delete-button main-bg" (click)="removePhoto()" (press)="$event.stopPropagation()" [attr.data-cy]="'delete-image'"><i class="fa-solid fa-xmark"></i></button>
      </div>
    </div>
  </div>
  <ng-container *ngIf="guest.visa_data">
    <straiv-section-header [heading]="'service.check_in.visa.title' | translate"></straiv-section-header>
    <div class="form-grid">
      <straiv-datepicker *ngIf="fields.visa_date" ngDefaultControl name="visa_date" [labelText]="fields.visa_date.name" [locale]="locale" [localeLng]="locale" [required]="fields.visa_date.required" [errorMessage]="datePickerValidationMessages | translate" [validation]="fields.visa_date.validation" [(ngModel)]="guest.visa_date" (dateBlur)="setDatePickerValidationMessage($event, 'visa_date')"> </straiv-datepicker>

      <straiv-datepicker *ngIf="fields.visa_expire" ngDefaultControl name="visa_expire" [labelText]="fields.visa_expire.name" [locale]="locale" [localeLng]="locale" [required]="fields.visa_expire.required" [errorMessage]="datePickerValidationMessages | translate" [validation]="fields.visa_expire.validation" [(ngModel)]="guest.visa_expire" (dateBlur)="setDatePickerValidationMessage($event, 'visa_expire')"> </straiv-datepicker>

      <straiv-input *ngIf="fields.visa_number" ngDefaultControl name="visa_number" [labelText]="fields.visa_number.name" [placeholder]="fields.visa_number.name" [required]="fields.visa_number.required" [(ngModel)]="guest.visa_number" [errorMessage]="'validation.visa_number' | translate" (valueChange)="handleDocumentChange('visa_number')" (inputBlur)="onBlurVisaNumber()"> </straiv-input>
    </div>
  </ng-container>

  <app-photo-preview class="animation-out" *ngIf="guest.passport_image && showPreview" [photoSrc]="passportImg" (close)="showPreview = false"></app-photo-preview>
</div>

<straiv-dialog *ngIf="showCamera" id="dialog" size="md" [closeOnClickOutside]="false" [open]="showCamera" (dialogClose)="onPhotoDialogClose()">
  <straiv-dialog-header class="straiv-dialog"></straiv-dialog-header>
  <app-passport-photo class="animation-out" [type]="guest.doc_type" (success)="valueChange()"></app-passport-photo>
</straiv-dialog>
