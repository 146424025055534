import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderModule } from 'common/loader/loader.module';
import { SafePipeModule } from 'pipes/safe.pipe.module';
import { PmsBaseDirective } from 'pms_base/base.directive';
import { IconBoxGroupModule } from '../../../../common/icon-box-group/icon-box-group.module';
import { TypographyModule } from '../../../../common/typography/typography.module';
import { PmsSimpleFooterModule } from '../../shared/simple-footer/simple-footer.module';
import { PmsAuthenticationRoutingModule } from './authentication-routing.module';
import { PmsAuthenticationComponent } from './authentication.component';
import { VerificationBoxGroupComponent } from './verification-box-group/verification-box-group.component';
import { IconboxModule } from 'common/icon-box/icon-box.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, PmsAuthenticationRoutingModule, SafePipeModule, TranslateModule, LoaderModule, IconboxModule, TypographyModule, IconBoxGroupModule, PmsSimpleFooterModule, FormsModule, ReactiveFormsModule],
  exports: [PmsAuthenticationComponent, VerificationBoxGroupComponent],
  declarations: [PmsAuthenticationComponent, VerificationBoxGroupComponent],
  providers: [PmsBaseDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PmsAuthenticationModule {}
