import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgPipesModule } from 'ngx-pipes';
import { FilterPipeModule } from 'pipes/filter.pipe.module';
import { WidgetAdvertModule } from 'widgets/advert/advert.module';
import { AttachmentsModule } from 'shared/layout/attachments/attachments.module';
import { ModuleTitleModule } from 'shared/layout/module_title.module';
import { PortfolioListAllRoutingModule } from './all-routing.module';
import { PortfolioListAllComponent } from './all.component';
import { ServiceAllDirective } from 'shared/service/all.directive';

@NgModule({
  imports: [CommonModule, PortfolioListAllRoutingModule, WidgetAdvertModule, ModuleTitleModule, FormsModule, AttachmentsModule, TranslateModule, NgPipesModule, FilterPipeModule],
  exports: [PortfolioListAllComponent],
  declarations: [PortfolioListAllComponent],
  providers: [ServiceAllDirective],
})
export class PortfolioListAllModule {}
