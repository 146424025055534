import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { WidgetAdvertModule } from 'widgets/advert/advert.module';
import { ModuleTitleModule } from 'shared/layout/module_title.module';
import { SharedSwiperModule } from 'shared/layout/swiper/swiper.module';
import { EventListCalendarRoutingModule } from './calender-routing.module';
import { EventListCalendarComponent } from './calendar.component';
import { EventListCalenderItemModule } from './item/calender-item.module';

@NgModule({
  imports: [CommonModule, EventListCalendarRoutingModule, EventListCalenderItemModule, ModuleTitleModule, SharedSwiperModule, WidgetAdvertModule, TranslateModule],
  exports: [EventListCalendarComponent],
  declarations: [EventListCalendarComponent],
})
export class EventListCalendarModule {}
