<app-module-title [module]="module"></app-module-title>

<ng-container *ngIf="weathers">
  <input class="toggleSlider" type="checkbox" id="toggle" [checked]="showFahrenheit" (change)="changeUnit($event)" />
  <label for="toggle" class="toggler slim">
    <span class="on">°F</span>
    <span class="off">°C</span>
  </label>
  <div class="container">
    <div class="container-left">
      <div class="weather">
        <div class="current clearfix">
          <div class="weatherblock" *ngFor="let weather of weathers | slice: 3 : 4; let i = index">
            <ng-container [ngTemplateOutlet]="info" [ngTemplateOutletContext]="{ weather: weather }"></ng-container>
          </div>
          <app-widget-suggestions origin="content" *ngIf="smart_weather"></app-widget-suggestions>
        </div>
        <div class="forecast">
          <div class="weatherblock" *ngFor="let weather of weathers | slice: 0 : 3; let i = index">
            <ng-container [ngTemplateOutlet]="info" [ngTemplateOutletContext]="{ weather: weather }"></ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #info let-weather="weather">
  <span class="weathericon"><img [src]="weather.media.image" [alt]="weather.description" /></span>
  <div class="info">
    <h4>{{ weather.date_name }}</h4>
    <p>{{ weather.description }}</p>
    <h2 *ngIf="showFahrenheit; else celsius">{{ weather.temp_fahrenheit }} °F</h2>
    <ng-template #celsius
      ><h2>{{ weather.temp }} °C</h2></ng-template
    >
    <div class="additional-info">
      <p><span class="icon humidity"></span> {{ weather.humidity }} %</p>
      <p><span class="icon wind"></span> {{ weather.speed }} km/h</p>
    </div>
  </div>
</ng-template>
