import { Injectable } from '@angular/core';
import { PmsCiCoService } from 'cico_service';
import { EventConstants } from 'global_enums';
import { GuestSubSteps, StepperActions, StepperState } from 'models/pms/stepper';
import { Step } from 'pms_enums';
import { EventAggregatorService } from 'services/events/event-aggregator.service';

@Injectable({ providedIn: 'root' })
export class StepperService {
  constructor(
    private cicoService: PmsCiCoService,
    protected readonly eventService: EventAggregatorService,
  ) {}

  /**
   * Determines the label for the button based on the current state of the stepper.
   * @returns 'Check In' if the current step is the last step and its last substep is active, otherwise 'Continue'.
   */
  getCurrentButtonText(): string {
    const activeIndex = this.getActiveStepIndex();
    return activeIndex === this.cicoService.userSteps.length - 1 && this.cicoService.userSteps[activeIndex].currentStep === this.cicoService.userSteps[activeIndex].totalSteps ? 'Check In' : 'Continue';
  }

  /** Moves the stepper to the next step or substep. */
  nextStep(): void {
    const currentIndex = this.getActiveStepIndex();
    if (currentIndex !== -1) {
      this.triggerNavigationSetAction(StepperActions.next);
    }
    this.cicoService.setActiveStepper();
  }

  /** Moves the stepper back to the previous step or substep, handling multi-step and sub-step processes. */
  previousStep(): void {
    // Handling for the primary guest sub-steps.
    if (this.cicoService.subStepForGuest == GuestSubSteps.identificationGuest || this.cicoService.subStepForGuest == GuestSubSteps.privateAddress) {
      this.triggerNavigationSetAction(StepperActions.previous);
      return;
    }

    // Find the index of the currently active step.
    let activeIndex = this.getActiveStepIndex();

    // Handle case where no active steps are found (assume all steps are completed).
    if (activeIndex === -1) {
      this.startFromLastStepToPrevious();
      this.triggerNavigationSetAction(StepperActions.previous);
      return;
    }

    // Store the currently active step in a variable for easy access.
    const activeStep = this.cicoService.userSteps[activeIndex];

    // Check if there are sub-steps within the current step and decrement the sub-step count if possible.
    // If the current step is not the first sub-step, decrement the sub-step.
    if (activeStep.currentStep > 1) {
      activeStep.currentStep--;
      this.triggerNavigationSetAction(StepperActions.previous);
      return;
    }

    // Special handling for the very first step and user click back and show idnow.
    if (activeIndex === 0) {
      if (activeStep.currentStep === 1 && activeStep.key === Step.guests && (activeStep.action === StepperActions.previous || activeStep.action === StepperActions.none)) {
        this.eventService.getEvent(EventConstants.toShowIdNowWhenUserClickBack).publish(undefined);
      }
    }

    // Move to the previous step if the all the sub set is completed.
    if (activeIndex > 0) {
      this.moveToPreviousStep(activeIndex);
      this.triggerNavigationSetAction(StepperActions.previous);
    }

    this.cicoService.setActiveStepper();
  }

  /**
   * Continue the steps or completes the current step and activates the next step if the validation is success.
   */
  continueOrCompleteStepper(): void {
    const currentIndex = this.getActiveStepIndex();
    if (currentIndex === -1) return;
    const currentStep = this.cicoService.userSteps[currentIndex];

    // Check if the current sub-step is the last one within the step.
    if (currentStep.currentStep < currentStep.totalSteps) {
      currentStep.currentStep++;
    } else {
      currentStep.state = StepperState.done;
      this.activateNextStep(currentIndex);
    }
  }

  /** Starts the stepper from the last step if no active steps are found. */
  private startFromLastStepToPrevious(): void {
    // Set to the last step in the list as the starting point.
    const lastIndex = this.cicoService.userSteps.length - 1;
    this.cicoService.userSteps[lastIndex].state = StepperState.active;

    // If the last step has sub-steps, set to the last sub-step.
    if (this.cicoService.userSteps[lastIndex].totalSteps > 1) {
      this.cicoService.userSteps[lastIndex].currentStep = this.cicoService.userSteps[lastIndex].totalSteps;
    }
  }

  /** Moves to the previous step and updates states appropriately. */
  private moveToPreviousStep(currentIndex: number): void {
    const previousIndex = currentIndex - 1;

    // Set the current step to disabled.
    this.cicoService.userSteps[currentIndex].state = StepperState.disabled;
    this.cicoService.userSteps[currentIndex].action = StepperActions.none;

    // Activate the previous step.
    const previousStep = this.cicoService.userSteps[previousIndex];
    previousStep.state = StepperState.active;

    // Set the sub-step of the previous step to its maximum for backward navigation.
    previousStep.currentStep = previousStep.totalSteps;
  }

  /**
   * Activates the next step in the sequence.
   * @param currentIndex The index of the current step.
   */
  private activateNextStep(currentIndex: number): void {
    if (currentIndex + 1 < this.cicoService.userSteps.length) {
      this.cicoService.userSteps[currentIndex + 1].state = StepperState.active;
    }
  }

  /**
   * Triggers a navigation change to the previous step based on the provided action.
   * @param action The action to set for the button, either 'Next' or 'Previous'.
   */
  private triggerNavigationSetAction(action: string) {
    const activeStepper = this.cicoService.userSteps.find((step) => step.state === StepperState.active);
    // If an active stepper is found, update its action and set it as the active step in the service
    if (activeStepper) {
      activeStepper.action = action; // Set the action (Next or Previous) for the active stepper
      this.cicoService.activeStep = activeStepper; // Update the active steps in the service
    }
    this.eventService.getEvent(EventConstants.navigationChange).publish(undefined);
  }

  /** Get active step index */
  private getActiveStepIndex(): number {
    return this.cicoService.userSteps.findIndex((step) => step.state === StepperState.active);
  }
}
