import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from 'api_service';
import { Globals } from 'base';

@Component({
  selector: 'app-widget-group-image',
  templateUrl: './group_image.component.html',
})
export class WidgetGroupImageComponent implements OnInit {
  widget: any;

  constructor(
    private globals: Globals,
    private router: Router,
    private api: ApiService,
  ) {}

  ngOnInit() {
    this.getWidget().subscribe((success) => {
      this.widget = success['image_widget'];
    });
  }

  action() {
    if (this.widget['system_url']) {
      this.router.navigate(['/g', this.globals.getCode()].concat(this.widget['system_url'].split('/')));
    } else {
      this.globals.openUrl(this.widget['url'], this.widget['url_target']);
    }
  }

  getWidget(): Observable<Object> {
    return this.api.get('widgets/group_image');
  }
}
