import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FilterPipeModule } from 'pipes/filter.pipe.module';
import { WidgetAdvertModule } from 'widgets/advert/advert.module';
import { ModuleTitleModule } from 'shared/layout/module_title.module';
import { SharedSwiperModule } from 'shared/layout/swiper/swiper.module';
import { TvStationsRoutingModule } from './tv_stations-routing.module';
import { TvStationsComponent } from './tv_stations.component';

@NgModule({
  imports: [CommonModule, TvStationsRoutingModule, ModuleTitleModule, SharedSwiperModule, WidgetAdvertModule, FormsModule, FilterPipeModule, TranslateModule],
  exports: [TvStationsComponent],
  declarations: [TvStationsComponent],
})
export class TvStationsModule {}
