<div *ngFor="let date of dates_list" class="date-button" [class.disabled_state]="!date.available" (click)="choose(date.index)" [attr.data-index]="date.index">{{ date.view }}</div>

<input type="checkbox" #bool_helper name="bool_helper" [(ngModel)]="service.bool_helper" class="hidden" required />
<label translate class="error">validation.dates</label>

<div class="form-box checkboxgroup" *ngIf="select_all_days && dates_list && dates_list.length > 1">
  <div class="checkbox">
    <input (change)="check($event)" name="select_all" [(ngModel)]="select" type="checkbox" id="select_all" class="option-input checkbox" />
    <label translate for="select_all">green_choice.select_all_days</label>
  </div>
</div>
