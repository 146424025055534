import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgPipesModule } from 'ngx-pipes';
import { FilterPipeModule } from 'pipes/filter.pipe.module';
import { WidgetAdvertModule } from 'widgets/advert/advert.module';
import { AttachmentsModule } from 'shared/layout/attachments/attachments.module';
import { BacklinkModule } from 'shared/layout/backlink/backlink.module';
import { SharedSwiperModule } from 'shared/layout/swiper/swiper.module';
import { ServiceParentDirective } from 'shared/service/parent.directive';
import { CouponListParentRoutingModule } from './parent-routing.module';
import { CouponListParentComponent } from './parent.component';

@NgModule({
  imports: [CommonModule, CouponListParentRoutingModule, TranslateModule, FilterPipeModule, NgPipesModule, BacklinkModule, SharedSwiperModule, WidgetAdvertModule, AttachmentsModule, FormsModule],
  exports: [CouponListParentComponent],
  declarations: [CouponListParentComponent],
  providers: [ServiceParentDirective],
})
export class CouponListParentModule {}
