import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SharedSwiperModule } from 'modules/shared/layout/swiper/swiper.module';
import { WidgetAdvertModule } from 'widgets/advert/advert.module';
import { RestrictionRoutingModule } from './restriction-routing.module';
import { RestrictionComponent } from './restriction.component';

@NgModule({
  imports: [CommonModule, RestrictionRoutingModule, SharedSwiperModule, WidgetAdvertModule, TranslateModule],
  exports: [RestrictionComponent],
  declarations: [RestrictionComponent],
})
export class RestrictionModule {}
