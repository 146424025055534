import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FilterPipeModule } from 'pipes/filter.pipe.module';
import { WidgetAdvertModule } from 'widgets/advert/advert.module';
import { BacklinkModule } from '../layout/backlink/backlink.module';
import { SharedSwiperModule } from '../layout/swiper/swiper.module';
import { ListRoutingModule } from './list-routing.module';
import { ListComponent } from './list.component';

@NgModule({
  imports: [CommonModule, ListRoutingModule, SharedSwiperModule, WidgetAdvertModule, FormsModule, TranslateModule, FilterPipeModule, BacklinkModule],
  exports: [ListComponent],
  declarations: [ListComponent],
})
export class ListModule {}
