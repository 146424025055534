<app-pms-login [mod]="ciCoType.co" *ngIf="loginRequired; else process"></app-pms-login>

<ng-template #process>
  <ng-template [ngTemplateOutlet]="loading" *ngIf="cicoService.autoSkipUntilStep"></ng-template>
  <ng-container *ngIf="loaded && data?.incident?.reservation && data?.module?.type === ciCoType.co; else loading">
    <div class="grid-container" [class.hidden]="showOverlay || cicoService.autoSkipUntilStep">
      <div class="content">
        <app-steps-indicator></app-steps-indicator>

        <straiv-section-header heading="{{ cicoService.sectionDetails.sectionHeader }}" description="{{ cicoService.sectionDetails.sectionDescription }}"></straiv-section-header>

        <app-pms-co-general class="animation-out step-container" #reservation [submited]="formSubmited" *ngIf="this.cicoService.activeStep?.key === 'reservation'"></app-pms-co-general>

        <app-pms-folios class="animation-out step-container" #invoice [payment]="payment" *ngIf="this.cicoService.activeStep?.key === 'invoice'"></app-pms-folios>

        <app-pms-confirm class="animation-out step-container" #confirm *ngIf="this.cicoService.activeStep?.key === 'confirm'"></app-pms-confirm>
      </div>
    </div>

    <app-pms-footer class="animation-in" *ngIf="cicoService.showFooter" [currentIndex]="this.cicoService.activeStep.currentStep" [confirmName]="confirmName"></app-pms-footer>
  </ng-container>
</ng-template>

<app-pms-cico-overlay [business]="globals.business" [content]="olContent" *ngIf="globals.business && showOverlay"></app-pms-cico-overlay>

<ng-template #loading>
  <app-loader *ngIf="!loaded || cicoService.autoSkipUntilStep"></app-loader>
  <app-info-screen [type]="cicoService.loadingError.type" [text]="cicoService.loadingError.message" *ngIf="loaded && cicoService.loadingError"></app-info-screen>
</ng-template>
