<section id="footer" *ngIf="item">
  <a (click)="globals.goBack()" class="icon-button"> <i class="fas fa-arrow-left"></i>{{ 'footer.back' | translate }} </a>
  <a class="icon-button" (click)="toggleMenu()">
    <div *ngIf="!open"><i class="fas fa-bars"></i>{{ 'footer.menu' | translate }}</div>
    <div *ngIf="open"><i class="fas fa-times"></i>{{ 'footer.menu_close' | translate }}</div>
  </a>
  <ng-container *ngIf="item.link; else action">
    <a class="icon-button ext-link" [href]="item.link['url']" target="_blank"> <i class="{{ item.icon }}"></i>{{ item.name }} </a>
  </ng-container>
  <ng-template #action>
    <a class="icon-button" (click)="execute(item)"> <i class="{{ item.icon }}"></i>{{ item.name }} </a>
  </ng-template>
</section>
