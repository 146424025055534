import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { PmsCiCoService } from 'cico_service';
import { Field } from 'models/field';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { GuestService } from 'services/guest.service';
import { StorageService } from 'services/storage.service';
import { Globals } from 'base';
import { filter } from 'rxjs/operators';
import { GenericData } from 'models/pms/generic_data';
import { OverlayAction } from 'pms_enums';

@Component({
  selector: 'app-pms-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss'],
})
export class PmsSignatureComponent implements OnInit, AfterViewInit {
  @ViewChild('signatureTitle') signatureTitle: ElementRef;
  @ViewChild('signaturePad', { static: false }) signaturePad: SignaturePad;

  public SIG_PAD_OPTS: Object = {
    minWidth: 1,
    canvasWidth: 200,
    canvasHeight: 200,
  };

  @Input() data: GenericData;
  @Input() confirmName = '';

  subscriptions: Subscription = new Subscription();
  today: any;
  signatureField: Field;
  touched: boolean;
  disabled: boolean;
  valid: boolean = false;
  canvas: any;
  showImg: boolean = false;
  legal: any;
  width = 300;

  constructor(
    protected guestService: GuestService,
    protected storageService: StorageService,
    public cicoService: PmsCiCoService,
    public globals: Globals,
    private _cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.SIG_PAD_OPTS['minWidth'] = window.screen.width <= 640 ? 2 : 1;

    this.today = moment().format('L');
    this.signatureField = this.cicoService.field_for('signature');
    this.legal = this.globals.business?.legal;

    this.subscriptions.add(
      this.cicoService.idleSubj.pipe(filter(Boolean)).subscribe((_idle) => {
        this.cancelDialog();
      }),
    );
  }

  ngAfterViewInit() {
    this.resizeSignaturePad();
    this.renderSignature();
    this._cdr.detectChanges();
  }

  validate() {
    this.touched = true;
    this.valid = (<any>this.signaturePad.toData()).flat().length > 5 || this.data.helper;
    if (this.valid) {
      this.updateSignature();
    }
  }

  resetSignature() {
    this.touched = false;
    this.valid = false;
    this.data.incident.reservation.signature = null;
    this.data.helper = null;
    this.signaturePad.clear();
    this._cdr.detectChanges();
  }

  updateSignature() {
    const canvas = document.getElementsByTagName('canvas')[0];
    this.canvas = canvas;
    this.showImg = true;
    this.data.helper = this.canvas.toDataURL();
    const base64 = this.trimCanvas(canvas).toDataURL();
    this.cicoService.cacheImage('signature', this.data.uuid, base64);
    this.data.incident.reservation.signature = base64;
  }

  renderSignature() {
    if (this.data.helper) {
      this.signaturePad.fromDataURL(this.data.helper, { width: this.signatureTitle.nativeElement.offsetWidth, height: this.SIG_PAD_OPTS['canvasHeight'] });
      this.touched = true;
      this.valid = true;
    }
  }

  cancelDialog() {
    this.cicoService.closeOverlay(OverlayAction.close);
    this.subscriptions.unsubscribe();
  }

  submit() {
    this.disabled = true;
    this.cicoService.sendSubj.next(true);
    this.cancelDialog();
  }

  resizeSignaturePad() {
    const small = window.screen.height <= 700;
    const reallySmall = window.screen.height <= 600;
    const el = document.getElementById('signature-title');
    const canvas = <HTMLCanvasElement>document.querySelector('signature-pad > canvas');
    this.signaturePad.set('canvasWidth', el.clientWidth);

    if (canvas) {
      canvas.style.width = `${el.clientWidth}px`;
      if (reallySmall) {
        this.signaturePad.set('canvasHeight', 100);
        canvas.style.height = '100px';
      } else if (small) {
        this.signaturePad.set('canvasHeight', 150);
        canvas.style.height = '150px';
      }
    }
  }

  trimCanvas(c) {
    const ctx = c.getContext('2d');
    const copy = document.createElement('canvas').getContext('2d');
    const pixels = ctx.getImageData(0, 0, c.width, c.height);
    const l = pixels.data.length;
    const bound = { top: null, left: null, right: null, bottom: null };
    let i, x, y;

    // Iterate over every pixel to find the highest
    // and where it ends on every axis ()
    for (i = 0; i < l; i += 4) {
      if (pixels.data[i + 3] !== 0) {
        x = (i / 4) % c.width;
        y = ~~(i / 4 / c.width);

        if (!bound.top) {
          bound.top = y;
        }

        if (!bound.left || x < bound.left) {
          bound.left = x;
        }

        if (!bound.right || bound.right < x) {
          bound.right = x;
        }

        if (!bound.bottom || bound.bottom < y) {
          bound.bottom = y;
        }
      }
    }

    // Calculate the height and width of the content
    const trimHeight = bound.bottom - bound.top + 20;
    const trimWidth = bound.right - bound.left;
    const trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight);

    copy.canvas.width = trimWidth;
    copy.canvas.height = trimHeight;
    copy.putImageData(trimmed, 0, 0);

    // Return trimmed canvas
    return copy.canvas;
  }

  @HostListener('window:resize', ['$event'])
  onResize(_event) {
    this.resizeSignaturePad();
  }
}
