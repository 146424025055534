<div class="clearfix slider group-slide" *ngIf="adverts && showSwiper; else noSwiper">
  <swiper [config]="slideConfig" (indexChange)="setIndex($event)" (slideChangeTransitionEnd)="countView(adverts[slideIndex]?.id)">
    <div *ngFor="let advert of adverts; let i = index" class="swiper-slide" (click)="open(adverts[slideIndex]?.id, $event)">
      <div class="slider-content">
        <span class="name">- {{ 'advert.description' | translate }} -</span>
        <a class="inner"> <img [src]="advert.image" [alt]="advert.name" /></a>
      </div>
    </div>
  </swiper>
</div>

<ng-template #noSwiper>
  <div class="partner" *ngIf="advert">
    <a [title]="advert.name" (click)="openUrl(advert)">
      <img [src]="advert.image" [alt]="advert.name" />
    </a>
    <span class="single">- {{ 'advert.description' | translate }} -</span>
  </div>
</ng-template>
