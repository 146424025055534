<nav role="navigation" class="element-navigation" *ngIf="element">
  <div class="navigation-container clearfix">
    <div class="nav-btn">
      <a translate [class.disabled]="!element['previous']" (click)="previous()">misc.back</a>
    </div>
    <div class="nav-btn">
      <a translate (click)="globals.goBack()">misc.overview</a>
    </div>
    <div class="nav-btn">
      <a translate [class.disabled]="!element['next']" (click)="next()">misc.next</a>
    </div>
  </div>
</nav>

<div class="swipe_info" [style.display]="guest.swipe ? 'block' : 'none'" [ngClass]="{ swipe_fade: hideSwipeInfo }">
  <div>
    <img src="/assets/images/swipe_left.svg" alt="Swipe Left" />
    <span translate>misc.back</span>
  </div>
  <div>
    <img src="/assets/images/swipe_right.svg" alt="Swipe Right" />
    <span translate>misc.next</span>
  </div>
</div>
