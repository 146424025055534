import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import Bugsnag from '@bugsnag/js';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'api_service';
import { environment } from 'environments/environment';
import { LogType } from 'global_enums';
import { Business } from 'models/business';
import { Guest } from 'models/guest';
import { Link } from 'models/link';
import { Module } from 'models/module';
import { Place } from 'models/place';
import { Target } from 'models/target';
import * as moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import posthog from 'posthog-js';
import { datadogRum } from '@datadog/browser-rum';
import { BehaviorSubject, interval, Observable, of, Subscription } from 'rxjs';
import { delay, filter, take, takeWhile } from 'rxjs/operators';
import { AwayService } from 'services/away.service';
import { BusinessService } from 'services/business.service';
import { GuestService } from 'services/guest.service';
import { LoggerService } from 'services/logger.service';
import { ModuleService } from 'services/module.service';
import { OfflineService } from 'services/offline.service';
import { VisitService } from 'services/visit.service';
import { ExternalLinkService } from 'shared/link/external/external.service';
import { CookieSetting } from 'models/cookie_setting';
import { StorageService } from 'services/storage.service';

@Injectable({ providedIn: 'root' })
export class Globals implements OnDestroy {
  constructor(
    private businessService: BusinessService,
    private guestService: GuestService,
    private moduleService: ModuleService,
    private visitService: VisitService,
    public awayService: AwayService,
    private router: Router,
    private location: Location,
    private externalLinkService: ExternalLinkService,
    private api: ApiService,
    private offlineService: OfflineService,
    private storageService: StorageService,
    private translateService: TranslateService,
    private logger: LoggerService,
    private toast: ToastrService,
    private deviceService: DeviceDetectorService,
  ) {
    this.userAgent = window.navigator.userAgent.toLowerCase();

    this.subscriptions.add(
      this.guestService.currentGuest.pipe(filter(Boolean)).subscribe((guest: Guest) => {
        this.guest = guest;
        this.place = this.guest.place;
      }),
    );
    this.subscriptions.add(
      this.offlineService.offline.subscribe((offline) => {
        this.offline = offline;
        this.disableLinks(offline);
        if (offline) {
          this.log('No internet connection', LogType.error);
        }
      }),
    );
  }

  private static readonly JOURNEYS = ['default', 'stay', 'pre_stay', 'post_stay', 'pwa'];
  public _module: Module = new Module({ type: 'home' });
  subscriptions: Subscription = new Subscription();
  cacheSub: Subscription = new Subscription();
  error: HttpErrorResponse;
  business: Business;
  link: Link;
  guest: Guest;
  place: Place;
  timer: Object;
  code: string;
  page: string;
  country: string;
  reservationUuid: string;
  backlink = [];
  offline: boolean;
  overrideBacklink = [];
  send_cancel_disabled = false;
  posthogAllowed: boolean;

  backSteps = 1;

  userAgent: string;
  public start = true;

  taskSubj: BehaviorSubject<boolean> = new BehaviorSubject(null);
  taskObservable: Observable<boolean> = this.taskSubj.asObservable();

  moduleSubj: BehaviorSubject<Module> = new BehaviorSubject(this._module);
  module: Observable<Module> = this.moduleSubj.asObservable();
  device = this.deviceService.getDeviceInfo();

  viewSubj: BehaviorSubject<string> = new BehaviorSubject<string>('home');
  view: Observable<string> = this.viewSubj.asObservable();

  popupSubj: BehaviorSubject<boolean> = new BehaviorSubject(false);
  popup: Observable<boolean> = this.popupSubj.asObservable();

  getCode() {
    if (this.code?.length) {
      return this.code;
    }
    if (!this.business) {
      // check needed for the first call, there is no business present
      this.subscriptions.add(
        this.businessService.current_business.subscribe((_business) => {
          if (_business) {
            this.business = _business;
            this.code = this.business.code;
            this.country = this.business.address?.country?.toLowerCase() || 'de';
          }
        }),
      );
    } else {
      this.code = this.business.code;
    }
    if (!this.code) {
      const path = window.location.pathname.split('/');
      this.code = path[path.indexOf('g') + 1];
    }
    return this.code;
  }

  getModule(mod, visit = true, force = false): any {
    if (visit) {
      this.setModule(mod);
    }
    return new Promise((resolve, reject) => {
      if (this._module?.type === mod && !force) {
        this.taskSubj.next(this._module.task_module);
        this.moduleSubj.next(this._module);
        resolve(this._module);
      } else {
        this.moduleService.getModuleFromApi(mod, this.reservationUuid).subscribe(
          (success: any) => {
            const module = new Module(success.module);
            if (visit) {
              this._module = module;
              this.taskSubj.next(this._module.task_module);
              this.moduleSubj.next(this._module);
            }
            resolve(module);
          },
          (error) => {
            if (error.status === 423) {
              this.router.navigate(['/error', error.status]);
            } else if (error.status === 406) {
              resolve(new Module());
            } else if (error.status === 403 && visit) {
              resolve(new Module());
              this.navigate('home');
            }
            reject(error);
          },
        );
      }
    });
  }

  checkUpdate() {
    try {
      const scope = window.location.origin + window.location.pathname.split('/').slice(0, 3).join('/');
      navigator?.serviceWorker
        ?.getRegistrations()
        .then((regs) =>
          regs
            .find((reg) => reg.scope === scope)
            ?.update()
            .then(() => {
              this.messageSW({ type: 'GET_VERSION' })
                .then((version: string) => {
                  const currentVersion = window.localStorage?.getItem('swVersion');
                  if (currentVersion && parseInt(currentVersion, 10) < parseInt(version, 10)) {
                    this.log('oldVersion: ' + currentVersion + ' newVersion: ' + version, LogType.info);

                    interval(100)
                      .pipe(
                        takeWhile(() => this.translate('outdated.title') !== 'outdated.title' && this.translate('outdated.message') !== 'outdated.message'),
                        take(1),
                      )
                      .subscribe(() => {
                        const title = this.translate('outdated.title');
                        const message = this.translate('outdated.message');
                        if (!document.querySelector('#modal.update') && !document.querySelector('.ngx-toastr.toast-info')) {
                          this.blockScreen();
                          this.toast
                            .info(message, title, { enableHtml: true, disableTimeOut: true })
                            .onTap.pipe(take(1))
                            .subscribe(() => {
                              this.reload();
                            });
                        }
                      });
                  }
                  const newVersion = Math.max(parseInt(currentVersion, 10) || 0, parseInt(version, 10)).toString();
                  if (currentVersion !== newVersion) {
                    window.localStorage?.setItem('swVersion', newVersion);
                  }
                })
                .catch();
            })
            .catch(),
        )
        .catch();
    } catch (_e) {}
  }

  blockScreen() {
    this.taskSubj.next(true);
    interval(25)
      .pipe(
        takeWhile(() => document.getElementsByClassName('overlay-container')[0] !== null),
        take(1),
      )
      .subscribe(() => {
        const container = document.getElementsByClassName('overlay-container')[0];
        container.classList.add('curtain');
      });
  }

  messageSW(data: object) {
    return new Promise((resolve, reject) => {
      const messageChannel = new MessageChannel();
      messageChannel.port1.onmessage = (event: MessageEvent) => {
        resolve(event.data);
      };

      if (!navigator?.serviceWorker?.controller) {
        reject();
      }
      navigator.serviceWorker.controller.postMessage(data, [messageChannel.port2]);
    });
  }

  getNetworkInfo(mod) {
    return this.api.get('network/' + mod);
  }

  setModule(type: string) {
    this.moduleSubj.next(new Module({ type: type }));
  }

  setGuestName(name: string) {
    if (this.isJourney()) {
      this.guestService.setName(name);
    }
  }

  setEmail(email) {
    if (this.isJourney()) {
      this.guestService.setEmail(email);
    }
  }

  getEmail() {
    return this.isJourney() ? this.guestService.getEmail() : '';
  }

  getName() {
    return this.isJourney() ? this.guestService.getName() : '';
  }

  isSafari() {
    return this.device.browser === 'Safari';
  }

  isIos() {
    return this.device.os === 'iOS' || this.isIpad() || /iPhone/i.test(window?.navigator?.userAgent);
  }

  isMacOrIos() {
    return this.isIos() || this.device.os === 'Mac';
  }

  isIpad() {
    return this.device.deviceType === 'tablet' && this.device.os === 'Mac';
  }

  isMac() {
    return this.device.os === 'Mac' && this.device.deviceType !== 'tablet';
  }

  isAndroid() {
    return /android/.test(this.userAgent);
  }

  needPlace() {
    return !this.place.has_number;
  }

  isPreStay() {
    return this.place.journey === 'pre_stay' && this.place.name !== 'PWA';
  }

  isStay() {
    return this.place.journey === 'stay' && this.place.name !== 'PWA';
  }

  isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Windows Phone|Opera Mini/i.test(window.navigator.userAgent) || this.isIpad();
  }

  isTouch(): boolean {
    return document.querySelector('html').classList.contains('can_touchevents') || 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  }

  isThin() {
    return document.body.clientWidth <= 890;
  }

  isJourney(): boolean {
    if (this.place) {
      return Globals.JOURNEYS.indexOf(this.place.journey) !== -1;
    } else {
      return false;
    }
  }

  isPmsModule(): boolean {
    return this._module?.type?.startsWith('pms_') || this.router?.routerState?.snapshot?.url?.includes('pms_') || false;
  }

  locale() {
    return this.translateService.currentLang;
  }

  translate(text, param: any = null): string {
    if (text?.length) {
      return this.translateService.instant(text, param);
    } else {
      return '';
    }
  }

  sending() {
    const button = document.getElementById('send_form');
    if (button) {
      this.init_send_cancel_btn();
      this.send_cancel_disabled = true;
      button.innerText = this.translate('misc.sending');
    }
  }

  init_send_cancel_btn() {
    const button = document.getElementById('send_form');
    if (button) {
      this.send_cancel_disabled = false;
      if (button.dataset.label) {
        button.innerHTML = button.dataset.label;
      } else {
        button.dataset.label = button.innerHTML;
      }
    }
  }

  goBack() {
    if (this.overrideBacklink && this.overrideBacklink.length) {
      this.router.navigate(this.overrideBacklink);
      this.overrideBacklink = [];
    } else if (['api', 'link'].includes(this._module?.pretty_mode)) {
      this.navigate('home');
    } else {
      history.go(this.backSteps * -1);
      this.backSteps = 1;
    }
  }

  openUrl(url, target = '_blank') {
    if (url !== this.link?.url) {
      if (target === '_blank') {
        this.link = new Link({ url: url, url_target: target });
        this.externalLinkService.changeLink(this.link);
        this.router.navigate(['g', this.getCode(), 'link', 'external']);
      } else {
        // check if its an internal link
        if (/\b(http:|https:)/.test(url)) {
          this.link = new Link({ url: url, url_target: target });
          this.externalLinkService.changeLink(this.link);
          this.navigate('link');
        } else {
          this.navigate(url);
        }
      }
    }
  }

  navigate(target: string | Array<string>, fragment = null, query = null) {
    if (target === 'home') {
      return this.router.navigate(['/g/', this.getCode()]);
    }
    const path = typeof target === 'string' ? ['g', this.getCode(), target] : ['g', this.getCode(), ...target];
    this.router.navigate(path, { fragment: fragment, queryParams: query });
  }

  target_navigate(categories: Array<Object>, entries: Array<Object>, route: string, target: Target): void {
    if (target) {
      if (categories?.length === 1 && (!entries || !entries.length)) {
        this.overrideBacklink = ['/g', this.getCode()];
      }
      const path = target.target === 'category' ? route + '_category' : route;
      this.router.navigate(['g', this.getCode(), path, target.id], { replaceUrl: true });
    }
  }

  removeOverlayClass() {
    document.getElementById('container')?.classList?.remove('overlay');
  }

  removeQuery() {
    this.location.replaceState(location.pathname);
  }

  queryString(query): string {
    if (query) {
      const combined = Object.keys(query)
        .filter((key) => !['res', 'o'].includes(key))
        .map((key) => key + '=' + query[key])
        .join('&');
      return combined.length ? '?' + combined : '';
    } else {
      return '';
    }
  }

  disableLinks(offline) {
    const ext = Array.prototype.slice.call(<any>document.getElementsByClassName('ext-link'), 0);
    const social = Array.prototype.slice.call(<any>document.getElementsByClassName('social-button'), 0);
    for (let link of ext.concat(social)) {
      offline ? link?.classList?.add('disabled_state') : link?.classList?.remove('disabled_state');
    }
  }

  formError(errors) {
    if (!this.offline) {
      const list = <HTMLElement>document.createElement('ul');
      try {
        errors.error.error.forEach((value) => {
          const item = <HTMLElement>document.createElement('li');
          item.innerHTML = value;
          list.appendChild(item);
        });
      } catch (e) {
        const item = <HTMLElement>document.createElement('li');
        item.innerHTML = this.translate('validation.error');
        list.appendChild(item);
      }
      const form = document.getElementsByTagName('form')[0].getBoundingClientRect().top + window.scrollY - 80;
      window.scrollTo(0, form < 300 ? 0 : form);
      return list.outerHTML;
    } else {
      return null;
    }
  }

  clearAlert(force = false) {
    this.toast.clear();
    const box = document.getElementById('alert-box');
    if (box && (force || !box.hasAttribute('data-sticky'))) {
      box.remove();
    }
  }

  alert(type, message, title = null, config?) {
    const settings = { ...config, ...{ timeOut: type === 'success' ? 5000 : 8000, enableHtml: true } };
    switch (type) {
      case 'success':
        this.toast.success(message, title, settings);
        break;
      case 'warning':
        this.toast.warning(message, title, settings);
        break;
      case 'error':
        this.toast.error(message, title, settings);
        break;
      default:
        this.toast.show(message, title, config);
    }
  }

  testView() {
    return this.place?.test_view;
  }

  kiosk() {
    return this.place?.wizard;
  }

  terminalKiosk() {
    return this.place?.view === 'terminal';
  }

  hardwareTerminalKiosk() {
    return this.place?.view === 'hardware_terminal';
  }

  mobileKiosk() {
    return this.place?.view === 'mobile';
  }

  validPmsGuest() {
    return this.guest.validPmsGuest();
  }

  // Statistics

  createVisit() {
    if (this.guest) {
      this.guestService.stepsSubj.next(this.guest.steps++);
      if (!this.testView() && !this.kiosk()) {
        this.visitService.createVisit({
          module: this._module?.type,
          page: this.page,
          session: this.awayService.stop_timer(this.timer),
          created_at: this.offline ? new Date().toUTCString() : null,
        });
        this.page = null;
        this.timer = this.awayService.start_timer();
      }
    }
  }

  markAllControlsTouched(form: NgForm) {
    this.clearAlert();
    for (const field in form?.controls) {
      if (field) {
        form.controls[field].markAsTouched();
      }
    }
    if (form?.invalid) {
      const context = (<any>form).__ngContext__;
      for (let i = 0; i < context.length; i++) {
        if (context[i]?.nodeName === 'FORM') {
          const errorInputs: any = context[i].querySelectorAll('input.ng-invalid, ng-select.ng-invalid, international-phone-number.ng-invalid');
          if (errorInputs.length > 0) {
            const input = errorInputs[0];
            const bounding = input.getBoundingClientRect();
            const location = input.closest('.form-box')?.offsetTop || 0;
            if (bounding.top < 20 || bounding.bottom >= (window.innerHeight || document.documentElement.clientHeight)) {
              window.scrollTo(0, location > 100 ? location - 100 : 0);
            }
          }
        }
      }
    }
  }

  isInStandaloneMode() {
    const isInStandalone = window.matchMedia('(display-mode: standalone)').matches;
    if (isInStandalone && !this.offline) {
      try {
        navigator.serviceWorker.ready
          .then((serviceWorkerRegistration) => {
            return serviceWorkerRegistration.pushManager?.getSubscription();
          })
          .then(() => {
            navigator.serviceWorker.controller?.postMessage({ isInStandalone: isInStandalone });
          });
      } catch (e) {
        console.log(e);
      }
    }
    return isInStandalone;
  }

  cacheContent() {
    if (environment.production && !this.testView() && !this.kiosk() && this.isInStandaloneMode()) {
      of(true)
        .pipe(delay(15000))
        .subscribe(() => {
          let fetched = false;
          this.cacheSub = this.taskObservable.pipe(filter((task: boolean) => !task)).subscribe(() => {
            this.cacheSub?.unsubscribe();
            if (!fetched) {
              fetched = true;
              this.api.get('offline/paths').subscribe(
                (data: any) => {
                  if (Array.isArray(data.paths)) {
                    this.api.batchGet(data.paths);
                  }
                  if (Array.isArray(data.images)) {
                    this.cacheImages(data.images);
                  }
                },
                () => {},
              );
            }
          });
        });
    }
  }

  cacheImages(images: Array<string>) {
    if (images?.length) {
      const firstImage = images.shift();
      this.cacheImage(firstImage)
        .then(() => {
          this.cacheImages(images);
        })
        .catch();
    } else {
      document.getElementById('straivImgCache')?.remove();
    }
  }

  cacheImage(image) {
    return new Promise<void>((resolve) => {
      of(true)
        .pipe(delay(4000))
        .subscribe(() => {
          const container = document.getElementById('straivImgCache');
          if (container) {
            (<HTMLImageElement>container).src = image;
          }
          resolve();
        });
    });
  }

  validPushStates(): string[] {
    return this.isMacOrIos() ? ['granted', 'prompt'] : ['granted'];
  }

  today(daysToAdd: number = 0, date?: string) {
    return moment(date).add(daysToAdd, 'days').format(moment.HTML5_FMT.DATE);
  }

  versionCheck(module): Observable<Object> {
    return this.api.silentGet('version/' + module);
  }

  reload(stay = true) {
    try {
      this.businessService.reloadBusiness();
    } catch (_e) {}
    try {
      window.parent.caches
        .keys()
        .then((keys) => {
          keys.forEach((key) => {
            window.parent.caches.delete(key).then().catch();
          });
          this.reloadPage(stay);
        })
        .catch(() => {
          this.reloadPage(stay);
        });
    } catch (_e) {
      this.reloadPage(stay);
    }
  }

  reloadPage(stay = true) {
    try {
      if (stay) {
        location.reload();
      } else {
        const url = this.place?.remote_url || this.guestService?.place?.remote_url;
        url ? window.location.replace(url) : location.reload();
      }
    } catch (_e) {
      location.reload();
    }
  }

  log(msg: string, type: LogType = LogType.log, force = false) {
    this.logger.log(msg, type, force);
  }

  setStart(start) {
    this.start = start;
  }

  // Tracking Start

  startTracking() {
    this.businessService.current_business.pipe(filter(Boolean), take(1)).subscribe((business: Business) => {
      this.business = business;
      this.subscriptions.add(
        this.guestService.currentGuest.pipe(filter(Boolean), take(1)).subscribe((guest: Guest) => {
          this.guest = guest;
          this.enableBugsnag();
          this.enablePosthog();
          if (environment.datadog.enabled) {
            this.enableDatadog();
          }
        }),
      );
    });
  }

  enableBugsnag() {
    Bugsnag.addOnError((event) => {
      if (!this.trackingEnabled() || this.bugsnagHidden(event)) {
        return false;
      }
      this.bugsnagMeta(event);
    });
    Bugsnag.addOnSession((session) => {
      if (!this.trackingEnabled()) {
        return false;
      }
    });
  }

  enableDatadog() {
    this.subscriptions.add(
      this.guestService.cookie.pipe(filter(Boolean)).subscribe((setting: CookieSetting) => {
        if (setting.analytics) {
          datadogRum.init({
            site: 'datadoghq.eu',
            service: 'guestapp',
            applicationId: environment.datadog.applicationId,
            clientToken: environment.datadog.clientToken,
            env: environment.env,
            version: environment.version,
            defaultPrivacyLevel: 'mask-user-input',
            sessionSampleRate: 25,
            sessionReplaySampleRate: 0,
            trackUserInteractions: true,
            trackViewsManually: true,
            trackResources: true,
            trackLongTasks: true,
            allowedTracingUrls: [environment.api_url],
            traceSampleRate: 25,
          });

          datadogRum.setUser({
            business: this.business?.name,
            business_id: this.business?.id?.toString(),
            pms_system: this.business?.tech?.pms_system?.type || '',
            payment_provider: this.business?.tech?.payment_provider?.type || '',
            door_system: this.business?.tech?.door_system?.type || '',
          });

          this.updateDatadogView();
        }
      }),
    );
  }

  updateDatadogView() {
    let lastMod;
    this.subscriptions.add(
      this.view.pipe(filter(Boolean)).subscribe((mod: string) => {
        if (mod !== lastMod) {
          datadogRum.startView({ name: mod });
          lastMod = mod;
        }
      }),
    );
  }

  enablePosthog() {
    this.posthogToolbar();
    this.subscriptions.add(
      this.guestService.cookie.pipe(filter(Boolean)).subscribe((setting: CookieSetting) => {
        this.posthogAllowed = setting.analytics;
        this.posthogAllowed ? this.initPostHog() : posthog.opt_out_capturing();
      }),
    );
  }

  initPostHog() {
    if (environment.posthog.enabled && !posthog.has_opted_in_capturing()) {
      posthog.init(environment.posthog.apiKey, { api_host: environment.posthog.host, ip: false, disable_session_recording: true });
      posthog.identify(this.guest.id.toString());
      posthog.group('hotel', this.business.id?.toString() || this.business.code, {
        name: this.business.name,
        pms_system: this.business.tech.pms_system.type || '',
        door_system: this.business.tech.door_system.type || '',
        payment_provider: this.business.tech.payment_provider.type || '',
      });
      posthog.opt_in_capturing();
    }
  }

  posthogToolbar() {
    this.storageService.getItem('posthog_toolbar', 'admin').then((json) => {
      posthog.loadToolbar(JSON.parse(json.data));
    });
  }

  posthogSetCapture(data) {
    if (this.posthogEnabled()) {
      posthog.capture('cus_event', { $set: data });
    }
  }

  posthogStartRecord() {
    if (this.posthogEnabled()) {
      posthog.startSessionRecording();
    }
  }

  posthogEnabled(): boolean {
    return this.posthogAllowed && environment.posthog.enabled && posthog?.has_opted_in_capturing();
  }

  trackingEnabled(): boolean {
    const withoutPermission = (this.business?.address && !this.business.address.eu_member) || this.guest?.place?.wizard;
    return withoutPermission || this.guest?.cookies?.analytics || false;
  }

  bugsnagMeta(event) {
    event.setUser(this.guest?.id || 0);

    const path = event.context.split('/');
    event.context = path[path.indexOf('g') + 2] || 'home';

    const swVersion = window.localStorage?.getItem('swVersion');
    const time = parseInt(swVersion, 10);

    event.addMetadata('deployment', {
      time: !isNaN(time) ? new Date(time) : '',
      utc: !isNaN(time) ? new Date(time).toUTCString() : '',
    });
    event.addMetadata('business', {
      business: this.business?.name || '',
      pms_system: this.business?.tech?.pms_system?.type || '',
      payment_provider: this.business?.tech?.payment_provider?.type || '',
      door_system: this.business?.tech?.door_system?.type || '',
    });
    event.addMetadata('place', {
      id: this.place?.id || 0,
      name: this.place?.name || '',
      code: this.place?.cryptcode || '',
      view: this.place?.view || '',
      journey: this.place?.journey || '',
      test_view: this.place?.test_view || '',
    });
    event.addMetadata('user', {
      id: this.guest?.id || 0,
      locale: this.guest?.locale || '',
      code: this.guest?.code || '',
      reservation: this.reservationUuid || '',
    });
    event.addMetadata('module', {
      type: this._module?.type,
      mode: this._module?.pretty_mode,
    });

    event.groupingHash = this.bugsnagGrouping(event);

    const regex = new RegExp('zone.js|@angular|rxjs|polyfills|vendor');
    event.errors[0].stacktrace.forEach(function (frame) {
      frame.inProject = !regex.test(frame.file);
    });
  }

  bugsnagGrouping(event): string {
    const message = event.errors[0]?.errorMessage;
    if (message?.includes('SocketConnectionError')) {
      return 'socket';
    } else if (message?.includes('ChunkLoadError')) {
      return 'assets';
    }
  }

  bugsnagHidden(event): boolean {
    return event.errors.find((error) => ['HttpErrorResponse', 'FirebaseError'].includes(error.errorClass));
  }

  /**
   * Triggers blur events for all input controls within a form to ensure validation errors are shown.
   * This method marks each form control as touched and dispatches a blur event on their corresponding input elements.
   *
   * @param {NgForm} form - The Angular form instance containing form controls.
   */
  triggerBlurEvents(form: NgForm): void {
    if (!form) return; // Check if the form is defined
    for (const [key, control] of Object.entries(form.controls)) {
      let nativeElement = document.querySelector(`[name="${key}"]`) as HTMLElement;
      if (!nativeElement) {
        nativeElement = document.querySelector(`[ng-reflect-name="${key}"], [formControlName="${key}"]`) as HTMLElement;
        if (!nativeElement) continue; // Skip if element not found
      }
      // If the native element is found, locate the input element within it
      const inputElement = (nativeElement.querySelector('input') as HTMLInputElement) ? (nativeElement.querySelector('input') as HTMLInputElement) : (nativeElement.querySelector('textarea') as HTMLTextAreaElement) ? (nativeElement.querySelector('textarea') as HTMLTextAreaElement) : (nativeElement.querySelector('canvas') as HTMLCanvasElement);
      // If the input element exists, mark the control as touched and dispatch a blur event
      if (inputElement) {
        control.markAsTouched(); // Marks the control as touched to trigger validation errors
        inputElement.dispatchEvent(new Event('blur', { bubbles: true })); // Dispatches a blur event to simulate user interaction
      }
      // Handle custom select components
      if (nativeElement.classList.contains('sc-straiv-select-h')) {
        control.markAsTouched(); // Mark the control as touched to trigger validation errors
        // Find and trigger events for custom select components
        const dropdownHeader = nativeElement.querySelector('.dropdown-container') as HTMLElement;
        if (dropdownHeader) {
          // Trigger focus and blur events on the dropdown header
          dropdownHeader.dispatchEvent(new Event('focus', { bubbles: true }));
          dropdownHeader.dispatchEvent(new Event('blur', { bubbles: true }));
        }
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.cacheSub?.unsubscribe();
  }
}
