import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

export class Magazine {
  id_magazine_publication: number;
  id_magazine: number;
  date_of_publication: Date;
  page_max: number;
  magazine: string;
  publisher: string;
  magazine_categories: string;
  magazine_language: string;
  advertisement: boolean;
  cover_url: SafeUrl;
  file_url: string;

  constructor(raw_json: any, sanitizer: DomSanitizer) {
    this.id_magazine_publication = Number(raw_json.id_magazine_publication);
    this.id_magazine = Number(raw_json.id_magazine);
    this.date_of_publication = new Date(raw_json.date_of_publication);
    this.page_max = Number(raw_json.page_max);
    this.magazine = raw_json.magazine;
    this.publisher = raw_json.publisher;
    this.magazine_categories = raw_json.magazine_categories;
    this.magazine_language = raw_json.magazine_language;
    this.advertisement = raw_json.advertisement;
    this.cover_url = sanitizer.bypassSecurityTrustUrl('https://' + raw_json.cover_url.replace('https://', ''));
    this.file_url = raw_json.file_url;
  }
}
