import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { FormSelectRoutingModule } from './select-routing.module';
import { FormSelectComponent } from './select.component';
import { TypographyModule } from 'common/typography/typography.module';

@NgModule({
  imports: [CommonModule, FormSelectRoutingModule, NgSelectModule, FormsModule, TranslateModule, TypographyModule],
  exports: [FormSelectComponent],
  declarations: [FormSelectComponent],
})
export class FormSelectModule {}
