import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Globals } from 'base';
import { Business } from 'models/business';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { interval, of, Subscription } from 'rxjs';
import { delay, filter, take, takeWhile } from 'rxjs/operators';
import { BusinessService } from 'services/business.service';

@Component({
  selector: 'app-swiper',
  templateUrl: './swiper.component.html',
  styleUrls: ['./swiper.component.scss'],
})
export class SwiperComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  @Input() items: any[];
  @Input() lightbox = true;
  @Input() center = false;

  medias: any;
  slideIndex = 0;
  business: Business;

  autoplay = {
    delay: 7000,
    disableOnInteraction: false,
  };

  public slideConfig: SwiperConfigInterface = {
    loop: true,
    watchOverflow: true,
    navigation: true,
    grabCursor: true,
  };

  constructor(
    private sanitizer: DomSanitizer,
    private businessService: BusinessService,
    private globals: Globals,
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.businessService.current_business.pipe(filter(Boolean)).subscribe((business: Business) => {
        if (this.items?.length) {
          this.business = business;
          this.medias = JSON.parse(JSON.stringify(this.items));
          this.medias.forEach((item: any) => {
            if (item.media.type === 'image') {
              item.media.url = this.sanitizer.bypassSecurityTrustUrl(item.media.url);
            } else {
              item.media.url.mp4 = this.sanitizer.bypassSecurityTrustUrl(item.media.url.mp4);
              item.media.url.webm = this.sanitizer.bypassSecurityTrustUrl(item.media.url.webm);
            }
          });

          if (<any>this.medias.length === 1) {
            this.slideConfig.loop = false;
          } else {
            if (business.style.image_swiper) {
              this.autoplay.delay = Number(business.style.image_trigger * 1000);
              this.slideConfig.autoplay = this.autoplay;
            } else {
              this.slideConfig.autoplay = false;
            }
          }
          if (this.medias.length > 0 && this.medias[this.slideIndex].media.type === 'video') {
            interval(50)
              .pipe(
                takeWhile(() => document.getElementsByTagName('video') !== null),
                take(1),
              )
              .subscribe(() => {
                this.checkMedia(this.medias[this.slideIndex].media);
              });
          }
        }
      }),
    );
  }

  swiperInit() {
    if (<any>document.querySelector('.swiper-slide-active')) {
      const videoEl = <any>document.querySelector('.swiper-slide-active').getElementsByTagName('video')[0];
      this.toggleVideoClass(videoEl);
    }
  }

  setIndex(index: number) {
    if (this.medias && this.medias[this.slideIndex]) {
      const current = this.medias[this.slideIndex].media;
      const videoElements = document.querySelectorAll(`[data-id="${current.id}"]`);
      videoElements.forEach((videoEl) => {
        (<HTMLVideoElement>videoEl).pause();
      });

      this.slideIndex = index;

      this.subscriptions.add(
        of(true)
          .pipe(delay(200))
          .subscribe(() => {
            this.checkMedia(current);
          }),
      );
    }
  }

  checkMedia(current: any) {
    if (this.medias[this.slideIndex]) {
      const next = this.medias[this.slideIndex].media;

      if (next.type === 'video' || next.type !== current.type) {
        if (this.business.style.image_swiper) {
          this.slideConfig.autoplay = {
            delay: (this.medias[this.slideIndex].media.duration || this.business.style.image_trigger) * 1000 - 100,
            disableOnInteraction: false,
          };
        }

        if (next.settings.auto_start) {
          const self = this;
          const videoElements = document.querySelectorAll(`[data-id="${next.id}"]`);
          videoElements.forEach((videoEl) => {
            self.toggleVideoClass(videoEl);
            (<HTMLVideoElement>videoEl).play().catch(() => {});
          });
        }
      }
    }
  }

  toggleVideoClass(videoEl: any) {
    ['prev', 'next'].forEach((button) => {
      const activeSlide = document.querySelector('.swiper-slide-active');
      if (activeSlide) {
        const swiperButton = activeSlide.parentElement?.parentElement?.querySelector('.swiper-button-' + button);
        swiperButton?.classList?.toggle('video', !!videoEl);
      }
    });
  }

  openItem(media, event) {
    if (event?.target && !event.target.className.includes('swiper-button') && media && media.type === 'image') {
      if (this.lightbox && !(this.globals.isMobile() || this.globals.isThin())) {
        this.openModal();
        this.showSlides(this.slideIndex);
      }
    }
  }

  openModal() {
    const swiperLightboxElement = document.getElementById('swiperLightbox');
    if (swiperLightboxElement) {
      swiperLightboxElement.style.display = 'block';
    }
  }

  // Close the Modal
  closeModal() {
    const swiperLightboxElement = document.getElementById('swiperLightbox');
    if (swiperLightboxElement) {
      swiperLightboxElement.style.display = 'none';
    }
    this.slideIndex = 0;
  }

  // Next/previous controls
  plusSlides(n) {
    this.showSlides((this.slideIndex += n));
  }

  // Thumbnail image controls
  currentSlide(n) {
    this.showSlides((this.slideIndex = n));
  }

  showSlides(n) {
    const slides = document.querySelectorAll('.imgSlides');
    const dots = document.querySelectorAll('.demo');

    if (n === slides.length || n < 0) {
      this.slideIndex = 0;
    }

    for (let i = 0; i < slides.length; i++) {
      (<any>slides[i]).style.display = 'none';
    }
    for (let i = 0; i < dots.length; i++) {
      dots[i].classList.remove('active');
    }
    (<any>slides[this.slideIndex]).style.display = 'block';
    dots[this.slideIndex].classList.add('active');
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
