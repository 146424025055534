<div class="info-text" *ngIf="type && camera.loadingFinished">{{ 'booking_data.passport_image.description' | translate }}</div>

<div class="main-container">
  <div class="camera-wrapper">
    <app-camera #camera [imageUpload]="imageUpload" [recaptureTrigger$]="recaptureTrigger$" (captured)="captured = $event" (success)="onPhotoSuccess($event)" [hasInternalCapture]="false" (abort)="onAbort()"></app-camera>
  </div>
  <svg viewBox="0 0 144 160" fill="none">
    <path d="M35.9982 33.9912H18C18 103.581 74.4084 160 143.998 160V141.999C84.3472 141.999 35.9982 93.6422 35.9982 33.9912Z" fill="#F6F7F9" />
    <path d="M143.999 -37.9991V-56C94.2896 -56 54 -15.7104 54 33.9912C54 83.7007 94.2896 124.001 143.999 124.001V106C104.231 106 72.0009 73.7619 72.0009 33.9938C72.0009 -5.76102 104.231 -37.9991 143.999 -37.9991Z" fill="#F6F7F9" />
    <path d="M144 -20.001C114.17 -20.001 90 4.16929 90 33.9911C90 63.8208 114.17 87.999 144 87.999V33.999L198 33.9911C198 4.16929 173.832 -20.001 144 -20.001Z" fill="#F6F7F9" />
  </svg>
</div>

<div class="btn-grid">
  <ng-container *ngIf="!captured; else finishButtons">
    <straiv-button *ngIf="camera.loadingFinished" name="passport-camera-button" left-icon="fas fa-camera" [leftLabel]="'service.check_in.documents.add_photo' | translate" (buttonClick)="camera.capture()"> </straiv-button>
  </ng-container>
  <ng-template #finishButtons>
    <straiv-button [rightLabel]="'service.check_in.terminal.webcam.repeat' | translate" right-icon="fa-solid fa-refresh" variant="outline" value="button" (buttonClick)="reCapture()"></straiv-button>
    <straiv-button [rightLabel]="'service.check_in.terminal.webcam.use' | translate" right-icon="fa-solid fa-check" variant="flat" value="button" (buttonClick)="onSuccess()"></straiv-button>
  </ng-template>
</div>
