import { Component, HostListener, OnInit, OnDestroy, ViewContainerRef } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ApiService } from 'api_service';
import { Globals } from 'base';
import { FooterService } from 'common/footer/footer.service';
import { LanguageService } from 'common/language/language.service';
import { ModalService } from 'common/modal/modal.service';
import { environment } from 'environments/environment';
import { DomModal } from 'models/dom_modal';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { A2hsService } from 'services/a2hs.service';
import { AwayService } from 'services/away.service';
import { StorageService } from 'services/storage.service';

@Component({
  selector: 'app-root',
  template: ` <router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit, OnDestroy {
  subscriptions = new Subscription();
  color: string;
  page_closed = false;
  reservation = undefined;
  serviceNames = <HTMLElement>document.createElement('ul');
  classList = document.querySelector('html')['className'];
  supportServiceWorker = this.classList.indexOf('can_serviceworker') !== -1 && navigator.serviceWorker;

  @HostListener('window:unload', ['$event'])
  unloadHandler() {
    this.close_page();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander() {
    this.close_page();
  }

  constructor(
    private router: Router,
    private awayService: AwayService,
    private globals: Globals,
    private api: ApiService,
    private languageService: LanguageService,
    private footerService: FooterService,
    private a2hsService: A2hsService,
    private modalService: ModalService,
    public viewRef: ViewContainerRef,
    private storageService: StorageService,
  ) {
    if (this.supportServiceWorker && navigator?.serviceWorker && environment.production) {
      navigator.serviceWorker.ready
        .then(() => {
          navigator.serviceWorker.addEventListener('message', (msg) => {
            if (msg?.data?.action) {
              switch (msg.data.action) {
                case 'NOTIFICATION_CLICK':
                  if (msg.data.message_id) {
                    this.api.put('pwa/push_subscription/' + msg.data.message_id, {}).subscribe(
                      () => {
                        if (msg.data.navigate_url) {
                          this.router.navigateByUrl(msg.data.navigate_url);
                        }
                      },
                      () => {},
                    );
                  } else if (msg.data.navigate_url) {
                    this.router.navigateByUrl(msg.data.navigate_url);
                  }
                  break;
                case 'SYNC_FINISH':
                  if (msg.data.requests.length) {
                    this.showFailedServices(msg.data.requests);
                  }
                  break;
                default:
                  break;
              }
            }
          });
        })
        .catch(() => {});
    }
    this.languageService.initLanguages();
  }

  ngOnInit() {
    this.api.codeSubj.next(this.globals.getCode());
    if (window.location.href.includes('/g/')) {
      this.globals.startTracking();
    }

    // Scroll to top on route change
    this.router.events.pipe(filter((evt) => evt instanceof NavigationStart || evt instanceof NavigationEnd)).subscribe((evt) => {
      if (evt instanceof NavigationStart) {
        if (this.router.url.includes('/g/') && this.router.navigated) {
          this.globals.createVisit();
        } else {
          this.globals.timer = this.awayService.start_timer();
        }
      }
      if (evt instanceof NavigationEnd) {
        if (this.modalService.isShowing && evt.url.indexOf('file') !== -1) {
          this.modalService.close();
        }
        this.globals.clearAlert();
        this.footerService.openSubj.next(false);
        this.languageService.checkLanguage();
        if (this.router.url.includes('/g/')) {
          this.updateDatadogView();
          this.preventWizards(evt);
        }
        window.scrollTo(0, 0);
        this.globals.checkUpdate();
      }
    });
    this.homescreen();
  }

  private updateDatadogView() {
    let name;
    try {
      name = this.router.url.split('/')[3];
    } catch (_e) {
      name = 'home';
    }
    this.globals.viewSubj.next(name);
  }

  private close_page() {
    if (!this.page_closed) {
      if (this.router.url.indexOf('/g') !== -1 && this.router.navigated) {
        this.globals.createVisit();
      }
      this.page_closed = true;
    }
  }

  private homescreen(): void {
    window.addEventListener('beforeinstallprompt', (event) => {
      this.a2hsService.a2hsEvent = event;
      event.preventDefault();
      this.a2hsService.setBeforeInstall(true);
    });

    window.addEventListener('appinstalled', (event) => {
      event.preventDefault();
      this.a2hsService.installableSubject.next(false);
      this.a2hsService.closeModal();
      this.a2hsService.setBeforeInstall(false);
      this.api.post('pwa/homescreen', {}).subscribe();
      this.storageService.setItem('pwa_installed', { pwa_installed: true, code: this.globals.getCode() });
    });
  }

  private showFailedServices(referrers: string[], index: number = 0) {
    if (index === referrers.length) {
      const body = new DomModal();
      body.title = 'bgSync.title';
      body.description = this.globals.translate('bgSync.description', { serviceNames: this.serviceNames.outerHTML });
      this.modalService.open(body, true);
      this.serviceNames = <HTMLElement>document.createElement('ul');
    } else {
      const name = referrers[index].split('/');
      this.globals
        .getModule(name[name.length - 1], false)
        .then((mod) => {
          if (mod) {
            const item = <HTMLElement>document.createElement('li');
            item.innerHTML = mod.name || '';
            this.serviceNames.appendChild(item);
          }
          this.showFailedServices(referrers, index + 1);
        })
        .catch(() => {});
    }
  }

  preventWizards(evt) {
    if (this.globals.kiosk() && !evt.url.includes('error')) {
      const guest = this.globals.guest;
      const regex = /\/g\/[a-z]{4}\/pms_kiosk\/([a-z0-9]{6,})?/;
      const match = evt.url.match(regex);
      if (!match) {
        this.router.navigate(['/g/', guest.code, 'pms_kiosk', guest.place.cryptcode]);
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
