<form #form="ngForm" class="redesign">
  <fieldset class="pin-preview-container">
    <ng-container *ngIf="terminal">
      <h2 class="title main-text">
        {{ 'misc.room_number' | translate }} : <span class="door-number">{{ door?.name }}</span>
      </h2>
      <p class="text" translate>pms_door.pin_codes.name</p>
      <div class="divider"></div>
    </ng-container>

    <ng-container *ngIf="!loadingDoors; else loading">
      <table class="doors-table">
        <thead>
          <tr>
            <th [class.has-border]="!hasComfortCode" translate>pms_door.pin_codes.activation_code</th>
            <th *ngIf="hasComfortCode" class="has-border" translate>pms_door.pin_codes.access_code</th>
            <th translate>pms_door.pin_codes.door</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let pin of pinCodes | keyvalue">
            <td class="pin-code">
              <span>{{ pin.key }}</span>
            </td>
            <td class="pin-code" *ngIf="hasComfortCode">
              <span>{{ pin.value.comfort_code }}</span>
            </td>
            <td class="flex-cell">
              <span *ngFor="let door of pin.value.doorData">{{ door.name }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>
    <div class="button-grid" *ngIf="terminal && !hideFinishButton">
      <button class="btn-filled" (click)="close()" translate>misc.finish</button>
    </div>
  </fieldset>
</form>

<ng-template #noCodes>
  <div class="alert alert-danger" translate>pms_door.pin_codes.error</div>
</ng-template>

<ng-template #loading>
  <app-loader [small]="true"></app-loader>
</ng-template>
