import { Component, OnInit, Input, Output, EventEmitter, HostListener, ChangeDetectorRef, OnChanges } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { PmsCiCoService } from 'cico_service';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectComponent,
    },
  ],
  styleUrls: ['../input-styles.scss', './select.component.scss'],
})
export class SelectComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {
  @Input() items: any[];
  @Input('ngModel') selItem: any = null;
  @Output('ngModelChange') selItemChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() description: string;
  @Input() required: boolean;
  @Input() triggerKeyboard: boolean = false;
  @Input() name: string;
  @Input() tooltip: string;
  @Input() placeholder: string;
  @Input() showIcons: boolean = false;
  @Input() disabled: boolean;
  @Input() submited: boolean;
  @Input() errorMsg: string = 'validation.required';

  inputId: string;
  dropdownOpen: boolean = false;
  dropdownPosition: 'above' | 'below';
  touched: boolean = false;
  displayelement: any = null;
  keyboardSize = 0;
  list: any[] = [];
  prevValue: any = null;
  searchVal: string;

  constructor(
    private _cdr: ChangeDetectorRef,
    private cicoService: PmsCiCoService,
  ) {}

  ngOnChanges(): void {
    this.placeholder = this.placeholder ? this.placeholder : this.name;

    if (this.selItem || this.selItem === 0) {
      this.displayelement = this.prevValue = this.findByValue(this.selItem);
    } else {
      this.displayelement = this.prevValue = undefined;
    }
  }

  ngOnInit(): void {
    this.list = this.items;
    this.placeholder = this.placeholder ? this.placeholder : this.name;
    this.inputId = `${this.name}_${Math.random().toString(36).substring(2)}`;

    if (this.selItem || this.selItem === 0) {
      this.displayelement = this.findByValue(this.selItem);
      this.displayelement ? (this.prevValue = this.displayelement) : this.changeSelItem(null);
    }

    this.keyboardSize = window.screen.width <= 640 ? 450 : window.screen.width >= 1320 ? 400 : 380;
  }

  openDropdown() {
    if (this.disabled) {
      return;
    }
    this.calculateDropdownPosition();
    this.dropdownOpen = true;
    this.prevValue = this.displayelement;
    this.displayelement = null;
    if (this.prevValue) {
      this.focusElement('item-' + this.list.indexOf(this.selItem));
    }
  }

  closeDropdown() {
    if (this.displayelement == null) {
      this.displayelement = this.prevValue;
    }

    this.list = this.items;
    this.dropdownOpen = false;
    this.touched = true;
    this.focusElement('select-wrapper');
    this.searchVal = null;
  }

  handleInputKeyup(event: KeyboardEvent) {
    if (event.key !== 'ArrowUp' && event.key !== 'ArrowDown') {
      this.applyFilter((event.target as HTMLInputElement).value);
    }
  }

  applyFilter(text: string) {
    if (text.length > 0) {
      this.list = this.cicoService.orderCountriesInSearch(this.items, text);
    } else {
      this.list = this.items;
      this._cdr.detectChanges();
    }
  }

  changeSelItem(item: any) {
    this.selItem = item;
    if (item) {
      this.displayelement = this.prevValue = this.findByValue(item.value);
      this.selItemChange.emit(item.value);
    }
  }

  calculateDropdownPosition() {
    this.dropdownPosition = this.getAvailableDropdownSpace() < this.keyboardSize ? 'above' : 'below';
  }

  getAvailableDropdownSpace(): number {
    const el: HTMLElement = document.getElementById('select-wrapper' + this.inputId);
    const container = document.getElementsByClassName('grid-container')[0];

    if (!container) {
      return 0;
    } else {
      const containerHeight = (<any>container).offsetHeight;
      return containerHeight - (el.offsetTop + el.offsetHeight);
    }
  }

  isValid(): boolean {
    return (this.submited && this.selItem !== null && this.selItem !== undefined) || this.selItem;
  }

  isInvalid(): boolean {
    return this.submited && (this.selItem === null || this.selItem === undefined) && this.required;
  }

  selectPrevItem() {
    const currIndex: number = this.list.indexOf(this.prevValue);
    const newIndex: number = currIndex - 1 >= 0 ? currIndex - 1 : currIndex;

    this.changeSelItem(this.list[newIndex]);
    this.focusElement('item-' + newIndex);
  }

  selectNextItem() {
    const currentIndex: number = this.list.indexOf(this.prevValue);
    const newIndex: number = currentIndex + 1 <= this.list.length - 1 ? currentIndex + 1 : currentIndex;

    this.changeSelItem(this.list[newIndex]);
    this.focusElement('item-' + newIndex);
  }

  focusElement(id: string) {
    const target: HTMLElement = document.getElementById(id);
    if (target) {
      target.focus();
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeydownEvent(event: KeyboardEvent) {
    if (event.key == 'ArrowUp' || event.key == 'ArrowDown') {
      this.handleKeydown(event);
    }
  }

  handleKeydown(event: KeyboardEvent) {
    if (this.dropdownOpen) {
      if (['ArrowUp', 'ArrowDown', 'Space'].indexOf(event.code) > -1) {
        event.preventDefault();
      }
      switch (event.key) {
        case 'ArrowUp':
          this.selectPrevItem();
          break;
        case 'ArrowDown':
          this.selectNextItem();
          break;
      }
      this.focusElement('item-' + this.list.indexOf(this.selItem));
    }
  }

  findByValue(value: any) {
    return this.list.find((element) => element.value?.toString()?.toLowerCase() === value?.toString()?.toLowerCase());
  }

  registerOnChange(fn: any): void {}

  registerOnTouched(fn: any): void {}

  writeValue(obj: any): void {}

  validate(control: AbstractControl): ValidationErrors | null {
    return null;
  }
}
