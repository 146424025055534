<h3 *ngIf="headline" translate>pms_door.pin_codes.name</h3>

<div class="info_box info" *ngIf="error">
  <i class="fas fa-info-circle"></i>
  <b [innerHTML]="error?.title"></b>
  <p [innerHTML]="error?.desc"></p>
</div>

<ng-container *ngIf="doors; else loading">
  <div class="menu" *ngIf="doors.length">
    <div class="head">
      <div></div>
      <div translate>{{ names.start_code }}</div>
      <div translate *ngIf="sag">{{ names.comfort_code }}</div>
    </div>
    <ng-container *ngFor="let door of doors | filter: searchText">
      <a href="#">
        <div>{{ door.name }}</div>
        <div>
          <span>{{ names.start_code | translate }}:</span>{{ door.start_code }}
        </div>
        <div *ngIf="sag">
          <span>{{ names.comfort_code | translate }}:</span>{{ door.comfort_code }}
        </div>
      </a>
    </ng-container>
  </div>
</ng-container>

<ng-template #loading>
  <app-loader [small]="true" *ngIf="!error"></app-loader>
</ng-template>
