<ng-container *ngIf="business">
  <p [innerHTML]="business.getAddressBlock(true)"></p>
  <ul class="icon-list">
    <li>
      <i class="far fa-envelope"></i>
      <a href="mailto:{{ business.contact.email }}" translate>{{ business.contact.email.length <= 20 ? business.contact.email : 'misc.send_email' }}</a>
    </li>
    <li>
      <i class="fas fa-phone"></i>
      <a target="_blank" href="tel:{{ business.contact.phone }}">{{ business.contact.phone }}</a>
    </li>
    <li>
      <i class="fas fa-home"></i>
      <a [class.ext-link]="business.contact.url_target === '_blank'" translate (click)="globals.openUrl(business.contact.url, business.contact.url_target)">misc.open_website</a>
    </li>
  </ul>
</ng-container>
