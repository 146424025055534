import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BackgroundModule } from '../../common/background/background.module';
import { PoiListAllModule } from './list/all/all.module';
import { PoiListParentModule } from './list/parent/parent.module';
import { PoiRoutingModule } from './poi-routing.module';
import { PoiComponent } from './poi.component';

@NgModule({
  imports: [CommonModule, PoiRoutingModule, PoiListParentModule, PoiListAllModule, BackgroundModule, TranslateModule],
  exports: [PoiComponent],
  declarations: [PoiComponent],
})
export class PoiModule {}
