import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BooleanValueAccessor } from './boolean-value-accessor';
import { DatepickerValueAccessor } from './datepicker-value-accessor';

@NgModule({
  imports: [CommonModule],
  declarations: [BooleanValueAccessor, DatepickerValueAccessor],
  exports: [BooleanValueAccessor, DatepickerValueAccessor],
})
export class ValueAccessorModule {}
