import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Field } from 'models/field';
import { Globals } from 'base';

@Component({
  selector: 'app-form-phone-field',
  templateUrl: './phone_field.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FormPhoneFieldComponent implements OnInit {
  default: string;

  @Input() model: any;
  @Input() key: any;
  @Input() field: Field;
  @Input() defaultCountry: string;

  constructor(public globals: Globals) {}

  ngOnInit() {
    this.replace00();
    this.setDefault();
  }

  replace00() {
    if (this.model[this.key]?.startsWith('00')) {
      this.model[this.key] = this.model[this.key].replace(/^.{2}/g, '+');
    }
  }

  setDefault() {
    if (!this.model[this.key]) {
      this.default = this.defaultCountry !== 'defined' ? this.defaultCountry || this.globals.country : '';
    }
  }
}
