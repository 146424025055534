import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FilterPipeModule } from 'pipes/filter.pipe.module';
import { WidgetAdvertModule } from 'widgets/advert/advert.module';
import { AttachmentsModule } from 'shared/layout/attachments/attachments.module';
import { ModuleTitleModule } from 'shared/layout/module_title.module';
import { SharedSwiperModule } from 'shared/layout/swiper/swiper.module';
import { ServiceAllDirective } from 'shared/service/all.directive';
import { PoiListAllRoutingModule } from './all-routing.module';
import { PoiListAllComponent } from './all.component';

@NgModule({
  imports: [CommonModule, PoiListAllRoutingModule, AttachmentsModule, SharedSwiperModule, WidgetAdvertModule, FormsModule, TranslateModule, FilterPipeModule, ModuleTitleModule],
  exports: [PoiListAllComponent],
  declarations: [PoiListAllComponent],
  providers: [ServiceAllDirective],
})
export class PoiListAllModule {}
