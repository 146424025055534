import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderModule } from 'common/loader/loader.module';
import { ModuleTitleModule } from '../../shared/layout/module_title.module';
import { SharedSwiperModule } from '../../shared/layout/swiper/swiper.module';
import { PmsCiCoBaseDirective } from '../base/cico_base.directive';
import { PmsFoliosModule } from '../shared/folios/folios.module';
import { PmsLoginModule } from '../shared/login/login.module';
import { PmsOverlayModule } from '../shared/overlay/overlay.module';
import { PmsReservationRoutingModule } from './reservation-routing.module';
import { PmsReservationComponent } from './reservation.component';
import { SwiperModule } from 'swiper/angular';

@NgModule({
  imports: [CommonModule, PmsReservationRoutingModule, TranslateModule, SwiperModule, SharedSwiperModule, ModuleTitleModule, PmsLoginModule, PmsFoliosModule, LoaderModule, PmsOverlayModule],
  exports: [PmsReservationComponent],
  declarations: [PmsReservationComponent],
  providers: [PmsCiCoBaseDirective],
})
export class PmsReservationModule {}
