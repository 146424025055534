import { Component, OnDestroy, OnInit } from '@angular/core';
import { Globals } from 'base';
import { Business } from 'models/business';
import { Module } from 'models/module';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { BusinessService } from 'services/business.service';
import { GuestService } from 'services/guest.service';
import { OfflineService } from 'services/offline.service';
import { NavigationService } from 'services/websocket/navigation.service';
import { LanguageService } from '../language/language.service';
import { MenuService } from './menu.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
})
export class MenuComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  frontpage: any;
  navigation: any;
  highlights: any;
  test_items: any;
  offline_modules: string[];
  offline: boolean;
  business: Business;
  module: Module;

  constructor(
    public globals: Globals,
    private menu: MenuService,
    private guestService: GuestService,
    private businessService: BusinessService,
    private offlineService: OfflineService,
    private navigationService: NavigationService,
    public languageService: LanguageService,
  ) {}

  ngOnInit() {
    this.menu.getOfflineModules().subscribe((success) => {
      this.offline_modules = success;
    });

    this.subscriptions.add(
      this.businessService.current_business.subscribe((business) => {
        this.business = business;
        this.updateMainNavigation();
      }),
    );

    this.subscriptions.add(
      this.menu.update.subscribe(() => {
        this.updateMainNavigation();
      }),
    );

    this.subscriptions.add(
      this.businessService.currentLocale.subscribe(() => {
        this.updateMainNavigation();
        if (this.globals.testView()) {
          this.menu.getMenu('view').subscribe((success: any) => {
            this.test_items = success.navigation;
          });
        }
      }),
    );

    this.subscriptions.add(
      this.offlineService.offline.subscribe((offline) => {
        this.offline = offline;
      }),
    );

    this.subscriptions.add(
      this.navigationService.navigation.subscribe((data: any) => {
        this.setNavigation(data);
      }),
    );

    this.subscriptions.add(
      this.globals.module.pipe(delay(1)).subscribe((module: Module) => {
        this.module = module;
      }),
    );
  }

  updateMainNavigation() {
    this.menu.getMenu('main').subscribe((success) => {
      this.setNavigation(success);
    });
  }

  setNavigation(data: any) {
    // update list for external links in safari browser
    if (this.globals.isSafari()) {
      for (const entry of data['navigation'] as any) {
        if (entry.navigation.pretty_mode === 'link') {
          entry.navigation.type = 'ext';
          entry.navigation.url_target = '_blank';
        }
      }
    }
    this.frontpage = data.frontpage;
    this.navigation = this.elements(data, false);
    this.highlights = this.elements(data, true);
  }

  openExternalLink(mod) {
    this.create_visit(mod.path);
    this.globals.openUrl(mod.url);
  }

  elements(data, highlight): Array<any> {
    return data.navigation.map((element: any) => element.navigation).filter((nav) => nav.highlight === highlight);
  }

  overviewItems(mod) {
    return this.frontpage.map((element: any) => element.frontpage).filter((item) => item.mod === mod);
  }

  loadingIcons() {
    return ['home', 'book', 'hotel', 'map-signs', 'history', 'bell', 'leaf', 'newspaper', 'user-friends', 'taxi'];
  }

  create_visit(mod) {
    this.globals.createVisit();
    this.globals.setModule(mod);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
