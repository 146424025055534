import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { AddressType } from 'app/_enums/pms/enums';
import { Globals } from 'base';
import { PmsCiCoService } from 'cico_service';
import { EventConstants } from 'global_enums';
import { Address } from 'models/address';
import { Tech } from 'models/tech';
import { GenericData } from 'pms_models/generic_data';
import { PmsFolio } from 'pms_models/pms_folio';
import { Subscription } from 'rxjs';
import { EventAggregatorService } from 'services/events/event-aggregator.service';

@Component({
  selector: 'app-pms-invoice-address-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class PmsInvoiceAddressFormComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('addressForm', { static: false }) addressForm: NgForm;

  protected readonly AddressType = AddressType;
  protected subscriptions: Subscription = new Subscription();

  @Input() folio: PmsFolio;
  @Input() data: GenericData;
  @Input() staticAddress: boolean;
  @Input() number: number;
  @Input() newDesign: boolean;
  @Input() withoutFolio: boolean;
  @Input() deviatingBillingAdr: boolean;
  @Input() submited: boolean;
  @Output() dataEvent = new EventEmitter<boolean>();

  usedAddress: Address;
  guestAddress: Address;
  selectedAddress: Address;
  fields: any;
  fieldsInactive: boolean;
  pmsSystem: Tech;
  states = [];
  maxAdresses = 4;
  reference: any;
  combined: boolean;
  address_lock: boolean;

  isEmpty: boolean = false;
  htmlContent: string = '';

  isDialogOpen: boolean = false;
  highlightedIndex: number;
  isEditingAddress: boolean;
  editDeleteIndex: number;

  constructor(
    public cicoService: PmsCiCoService,
    public globals: Globals,
    protected readonly eventService: EventAggregatorService,
  ) {}

  ngOnChanges(): void {
    if (this.cicoService.addresses.length) {
      this.fillAddresses();
    }
  }

  ngOnInit() {
    this.fields = this.deviatingBillingAdr ? this.cicoService.field_for('primary_guest').subField('invoice_address') : this.cicoService.field_for('invoice_address');
    this.fieldsInactive = !this.fields?.active;

    this.guestAddress = this.data.incident.reservation.primary_guest.address;
    this.combined = this.data.module.settings.invoice_address === 'combined';
    this.pmsSystem = this.data.business?.tech?.pms_system;
    this.collectAddresses();
    this.address_lock = this.folio?.address_lock || this.suite8Lock();
    this.fillAddresses();

    this.usedAddress = this.folio?.address?.address ? this.folio.address : this.data?.incident?.reservation?.address?.address ? this.data.incident.reservation.address : this.guestAddress;

    if (this.deviatingBillingAdr) {
      this.data.incident.reservation.address.manual = true;
      this.usedAddress = this.data.incident.reservation.address;
    } else {
      if (this.folio && !this.folio?.address?.address) {
        this.folio.address = this.usedAddress;
      }
    }

    this.usedAddress.address_type = this.usedAddress.company?.length > 0 ? AddressType.company : AddressType.personal;
    this.selectedAddress = this.usedAddress;

    this.updateAddressInfo();

    this.subscriptions.add(
      this.eventService.getEvent(EventConstants.invoiceAddressUpdate).subscribe((value: string) => {
        if (value === 'delete') {
          const address = this.cicoService.addresses.find((address) => address === this.usedAddress);
          if (!address) {
            this.usedAddress = this.cicoService.addresses[0];
            this.selectedAddress = this.usedAddress;
            const index = this.cicoService.addresses.findIndex((address) => address === this.usedAddress);
            this.highlightedIndex = index;
          }
        }
        this.updateAddressInfo();
      }),
    );
  }

  isStaticAddress(): boolean {
    return this.staticAddress || this.folio?.existing_payments || (this.noPayment() && this.isReservationModule());
  }

  noPayment(): boolean {
    return this.folio?.payment === 'no_payment';
  }

  folioPaid(): boolean {
    return this.folio?.check === 'paid';
  }

  suite8Lock(): boolean {
    return this.data.business.usePms('suite8') && this.folioPaid() && this.folio.positions.length > 0;
  }

  isReservationModule(): boolean {
    return this.data?.isReservationModule();
  }

  updateAddressType() {
    if (this.usedAddress.address_type === AddressType.personal) {
      this.usedAddress.company = null;
    }
    this.updateAddress(this.usedAddress);
  }

  updateAddress(usedAddress) {
    if (this.folio) {
      this.folio.address = usedAddress;
    } else {
      this.data.incident.reservation.address = usedAddress;
    }
  }

  collectAddresses() {
    this.fixAdresses();
    this.reference = this.folio ? this.data.folioAddresses.find((fol) => fol.number === this.folio.number) : this.data.incident.reservation;
    const empty = this.reference?.address?.isEmpty();
    const same = this.reference?.address?.isSame(this.guestAddress) || false;

    if (!empty) {
      const address = same ? this.guestAddress : this.reference.address;
      this.checkAndPushAddress(address);
    }
    if (empty || !same) {
      if (!this.isReservationModule()) {
        if (this.guestAddress.isEmpty() && this.combined) {
          this.guestAddress.manual = true;
        }
        if (!this.folio?.address_lock || !this.cicoService.addresses?.length) {
          this.checkAndPushAddress(this.guestAddress);
        }
      } else {
        if (!this.cicoService.addresses[0]?.isSame(this.reference.address)) {
          if (this.reference.address.address) {
            this.checkAndPushAddress(this.reference.address);
          }
        }
      }
    }

    if (this.pmsSystem?.onPremise) {
      const ids = this.cicoService.addresses.map((address) => address.internal_id);
      const others = this.data.incident.reservation.allFolios.filter((folio) => folio.viewable && !folio.address_lock && folio.address.internal_id?.length && !ids.includes(folio.address.internal_id)).map((folio) => folio.address);
      this.cicoService.addresses = this.cicoService.addresses.concat(others);
    }

    if (!this.deviatingBillingAdr && !this.data.incident.reservation.address?.isEmpty() && !this.isStaticAddress() && this.cicoService.deviatingBillingAddress) {
      if (this.checkAndPushAddress(this.data.incident.reservation.address)) {
        this.addressChooser(this.cicoService.addresses.length - 1);
      }
    }

    if (this.isStaticAddress() && this.deviatingBillingAdr) {
      this.usedAddress = this.cicoService.addresses[0];
    }
  }

  // Method to check for duplicates and push if not present
  private checkAndPushAddress(address: Address): boolean {
    // Push the address only if it is not a duplicate
    if (!this.cicoService.addresses.some((existingAddress) => existingAddress.isSame(address))) {
      this.cicoService.addresses.push(address);
      return true;
    }
    return false;
  }

  fixAdresses() {
    this.data.folioAddresses.forEach((folioAdress) => {
      folioAdress.address = folioAdress.address instanceof Address ? folioAdress.address : new Address(folioAdress.address);
    });
  }

  fillAddresses() {
    this.fillMissingNames();
    if (this.cicoService.addresses.length > 0 && !this.cicoService.addresses[0].valid()) {
      this.cicoService.addresses[0].manual = true;
    }
  }

  private addressChooser(index: number) {
    this.highlightedIndex = index;
    this.usedAddress = this.cicoService.addresses[index];

    if (!this.cicoService.addresses[0].address) {
      this.usedAddress.first_name = this.cicoService.addresses[0].first_name;
      this.usedAddress.last_name = this.cicoService.addresses[0].last_name;
      this.usedAddress.company = this.cicoService.addresses[0].company;
    }

    if (this.folio) {
      this.folio.address = this.usedAddress;
    }
    this.cicoService.deviatingBillingAddress = this.usedAddress === this.data.incident.reservation.address || null;

    this.updateAddressInfo();
  }

  fillMissingNames() {
    const primaryGuest = this.data?.incident?.reservation?.primary_guest;
    if (this.folio && !this.folio.address?.company?.length && this.folio.address?.address?.length) {
      if (!this.folio.address?.first_name?.length) {
        if (!this.folio.address) {
          this.folio.address = new Address(null);
        }
        this.folio.address.first_name = primaryGuest?.first_name;
      }
      if (!this.folio.address?.last_name?.length) {
        this.folio.address.last_name = primaryGuest?.last_name;
      }
    }
  }

  editInvoiceAdr() {
    this.isDialogOpen = true;
    const index = this.cicoService.addresses.findIndex((address) => address === this.usedAddress);
    this.highlightedIndex = index;

    if (!this.folio?.address_lock) {
      this.cicoService.setFolioAddressStep('select');
    }
  }

  closeDialog() {
    this.cicoService.setFolioAddressStep('folio');
    this.isDialogOpen = false;
    this.deviatingBillingAdr = false;
    this.usedAddress = this.selectedAddress;
  }

  onClickAddNewAddress() {
    this.deviatingBillingAdr = true;
    this.selectedAddress = this.usedAddress;
    this.usedAddress = new Address({ manual: true, address_type: AddressType.personal });
    this.cicoService.setFolioAddressStep('create');
  }

  onSelectionOfAddress(index: number) {
    this.highlightedIndex = index;
    this.isDialogOpen = false;
    this.addressChooser(index);
    this.selectedAddress = this.usedAddress;
    this.editDeleteIndex = null;
  }

  onClickAddAddress() {
    if (this.addressForm && !this.addressForm.valid) {
      this.globals.triggerBlurEvents(this.addressForm);
      return;
    }
    this.deviatingBillingAdr = false;
    this.cicoService.setFolioAddressStep('folio');
    let index = 0;
    if (!this.isEditingAddress) {
      this.cicoService.addresses.push(this.usedAddress);
      index = this.cicoService.addresses.length - 1;
    } else {
      index = this.editDeleteIndex;
      this.eventService.getEvent(EventConstants.invoiceAddressUpdate).publish('edit');
    }
    this.isEditingAddress = false;
    this.onSelectionOfAddress(index);
  }

  onClickCancel() {
    this.deviatingBillingAdr = false;
    this.cicoService.setFolioAddressStep('select');
    this.usedAddress = this.selectedAddress;
  }

  onClickEditManualAddress(index: number) {
    this.editDeleteIndex = index;
    this.isEditingAddress = true;
    this.deviatingBillingAdr = true;
    this.addressChooser(index);
    this.cicoService.setFolioAddressStep('create');
  }

  onClickDeleteManualAddress(index: number) {
    //Stencil does not automatically track changes to complex objects, Instead of mutating the array directly, create a new copy and assign it back to the property.
    this.cicoService.addresses = [...this.cicoService.addresses.slice(0, index), ...this.cicoService.addresses.slice(index + 1)];
    this.eventService.getEvent(EventConstants.invoiceAddressUpdate).publish('delete');
  }

  handleCountryChange(updateAddress: any) {
    this.updateAddress(updateAddress);
  }

  private updateAddressInfo() {
    this.isEmpty = this.usedAddress.folioAddressInfoEmpty();
    this.htmlContent = this.isEmpty ? 'address.deviating_invoice_address' : this.usedAddress.htmlBlock(true);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
