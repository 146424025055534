<ng-template #roomUpgradeProduct>
  <app-upsell-gallery *ngIf="kind === 'room_category'" [upsellItems]="product.offers" [currentPrice]="product.gross" (itemSelected)="onRoomOfferSelectionChange($event)"></app-upsell-gallery>
</ng-template>

<ng-container *ngIf="this.kind === 'breakfast'; else roomUpgradeProduct">
  <div class="segment-button product-button">
    <straiv-segment-button ngDefaultControl id="product" name="product" [leftText]="'cus.skip_breakfast' | translate" [rightText]="'cus.order_breakfast' | translate" left-value="skip_breakfast" right-value="order_breakfast" [value]="cicoService.cusBreakfastToBook ? 'order_breakfast' : 'skip_breakfast'" (buttonClick)="onBreakfastSelectionChange($event)"> </straiv-segment-button>
  </div>
  <div class="product-info">
    <straiv-reservation-card is-upgrade-card="true" size="medium" [imageUrl]="images[0].media.url" [heading]="product.name" [description]="product.description" [roomAmount]="product.gross * nightsCount * totalGuestCount | currency: product.currency" amount-text="" min-height="185px" selectable="true" [active]="isBreakfastSelected" viewDetailsButtonText="{{ 'misc.more' | translate }}" [perNightCharge]="'cus.unit_price_cus' | translate: { number: product.gross | currency: product.currency }"> </straiv-reservation-card>
    <div class="reservation-container" *ngIf="reservationCusInfo && product.kind === 'room_category'">
      <div class="room-category">
        <span class="reservation-title smaller-font-size" translate>misc.category_name</span> <span class="info smaller-font-size">{{ reservationCusInfo?.unitName }}</span>
      </div>
      <div class="date-info">
        <div class="arrival">
          <span class="reservation-title smaller-font-size" translate>booking_data.arrival</span> <span class="info smaller-font-size">{{ reservationCusInfo?.arrival }}</span>
        </div>
        <div class="departure">
          <span class="reservation-title smaller-font-size" translate>booking_data.departure</span> <span class="info smaller-font-size">{{ reservationCusInfo?.departure }}</span>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<straiv-dialog [open]="isConfirmationDialogOpen" (dialogClose)="closeConfirmDialog()">
  <straiv-dialog-header dialogTitle="{{ 'cus.confirmation_dialog_title' | translate }}"></straiv-dialog-header>
  <ng-container>
    <p>{{ 'cus.confirmation_dialog_description' | translate }}</p>
    <straiv-dialog-actions>
      <straiv-button (buttonClick)="closeConfirmDialog()" leftLabel="{{ 'misc.back' | translate }}" variant="outline"> </straiv-button>
      <straiv-button [loading]="isBooking" right-icon="fa-regular fa-chevron-right" (buttonClick)="onOrderConfirmed()" leftLabel="{{ 'cus.confirmation_dialog_button' | translate }}"> </straiv-button>
    </straiv-dialog-actions>
  </ng-container>
</straiv-dialog>
