import { Component, ComponentRef, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Globals } from 'base';
import { Business } from 'models/business';
import { of, Subscription } from 'rxjs';
import { delay, filter } from 'rxjs/operators';
import { BusinessService } from 'services/business.service';
import { OfflineService } from 'services/offline.service';
import { InitComponent } from '../init/init.component';
import { LanguageService } from '../language/language.service';
import { SearchComponent } from '../search/search.component';
import { PmsCiCoService } from 'cico_service';
import { UserActionType } from 'pms_enums';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  business: Business;
  component: ComponentRef<SearchComponent>;
  multilingual = false;
  logo_upright = false;
  offline: boolean;
  isHomeModule = false;

  constructor(
    private businessService: BusinessService,
    public globals: Globals,
    private cicoService: PmsCiCoService,
    public translate: TranslateService,
    public languageService: LanguageService,
    private init: InitComponent,
    private offlineService: OfflineService,
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.businessService.current_business.pipe(filter(Boolean)).subscribe((business: Business) => {
        this.business = business;
        this.logo_upright = this.business.logo_upright;
        this.multilingual = this.business.multilingual;
      }),
    );

    this.subscriptions.add(
      this.offlineService.offline.subscribe((offline) => {
        this.offline = offline;
      }),
    );

    this.subscriptions.add(
      this.globals.module.subscribe((mod) => {
        of(true)
          .pipe(delay(1))
          .subscribe(() => {
            this.isHomeModule = mod.type === 'home';
          });
      }),
    );
  }

  showSearch() {
    if (this.component) {
      this.component.destroy();
      this.component = null;
    } else {
      this.component = this.init.search.createComponent(SearchComponent);
      this.component.instance._ref = this.component;
    }
  }

  resetProcess() {
    this.cicoService.navigate(UserActionType.cancel);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
