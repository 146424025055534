import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PmsCiCoService } from 'cico_service';
import { CusInfo } from 'models/pms/pms_reservation';
import { ConfirmName, CusProductTypes, Step } from 'pms_enums';
import { CurrencyPipe } from '@angular/common';
import { Globals } from 'base';
import { EventAggregatorService } from 'services/events/event-aggregator.service';
import { EventConstants } from 'global_enums';
import { Subscription } from 'rxjs';
import { BookingService, RoomBookingRequest, ServiceBookingRequest } from '../cus-booking.service';
import { TravelInfoSubSteps } from 'models/pms/stepper';
import { StepperService } from 'services/pms/stepper.service';
import { PmsService } from 'modules/pms/pms.service';
import { switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit, OnDestroy {
  @Input() index = 0;
  @Input() product: any;
  @Input() kind: CusProductTypes;
  @Input() totalGuestCount: number;
  @Input() nightsCount: number;
  @Input() reservationCusInfo: CusInfo;

  images: any[] = [];
  previewSrc: any;
  showPreview: boolean = false;
  perNightCharge: string;
  totalCUSPrize: string;
  isBreakfastSelected: boolean = false;
  isConfirmationDialogOpen: boolean = false;

  subscriptions: Subscription = new Subscription();
  isBooking: boolean;

  constructor(
    public globals: Globals,
    public cicoService: PmsCiCoService,
    private currencyPipe: CurrencyPipe,
    private eventService: EventAggregatorService,
    private bookingService: BookingService,
    private stepperService: StepperService,
    private pmsService: PmsService,
  ) {}

  ngOnInit(): void {
    this.images = this.product?.medias || [];

    if (this.cicoService.getCurrentStepName() === TravelInfoSubSteps.reservation) {
      this.setNextButtonForReservationStep();
    }
    if (this.cicoService.getCurrentStepName() === TravelInfoSubSteps.cusBreakfast) {
      this.setNextButtonForBreakfastStep();
    }

    this.subscriptions.add(
      this.eventService.getEvent(this.kind === CusProductTypes.breakfast ? EventConstants.openBreakfastBookingConfirmation : EventConstants.openRoomUpgradeConfirmation).subscribe(() => {
        this.isConfirmationDialogOpen = true;
      }),
    );
  }

  setNextButtonForReservationStep() {
    if (this.cicoService.cusRoomupgradeToBook) {
      this.cicoService.totalGrossService = this.transformAmount(this.totalPriceRoomUpgrade(), this.cicoService.cusRoomupgradeToBook.product.currency);
      this.cicoService.confirmNameSubj.next(ConfirmName.add_product);
    } else {
      this.cicoService.confirmNameSubj.next(ConfirmName.next);
    }
  }

  setNextButtonForBreakfastStep() {
    if (this.cicoService.cusBreakfastToBook) {
      this.cicoService.totalGrossService = this.transformAmount(this.totalPriceBreakfast(), this.cicoService.cusBreakfastToBook.product.currency);
      this.cicoService.confirmNameSubj.next(ConfirmName.add_product);
    } else {
      this.cicoService.confirmNameSubj.next(ConfirmName.next);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  nextImg() {
    const lastElement = this.images.pop();
    this.images.unshift(lastElement);
  }

  prevImg() {
    const firstElement = this.images.shift();
    this.images.push(firstElement);
  }

  photoClick(photo: any) {
    this.showPreview = true;
    this.previewSrc = photo;
  }

  onBreakfastSelectionChange(event?) {
    this.isBreakfastSelected = event?.detail === 'order_breakfast';
    if (this.isBreakfastSelected) {
      this.cicoService.cusBreakfastToBook = { product: this.product, ruleId: 123 };
    } else {
      this.cicoService.cusBreakfastToBook = null;
    }
    this.setNextButtonForBreakfastStep();
  }

  onRoomOfferSelectionChange(selectedItem: any | null) {
    if (selectedItem) {
      this.cicoService.cusRoomupgradeToBook = { product: this.product, ruleId: 123, offer: selectedItem };
    } else {
      this.cicoService.cusRoomupgradeToBook = null;
    }
    this.setNextButtonForReservationStep();
  }

  transformAmount(amount: number, currencyCode: string): string | null {
    return this.currencyPipe.transform(amount, currencyCode, 'symbol', '1.2-2');
  }

  totalPriceRoomUpgrade() {
    const chosenProduct = this.cicoService.cusRoomupgradeToBook.offer;
    const basePrice = chosenProduct.difference;
    return basePrice * this.nightsCount;
  }

  totalPriceBreakfast() {
    return this.product.gross * this.totalGuestCount * this.nightsCount;
  }

  closeConfirmDialog() {
    this.isConfirmationDialogOpen = false;
  }

  continueWithoutExtras() {
    this.stepperService.nextStep();
  }

  onOrderConfirmed() {
    if (this.isBooking) {
      return;
    }
    if (this.kind === CusProductTypes.breakfast) {
      this.bookBreakfast();
    }
    if (this.kind === CusProductTypes.roomCategory) {
      this.bookRoomUpgrade();
    }
  }

  bookBreakfast() {
    this.isBooking = true; // control the loading state of the button
    const request: ServiceBookingRequest = {
      id: this.product.id,
      rule_id: (this.cicoService.cusProducts.breakfast as any).id,
      origin: 'service',
    };

    this.subscriptions.add(
      this.bookingService.bookService(request, this.product.name).subscribe(
        () => {
          this.cicoService.removeSubstep(Step.reservation, TravelInfoSubSteps.cusBreakfast);
          this.cicoService.cusBreakfastToBook = null;
          this.setNextButtonForBreakfastStep();
          this.stepperService.nextStep();
          this.isBooking = false;
        },
        () => {
          this.isBooking = false;
        },
      ),
    );
  }

  bookRoomUpgrade() {
    const request: RoomBookingRequest = {
      id: this.product.id,
      rule_id: (this.cicoService.cusProducts.room as any).id,
      origin: 'service',
      offer_id: this.cicoService.cusRoomupgradeToBook.offer.id,
    };

    this.isBooking = true;
    this.bookingService
      .bookRoom(request, this.cicoService.cusRoomupgradeToBook.offer.name)
      .pipe(
        switchMap(() => this.pmsService.get_reservation(this.globals.reservationUuid, 'check_in')),
        take(1),
      )
      .subscribe(
        (res: any) => {
          this.cicoService.cusRoomupgradeToBook = null;
          this.isBooking = false;
          this.isConfirmationDialogOpen = false;
          this.cicoService.updateBookedUnit(res.reservation.unit, res.reservation.medias, res.reservation.services);
          this.setNextButtonForReservationStep();
          this.cicoService.hasBookedRoomupgrade = true;
        },
        () => {
          this.isBooking = false;
        },
      );
  }
}
