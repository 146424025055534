import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgStringPipesModule } from 'ngx-pipes';
import { FilterPipeModule } from 'pipes/filter.pipe.module';
import { WidgetAdvertModule } from 'widgets/advert/advert.module';
import { BasketWidgetModule } from '../../../basket/widget/widget.module';
import { AttachmentsModule } from 'shared/layout/attachments/attachments.module';
import { BacklinkModule } from 'shared/layout/backlink/backlink.module';
import { SharedSwiperModule } from 'shared/layout/swiper/swiper.module';
import { ServiceParentDirective } from 'shared/service/parent.directive';
import { MenuListParentRoutingModule } from './parent-routing.module';
import { MenuListParentComponent } from './parent.component';

@NgModule({
  imports: [CommonModule, MenuListParentRoutingModule, SharedSwiperModule, BasketWidgetModule, WidgetAdvertModule, AttachmentsModule, TranslateModule, FormsModule, FilterPipeModule, NgStringPipesModule, BacklinkModule],
  exports: [MenuListParentComponent],
  declarations: [MenuListParentComponent],
  providers: [ServiceParentDirective],
})
export class MenuListParentModule {}
