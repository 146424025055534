import { Injectable } from '@angular/core';
import { ApiService } from 'api_service';

@Injectable({ providedIn: 'root' })
export class SelectionService {
  constructor(private api: ApiService) {}

  getSelection(kind): any {
    return new Promise((resolve, reject) => {
      this.api.get('selection/' + kind).subscribe(
        (success: any) => {
          resolve(
            success.entries.map((entry) => {
              return {
                value: entry.id,
                name: entry.title,
                description: entry.description,
                category: entry.category_name,
                text_required: entry.text_required,
                price: entry.price,
                price_hidden: entry.price_hidden,
                orderable: entry.orderable,
                quantity: entry.quantity,
                medias: entry.medias,
              };
            }),
          );
        },
        () => {
          reject();
        },
      );
    });
  }

  optionChanged(value, select, component): any {
    const findOption = new Promise(function (resolve, _reject) {
      resolve(
        select.find(function (obj: Object) {
          return obj['value'] === value;
        }),
      );
    });
    findOption
      .then(function (item) {
        component.required = item['text_required'];
        component.price = item['price'];
        component.description = item['description'];
        component.orderable = item['orderable'] && component.module.orderable;
        component.quantitable = item['quantity'];
        component.has_price = !item['price_hidden'];
        component.entryMedias = item['medias'];
        component.rightSide = component.medias.length > 0 || component.entryMedias.length > 0 || (component.orderable && component.has_price);
        component.set_total_price();
      })
      .catch(() => {});
  }
}
