import { CdkDrag, CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'api_service';
import { Globals } from 'base';
import { Business } from 'models/business';
import { Module } from 'models/module';
import { of, Subject, Subscription } from 'rxjs';
import { debounceTime, delay } from 'rxjs/operators';
import { StorageService } from 'services/storage.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-pms-door-api',
  templateUrl: './api.component.html',
  styleUrls: ['./api.component.scss'],
})
export class PmsDoorApiComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  door_categories: any;
  number: number;
  error: any;
  only_fav_doors = false;
  headline: boolean;

  favIds: any = [];
  favorites: any = [];
  available: any = [];
  hotel_favs: any = [];
  private alert = new Subject<string>();

  movingAlert: string;

  @Input() business: Business;
  @Input() module: Module;
  @Input() searchText: string;
  @ViewChild('doors', { static: true }) container: ElementRef;

  constructor(
    private globals: Globals,
    private api: ApiService,
    private storageService: StorageService,
  ) {}

  ngOnInit() {
    this.showAlert();
    this.getDoors();
    this.headline = this.module.settings.modes.length > 1;
    this.alert.subscribe((message) => {
      this.movingAlert = message;
      window.scrollTo(0, 0);
    });
    this.alert.pipe(debounceTime(3000)).subscribe(() => {
      this.showAlert(false);
      this.movingAlert = '';
    });
  }

  changeMovingAlert(door) {
    if (this.isHotelFav(door)) {
      this.alert.next(this.globals.translate('pms_door.favorites_alert'));
    }
  }

  openDoor(ident, thumb) {
    if (ident) {
      thumb.parent().addClass('opening');
      if (this.isAssaAbloy()) {
        this.subscriptions.add(
          of(true)
            .pipe(delay(1500))
            .subscribe(() => {
              thumb.css('left', 0).parent().removeClass('opening').addClass('success');
            }),
        );
      }
      this.api.post('door/api', { ident: ident }).subscribe(
        () => {
          thumb.parent().removeClass('opening').addClass('success');
          this.subscriptions.add(
            of(true)
              .pipe(delay(this.isAssaAbloy() ? 1500 : 3500))
              .subscribe(() => {
                thumb.css('left', 0).parent().removeClass('success');
              }),
          );
        },
        () => {
          thumb.parent().removeClass('opening');
          $(thumb).animate({ left: 0 }, 2000);
          this.globals.alert('error', this.module.settings?.ui_messages?.open_forbidden?.content, null, { enableHtml: true });
          this.getDoors();
        },
      );
    }
  }

  showAlert(show = true) {
    const element = document.getElementById('alert');
    if (element) {
      element.style.display = show ? 'block' : 'none';
    }
  }

  getDoors() {
    this.api.get('door/list/api').subscribe(
      (response: any) => {
        this.loadFavIds().then((favorites) => {
          this.favIds = favorites?.favlist || [];
          this.hotel_favs = favorites?.hotellist || [];
          this.door_categories = response?.reservation?.doors;
          this.push_hotel_favs();
          this.selectAvailable();
          this.initDrags();
        });
      },
      (error) => {
        this.door_categories = null;
        this.error = error.error;
      },
    );
  }

  loadFavIds(): any {
    return this.storageService
      .getItem('current_doors', this.globals.code)
      .then((data: any) => {
        return data;
      })
      .catch(() => {});
  }

  push_hotel_favs(): any {
    this.hotel_favs = [];
    this.door_categories.forEach((group) => {
      group.entries
        .filter((door) => door.favorite)
        .forEach((door) => {
          if (!this.favIds.includes(door.ident)) {
            this.favIds.push(door.ident);
          }
          if (!this.favorites.includes(door)) {
            this.favorites.push(door);
          }
          if (!this.hotel_favs.includes(door.ident)) {
            this.hotel_favs.push(door.ident);
          }
        });
      group.entries
        .filter((door) => !door.favorite && !this.available.includes(door))
        .forEach((door) => {
          this.available.push(door);
        });
    });
    if (!this.available.length && this.hotel_favs.length === this.favorites.length) {
      this.only_fav_doors = true;
    }
    this.selectFavorites();
    this.save();
    this.initDrags();
  }

  selectFavorites(): any {
    this.favorites = [];
    this.favIds.forEach((ident) => {
      this.door_categories.forEach((group) => {
        const found = group.entries.find((door) => door.ident === ident);
        if (found) {
          this.favorites.push(found);
        }
      });
    });
  }

  selectAvailable() {
    this.selectFavorites();
    this.available = [];
    this.door_categories.forEach((group) => {
      group.entries.forEach((entry) => {
        if (!this.favorites.includes(entry)) {
          this.available.push(entry);
        }
      });
    });
  }

  toggleToFav(door, index) {
    if (this.isFav(door)) {
      this.favIds = this.favIds.filter((obj) => obj !== door.ident);
    } else {
      this.favIds.splice(index, 0, door.ident);
    }
    this.selectFavorites();
    this.save();
    this.initDrags();
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer.data === this.favorites && event.container.data === this.favorites) {
      this.moveInFavorites(event);
    } else if (event.previousContainer !== event.container) {
      this.moveToFavorites(event);
    }
  }

  moveInFavorites(event) {
    const index_firstDoor = this.favIds.findIndex((item) => item === this.favorites[event.previousIndex].ident);
    const index_secondDoor = this.favIds.findIndex((item) => item === this.favorites[event.currentIndex].ident);
    this.arraymove(this.favIds, index_firstDoor, index_secondDoor);
    this.selectFavorites();
    this.save();
    this.initDrags();
  }

  moveToFavorites(event) {
    const door = event.previousContainer.data[event.previousIndex];
    if (this.isHotelFav(door)) {
      this.showAlert();
    } else {
      this.toggleToFav(door, event.currentIndex);
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
      this.showAlert(false);
      this.selectAvailable();
    }
  }

  arraymove(arr, fromIndex, toIndex) {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  hasDoors(category: any): boolean {
    return category.entries.filter((door) => !this.isFav(door))?.length > 0;
  }

  isHotelFav(door): boolean {
    return this.hotel_favs?.includes(door.ident);
  }

  evenPredicate(item: CdkDrag<any>) {
    return !this.hotel_favs?.includes(item.data.ident);
  }

  isFav(door): boolean {
    return this.favIds.includes(door.ident);
  }

  isAssaAbloy(): boolean {
    return this.business.tech?.door_system?.type === 'assa_abloy';
  }

  save() {
    const ls_favorites = { code: this.globals.code, favlist: this.favIds, hotellist: this.hotel_favs };
    this.storageService.setItem('current_doors', ls_favorites);
  }

  initDrags(): void {
    const self = this;
    $(function () {
      $('.thumb').each(function () {
        const thumb = $(this);

        thumb.on({
          mousedown: function () {
            $(document).on({ mousemove: move, mouseup: up });
          },
          touchstart: function () {
            $(document).on({ touchmove: move, touchend: up });
          },
        });

        const up = function () {
          const drag = $('.thumb.moving');
          drag.removeClass('moving');
          const pixelLeft = drag?.css('left') ? drag.css('left').split('px')[0] : '0';
          const pos = parseInt(pixelLeft, 10) + drag?.width();
          if (pos <= drag.parent().width() - 1) {
            thumb.css('left', 0);
          } else {
            self.openDoor(drag.data('id'), thumb);
          }
          $(document).off({ touchstart: move, touchmove: move, mousemove: move, touchend: up, mouseup: up });
        };

        const move = function (e) {
          const drag = $('.thumb.moving');
          thumb.addClass('moving');
          const pagex = e.pageX || e.targetTouches[0].pageX;
          const maxX = drag.parent().width() - thumb.width();
          let x = pagex - thumb.parent().offset().left - thumb.width() / 2;
          x = x < 0 ? 0 : x > maxX ? maxX : x;
          thumb.css('left', x);
        };
      });
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
