<ng-container *ngIf="fieldsLoaded">
  <div *ngIf="isShowIdnowSection">
    <app-pms-ci-authentication class="animation-out" [data]="data" [guest]="guest" (dismiss)="dismissed()" (done)="checkIdnow(true)"></app-pms-ci-authentication>
  </div>

  <ng-container *ngIf="!isShowIdnowSection">
    <div id="reservation-form" class="animation-out">
      <ng-container *ngIf="cicoService.subStepForGuest == 'primary_guest' || cicoService.subStepForGuest == 'other_guest'">
        <div class="general-grid">
          <div class="two-col-grid">
            <straiv-input *ngIf="fields.first_name" ngDefaultControl name="first_name" [labelText]="fields.first_name.name" [placeholder]="'placeholder.input_text' | translate" [required]="fields.first_name.required" [errorMessage]="'validation.first_name' | translate" [(ngModel)]="guest.first_name" (valueChange)="setAddressName()"> </straiv-input>

            <straiv-input *ngIf="fields.last_name" ngDefaultControl name="last_name" [labelText]="fields.last_name.name" [placeholder]="'placeholder.input_text' | translate" [required]="fields.last_name.required" [errorMessage]="'validation.last_name' | translate" [(ngModel)]="guest.last_name" (valueChange)="setAddressName()"> </straiv-input>
          </div>

          <div class="group2grid">
            <straiv-datepicker *ngIf="fields.date_of_birth" name="date_of_birth" [labelText]="fields.date_of_birth.name" [locale]="locale" [localeLng]="locale" [required]="fields.date_of_birth.required" [errorMessage]="datePickerValidationMessages | translate" [validation]="fields['date_of_birth'].validation" [(ngModel)]="guest.date_of_birth" (dateBlur)="setDatePickerValidationMessage($event, 'date_of_birth')"> </straiv-datepicker>

            <straiv-select *ngIf="fields.nationality && data.countriesNat?.length && checkNationality" ngDefaultControl name="nationality" [labelText]="fields.nationality.name" [items]="data.countriesNat | json" [value]="guest.nationality" [required]="fields.nationality.required" [errorMessage]="'validation.nationality' | translate" [placeholder]="'address.state' | translate" [(ngModel)]="guest.nationality" (selectedEvent)="onSelectedNationality($event)"> </straiv-select>
          </div>

          <div class="group2grid" *ngIf="fields.place_of_birth">
            <straiv-input ngDefaultControl name="place_of_birth" [labelText]="fields.place_of_birth.name" [placeholder]="fields.place_of_birth.name" [required]="fields.place_of_birth.required" [errorMessage]="'validation.place_of_birth' | translate" [(ngModel)]="guest.place_of_birth"> </straiv-input>
          </div>

          <div class="group3grid" *ngIf="fields.phone && phoneCountry">
            <app-phone-input ngDefaultControl name="phone" [isNewComponent]="true" [description]="'misc.phone_number' | translate" [countries]="data?.countries" [(phoneNumber)]="guest.phone" [required]="fields.phone.required" [countryFromAdr]="guest.address?.country"> </app-phone-input>
          </div>

          <div class="group3grid" *ngIf="fields.email">
            <straiv-input ngDefaultControl name="email" type="email" [labelText]="fields.email.name" [placeholder]="fields.email.name" [required]="fields.email.required" [errorMessage]="'validation.email_wrong_format' | translate" [(ngModel)]="guest.email"> </straiv-input>
            <straiv-input *ngIf="fields.email.setting('double_check')" ngDefaultControl name="confirm_email" type="email" required="true" auto-select="true" allow-paste="false" [labelText]="'misc.confirm_email_address' | translate" [placeholder]="'misc.confirm_email_address' | translate" [errorMessage]="confirmEmailErrorMessage | translate" [value]="data.confirmEmail" [confirmEmail]="guest.email" [(ngModel)]="data.confirmEmail" (inputBlur)="validateConfirm($event)"> </straiv-input>
          </div>

          <straiv-input *ngIf="fields.profession" ngDefaultControl name="profession" [labelText]="fields.profession.name" [placeholder]="fields.profession.name" [required]="fields.profession.required" [errorMessage]="'validation.required' | translate" [(ngModel)]="data.profession"> </straiv-input>
        </div>
      </ng-container>

      <div class="guest-address" *ngIf="cicoService.subStepForGuest === 'private_address' || cicoService.subStepForGuest === 'other_guest'">
        <ng-container *ngIf="fields.private_address">
          <div class="apply-address" *ngIf="!primaryGuest">
            <straiv-button data-cy="apply-address" name="apply-address-button" [leftLabel]="'address.apply' | translate" left-icon="fas fa-circle-arrow-down" variant="flat" (buttonClick)="applyAddress()"> </straiv-button>
          </div>

          <pms-address ngDefaultControl [guestAddress]="guest.address" [fieldType]="'private_address'" [field]="fields.private_address" [data]="data"></pms-address>

          <ng-container *ngIf="primaryGuest && deviatingBillingAdr">
            <div class="billing-address">
              <straiv-checkbox ngDefaultControl name="use_billing_address" [labelText]="'address.deviating_invoice_address' | translate" [(ngModel)]="cicoService.deviatingBillingAddress" (valueChange)="onChangeBillingAdCallback($event)"> </straiv-checkbox>
            </div>

            <app-pms-invoice-address-form *ngIf="cicoService.deviatingBillingAddress" class="animation-out" [data]="data" [deviatingBillingAdr]="deviatingBillingAdr" [newDesign]="true" [staticAddress]="false" [withoutFolio]="true"></app-pms-invoice-address-form>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="this.primaryGuest">
          <div class="segment-button segment-button-sl" *ngIf="travelPurposeField">
            <straiv-label [header]="'service.check_in.general.travel_reasons' | translate" [description]="'service.check_in.general.travel_reasons_description' | translate"></straiv-label>
            <straiv-segment-button ngDefaultControl id="travel-toggle" name="travel-toggle" [leftText]="'booking_data.travel_purpose.private_trip' | translate" [rightText]="'booking_data.travel_purpose.business_trip' | translate" [value]="data.incident.reservation.travel_purpose" left-value="private_trip" right-value="business_trip" [disabled]="travelPurposeLock" (buttonClick)="reasonForTravelToggle($event)"> </straiv-segment-button>
          </div>

          <ng-container *ngIf="fields.car_licence">
            <div class="parking-flexbox">
              <straiv-label *ngIf="transportation" [header]="'booking_data.transportation.title' | translate" [description]="'booking_data.transportation.sub_title' | translate"></straiv-label>
              <div class="car_licence">
                <straiv-radio-group
                  *ngIf="transportation"
                  name="my_radio_group"
                  [options]="[
                    { label: 'booking_data.transportation.noPrivate' | translate, value: 'no' },
                    { label: 'booking_data.transportation.yesPrivate' | translate, value: 'yes' },
                  ]"
                  [value]="data.incident.reservation.car_licence ? 'yes' : 'no'"
                  (valueChange)="transportationType($event.detail)"
                >
                </straiv-radio-group>

                <straiv-input *ngIf="!transportation || data.incident.transportation === 'private'" ngDefaultControl name="car_licence" [labelText]="fields.car_licence.name" [placeholder]="fields.car_licence.name" [required]="fields.car_licence.required" [errorMessage]="'validation.car_licence' | translate" [disabled]="carLicenceDisabled" [(ngModel)]="data.incident.reservation.car_licence" (valueChange)="disableCarLicence($event)"> </straiv-input>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <div *ngIf="cicoService.subStepForGuest === 'identification_guest' || cicoService.subStepForGuest === 'other_guest'">
      <app-passport-form [guest]="guest" [guestIndex]="guestIndex" [locale]="locale"></app-passport-form>
    </div>
  </ng-container>
</ng-container>
