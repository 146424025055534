<div class="page-headline">
  <h1>{{ title }}</h1>
</div>

<em [innerHtml]="description" *ngIf="description != null"></em>

<div class="container">
  <div class="container-right" *ngIf="rightSide">
    <app-swiper [items]="medias"></app-swiper>

    <app-widget-advert></app-widget-advert>
  </div>
  <div class="container-left">
    <div class="searchbox" *ngIf="module?.search && (categories || entries)">
      <input type="search" [(ngModel)]="searchText" placeholder="{{ 'misc.searchenter' | translate }}" />
      <div class="search-icon"><i class="fas fa-search"></i></div>
    </div>

    <div class="menu" *ngIf="categories?.length">
      <a *ngFor="let item of categories | filter: searchText" [routerLink]="['/g', globals.getCode(), 'wish_category', item.category.id]">
        <div>{{ item.category.name }}</div>
        <div>
          <i class="fas fa-angle-right icon-next"></i>
        </div>
      </a>
    </div>
    <div class="menu entries" *ngIf="entries?.length">
      <div class="head">
        <div translate>shopping_card.name</div>
        <div translate *ngIf="category && category.show_price">shopping_card.price</div>
        <div *ngIf="!category || (category && !category.show_price)"></div>
        <div></div>
      </div>
      <a *ngFor="let item of entries | filter: searchText" [routerLink]="['/g', globals.getCode(), 'wish', item.entry.id]">
        <div class="two-lines">
          {{ item.entry.title }}
          <small *ngIf="item.entry.description" [innerHtml]="item.entry.description | stripTags"></small>
        </div>
        <div *ngIf="business && !item.entry.price_hidden">{{ item.entry.price | currency: business.currency }}</div>
        <div *ngIf="item.entry.price_hidden"></div>
        <div>
          <i class="fas fa-angle-right icon-next"></i>
        </div>
      </a>
    </div>
  </div>
</div>

<app-backlink [visibleMobile]="true" *ngIf="category"></app-backlink>
